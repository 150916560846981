import { gql, UpdateProvider } from 'services/dataProvider';

import { fields as TaskTypeFields } from '../fragments';

const prepareImage = (source: Record<string, any>) => {
  if (!source?.url) {
    return null;
  }
  return {
    id: source?.id || null,
    name: source?.name || '',
    thumbnail: source?.thumbnail || '',
    url: source?.url || '',
  };
};

const provider: UpdateProvider = (params) => {
  const mutation = gql`
    ${TaskTypeFields}
    mutation ($input: TaskTypeUpdateInput!) {
      taskType {
        update (
          input: $input
        ) {
          ...TaskTypeFields
        }
      }
    }
  `;
  return {
    mutation,
    variables: {
      input: {
        id: params.data.id,
        name: params.data.name,
        description: params.data.description,
        priorityLevel: params.data.priorityLevel,
        example: prepareImage(params.data.example),
        isOngoing: params.data.isOngoing,
        tags: params.data.tags.map?.((tag: any) => ({
          id: tag.id || null,
          name: tag.name,
        })),
      },
    },
  };
};

export default provider;
