import { memo, PropsWithChildren } from 'react';
import { Layout as LayoutOrigin } from 'react-admin';
import { createUseStyles } from 'react-jss';

import AppBar from './AppBar';
import Menu from './Menu';

const Layout = (props: PropsWithChildren) => {
  const classes = useStyles();

  return (
    <LayoutOrigin
      {...props}
      appBar={AppBar}
      menu={Menu}
      sx={{
        '& .RaSidebar-fixed': {
          display: 'flex',
        },
      }}
    />
  );
};

const useStyles = createUseStyles({

});

export default memo(Layout);
