import { useNavigate } from 'react-router-dom';
import { CSSProperties, memo, useCallback } from 'react';
import { Fab, Theme, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useLocaleState } from 'react-admin';

type FloatBackButtonProps ={
  style?: CSSProperties,
  size?: 'small' | 'medium' | 'large'
  color?: 'inherit' | 'primary' | 'secondary' | 'default' | 'success' | 'error' | 'info' | 'warning'
  sx?: CSSProperties
};

const FloatBackButton = (props: FloatBackButtonProps) => {
  const {
    style, size, color, sx,
  } = props;

  const [locale] = useLocaleState();

  const navigate = useNavigate();
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const handleBack = useCallback(async () => {
    navigate(-1);
  }, [navigate]);

  if (!isXSmall) {
    return null;
  }

  return (
    <Fab
      color={color}
      size={size}
      style={style}
      onClick={handleBack}
      sx={sx}
    >
      {locale === 'en' && (
        <ArrowBackIcon />
      )}
      {locale === 'he' && (
        <ArrowForwardIcon />
      )}
    </Fab>
  );
};

export default memo(FloatBackButton);
