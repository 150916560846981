import { Resource } from 'react-admin';

import SettingEdit from './SettingEdit';

import getOne from './graphql/providers/getOne';
import update from './graphql/providers/update';

export default <Resource
  name="Setting"
  edit={SettingEdit}
  options={{
    providers: {
      getOne,
      update,
    },
  }}
/>;
