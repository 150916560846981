import { memo } from 'react';
import { useTranslate } from 'react-admin';
import { createPortal } from 'react-dom';
import { IconButton, Paper, Stack } from '@mui/material';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

import BulkActionArchive from '../elements/BulkActionArchive';
import LinkButton from '../../../../../../../components/LinkButton';

type DesktopBulkActionProps = {
  filterValues? : {
    isArchived: string
  },
  handleApprove? : () => void,
  hasDownloadAll?: boolean,
  downloadUrl? : string
  selectedIds? : number[],
};

const MobileBulkAction = (props: DesktopBulkActionProps) => {
  const {
    filterValues, handleApprove, hasDownloadAll, downloadUrl, selectedIds,
  } = props;
  const { isArchived = 'unarchived' } = filterValues || {};
  const translate = useTranslate();
  return createPortal(
    <Stack
      spacing={2}
      display={selectedIds?.length !== 0 ? 'flex' : 'none'}
      sx={{
        position: 'fixed', bottom: '10rem', zIndex: 100, right: '1.3rem',
      }}
    >
      <Paper sx={{ borderRadius: '50%' }} elevation={5}>
        <IconButton
          sx={{ backgroundColor: '#549E56', color: '#fff' }}
          size="medium"
          onClick={handleApprove}
        >
          <DoneAllIcon />
        </IconButton>
      </Paper>
      <Paper hidden={!hasDownloadAll} sx={{ borderRadius: '50%' }} elevation={5}>
        <LinkButton
          variant="mobile"
          href={downloadUrl}
          icon={<GetAppOutlinedIcon />}
        />
      </Paper>
      {isArchived === 'unarchived' && (
        <BulkActionArchive
          selectedIds={selectedIds}
          action="setArchived"
          label={translate('resources.task.values.status.archive')}
          value
          icon={<DeleteOutlineIcon />}
        />
      )}
      {isArchived === 'archived' && (
      <BulkActionArchive
        selectedIds={selectedIds}
        action="setArchived"
        label={translate('resources.task.values.status.unArchive')}
        value={false}
        icon={<RestoreFromTrashIcon />}
      />
      )}
    </Stack>,
    document.body,
  );
};

export default memo(MobileBulkAction);
