import { memo } from 'react';
import cn from 'classnames';
import { useTranslate } from 'react-admin';
import { createUseStyles } from 'react-jss';
import { Chip } from '@mui/material';

import { Client } from 'interfaces/entities';

import useTaskInfo from '../hooks/useTaskInfo';

const defaultProps = {
  size: 'medium',
  mh: 0,
  mv: 0,
};

type TaskInfoChipProps = {
  record: Client,
  size?: 'small' | 'medium',
  mh?: number,
  mv?: number,
} & typeof defaultProps;

const TaskInfoChip = (props: TaskInfoChipProps) => {
  const {
    record,
    size,
    mh,
    mv,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  const {
    outstanding,
    waitingForReview,
    total,
    isDidntStart,
    isAwaitingForReview,
    isPartCompleted,
    isCompleted,
  } = useTaskInfo(record);

  if (isDidntStart) {
    return (
      <Chip
        className={cn(classes.chip, classes.red)}
        sx={{
          marginLeft: mh, marginRight: mh, marginTop: mv, marginBottom: mv,
        }}
        size={size}
        label={translate('resources.client.didntStart')}
      />
    );
  }

  if (isAwaitingForReview) {
    return (
      <Chip
        className={cn(classes.chip, classes.yellow)}
        sx={{
          marginLeft: mh, marginRight: mh, marginTop: mv, marginBottom: mv,
        }}
        size={size}
        label={`${waitingForReview} ${translate('resources.client.taskAwaitingApproval')}`}
      />
    );
  }

  if (isPartCompleted) {
    return (
      <Chip
        className={cn(classes.chip, classes.yellow)}
        sx={{
          marginLeft: mh, marginRight: mh, marginTop: mv, marginBottom: mv,
        }}
        size={size}
        label={`${total - outstanding} / ${total} ${translate('resources.client.taskOutstanding')}`}
      />
    );
  }

  if (isCompleted) {
    return (
      <Chip
        className={cn(classes.chip, classes.green)}
        sx={{
          marginLeft: mh, marginRight: mh, marginTop: mv, marginBottom: mv,
        }}
        size={size}
        label={translate('resources.client.allTasksCompleted')}
      />
    );
  }

  return null;
};

const useStyles = createUseStyles({
  chip: {
    fontSize: 11,
    fontWeight: 'bold',
    color: '#ffffff',
    height: 18,
    alignItems: 'center',
    '& > span': {
      display: 'block',
      lineHeight: '16px',
    },
  },
  red: {
    backgroundColor: '#de362abf',
  },
  yellow: {
    backgroundColor: '#f1b60fbf',
  },
  green: {
    backgroundColor: '#2a9c49bf',
  },
  blue: {
    backgroundColor: '#0074e8bf',
  },
});

TaskInfoChip.defaultProps = defaultProps;

export default memo(TaskInfoChip);
