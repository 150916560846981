import { gql, DeleteManyProvider } from 'services/dataProvider';

const provider: DeleteManyProvider = (params) => {
  const mutation = gql`
    mutation ($input: ClientTaskBulkDeleteInput!) {
      clientTask {
        deleteMany (input: $input) {
          id
        }
      }
    }
  `;
  return {
    mutation,
    variables: {
      input: {
        id: params.ids,
      },
    },
  };
};

export default provider;
