import { memo, useCallback } from 'react';
import {
  FunctionField, Show, useTranslate,
} from 'react-admin';
import {
  Box,
  InputLabel,
} from '@mui/material';

import DateFieldCustom from 'components/DateFieldCustom';
import { useWatch } from 'react-hook-form';

const UserInfoTaskAddMobile = () => {
  const translate = useTranslate();
  const watch = useWatch();

  const renderName = useCallback((record: { firstName: string; lastName: string; }) => (
    `${record?.firstName || ''} ${record?.lastName || ''}`.trim()
  ), []);

  const renderEmail = useCallback((record: { email: string }) => (
    `${translate('resources.client.fields.email')}: ${record?.email}`
  ), [translate]);

  const renderPhone = useCallback((record: { phone: string }) => (
    `${translate('resources.client.fields.mobilePhone')}: ${record?.phone}`
  ), [translate]);

  return (
    <Show
      sx={{
        '& .MuiPaper-root': {
          boxShadow: 'none',
          background: 'transparent',
          marginTop: '-1rem',
          padding: '0.5rem',
        },
      }}
      actions={false}
      resource="Client"
      id={watch.userId}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '1rem',
        alignItems: 'center',
      }}
      >
        <FunctionField
          sx={{
            fontWeight: 700,
            fontSize: '1.125rem',
            lineHeight: '1.875rem',
          }}
          label="Name"
          render={renderName}
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          width: '100%',
          marginTop: '1rem',
        }}
      >
        <InputLabel shrink>About client</InputLabel>
        <FunctionField
          label={translate('resources.client.fields.email')}
          render={renderEmail}
        />
        <FunctionField
          render={renderPhone}
        />
        <DateFieldCustom
          variant="body2"
          label={translate('resources.client.fields.dateOfBirth')}
          source="dateOfBirth"
        />
      </Box>
    </Show>
  );
};

export default memo(UserInfoTaskAddMobile);
