import { memo, type PropsWithChildren, type ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { Typography } from '@mui/material';

type IAuthUser = {
  firstName: string,
  lastName: string,
  company: string,
  loading: boolean,
};

const UserInfo = (props: PropsWithChildren<IAuthUser>): ReactElement => {
  const {
    firstName,
    lastName,
    company,
    loading,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.name}>
      {loading && (
      <Typography color="white" variant="caption" component="div">
        Pending data...
      </Typography>
      )}
      {!loading && (
      <>
        <Typography color="white" variant="caption" component="div">{`${firstName || ''} ${lastName || ''}`.trim()}</Typography>
        <Typography color="white" variant="caption" component="div">{company}</Typography>
      </>
      )}
    </div>
  );
};

const useStyles = createUseStyles((theme) => ({
  name: {
    display: 'block',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    textAlign: 'left',
  },
  company: {
    display: 'block',
    color: '#2D67BD',
  },
}));

export default memo(UserInfo);
