import { useEffect, useState, memo } from 'react';
import {
  DataProvider, LegacyDataProvider, AdminContext, AdminUI, LoadingPage, CustomRoutes, useSidebarState, useLocaleState,
} from 'react-admin';
import { BrowserRouter, Route } from 'react-router-dom';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {
  createTheme, Theme, ThemeProvider, useMediaQuery,
} from '@mui/material';

import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import buildDataProvider from './services/dataProvider';
import authProvider from './services/AuthProvider';
import englishMessages from './lib/i18n/dictionary/en';

import Layout from './components/Layout';
import Login from './components/Login';

import Tag from './resources/Tag/Tag';
import TaskType from './resources/TaskType/TaskType';
import Client from './resources/Client/Client';
import Task from './resources/Task/Task';
import Notification from './resources/Notification/Notification';
import Setting from './resources/Setting/Setting';
import CompanyAdmin from './resources/CompanyAdmin/CompanyAdmin';


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === 'he') {
    return import('./lib/i18n/dictionary/he').then((messages) => messages.default);
  }
  return englishMessages;
});

const themeRtl = createTheme({
  direction: 'rtl',
});
const themeLtr = createTheme({
  direction: 'ltr',
});

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const cacheLtr = createCache({
  key: 'muiltr',
});

const Loading = memo(() => (
  <div style={{
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
  >
    <LoadingPage />
  </div>
));

const MenuInitialController = memo(() => {
  const [, setSidebarState] = useSidebarState();
  const isSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  useEffect(() => {
    setSidebarState(!isSm);
  }, [setSidebarState, isSm]);
  return null;
});

const Ui = memo(() => {
  const [locale] = useLocaleState();

  return (
    <CacheProvider value={locale === 'he' ? cacheRtl : cacheLtr}>
      <ThemeProvider theme={locale === 'he' ? themeRtl : themeLtr}>
        <AdminUI
          layout={Layout}
          loginPage={false}
        >
          <CustomRoutes noLayout>
            <Route path="/login" element={<Login />} />
            <Route path="/code" element={<Login />} />
            <Route path="/onboard" element={<Login />} />
          </CustomRoutes>
          {Client}
          {Task}
          {TaskType}
          {Notification}
          {Setting}
          {CompanyAdmin}
          {Tag}
        </AdminUI>
      </ThemeProvider>
    </CacheProvider>
  );
});

const App = () => {
  const [dataProvider, setDataProvider] = useState<DataProvider | LegacyDataProvider>();
  const [prepared, setPrepared] = useState<boolean>(false);

  useEffect(() => {
    buildDataProvider([TaskType, Client, Task, Notification, Setting, CompanyAdmin, Tag]).then((result) => {
      setDataProvider(result);
    });
    authProvider.checkAuth()
      .then(() => { setPrepared(true); })
      .catch(() => { setPrepared(true); });
  }, []);

  if (!dataProvider) {
    return null;
  }

  return (
    <BrowserRouter>
      <AdminContext
        i18nProvider={i18nProvider}
        dataProvider={dataProvider}
        authProvider={authProvider}
      >
        <MenuInitialController />
        {!prepared && (
          <Loading />
        )}
        {prepared && (
          <Ui />
        )}
      </AdminContext>
    </BrowserRouter>
  );
};

export default memo(App);
