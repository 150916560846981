import {
  memo, useCallback,
} from 'react';
import {
  Toolbar as RAToolbar,
} from 'react-admin';
import { Button } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

import { FormButton } from 'components/FormButton';

const Toolbar = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleBack = useCallback(async () => {
    navigate(-1);
  }, [navigate]);

  return (
    <RAToolbar sx={{
      display: 'flex',
      columnGap: '1rem',
    }}
    >
      <>
        <Button onClick={handleBack} variant="outlined">Back</Button>
        <div className={classes.sep} />
        <FormButton label="Save Changes" />
      </>
    </RAToolbar>
  );
};

const useStyles = createUseStyles({
  sep: {
    flex: 1,
  },
});

export default memo(Toolbar);
