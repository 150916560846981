import {
  type PropsWithChildren, type ReactElement, type MouseEvent, useCallback, useState, memo,
} from 'react';
import { createUseStyles } from 'react-jss';
import {
  Logout, UserMenu,
} from 'react-admin';

import MenuItemLink from 'components/Menu/elements/MenuItemLink';
import Icon from 'components/Icon';

import UserInfo from './elements/UserInfo';
import Avatar from '../Avatar';

type IAuthUser = {
  firstName: string,
  lastName: string,
  company: string,
  loading: boolean,
  profilePhotoUrl: string,
};

const AuthUser = (props: PropsWithChildren<IAuthUser>): ReactElement => {
  const {
    children,
    firstName,
    lastName,
    company,
    loading,
    profilePhotoUrl,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    const root = document.body.querySelector(`.${classes.button}`) as HTMLDivElement;
    const menu = document.body.querySelector(`.${classes.menu} .MuiPaper-root`) as HTMLDivElement;
    if (menu) {
      menu.style.width = `${root.getBoundingClientRect().width + 32}px`;
      menu.style.maxWidth = 'none';
    }
    setAnchorEl(event.currentTarget);
  }, [classes.button, classes.menu]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <div onClick={handleMenu} role="none" className={classes.button}>
        {children}
      </div>
      <UserMenu
        className={classes.menu}
        icon={(
          <Avatar name={`${firstName || ''} ${lastName || ''}`} image={profilePhotoUrl} />
        )}
      >
        <MenuItemLink
          onClick={handleClose}
          className={classes.item}
          activeClassName={classes.itemActive}
          to="/Setting/1"
          primaryText="Profile"
          leftIcon={<Icon name="SettingsIcon" htmlColor="#7F8896" fontSize="medium" />}
        />
        {/* <MenuItemLink
          onClick={handleClose}
          className={classes.item}
          activeClassName={classes.itemActive}
          to="/Notification/1"
          primaryText="Notifications"
          leftIcon={<Icon name="NotificationsIcon" htmlColor="#7F8896" fontSize="medium" />}
        /> */}
        <Logout
          className={classes.item}
          icon={<Icon name="LogoutIcon" htmlColor="#7F8896" fontSize="medium" />}
        />
      </UserMenu>
      <UserInfo
        firstName={firstName}
        lastName={lastName}
        company={company}
        loading={loading}
      />
    </>
  );
};

const useStyles = createUseStyles((theme) => ({
  button: {
    cursor: 'pointer',
  },
  menu: {
    margin: '-1rem 0',
  },
  item: {
    '&': {
      fontWeight: 400,
      color: '#2B2F36',
      transition: 'color 900ms ease',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#3D7CDA',
      transitionDuration: '200ms',
    },
    '& svg': {
      fill: '#454950',
      marginBottom: '1px',
      transition: 'fill 900ms ease',
    },
    '&:hover svg': {
      fill: '#3D7CDA',
      transitionDuration: '200ms',
    },
    '&:focus-visible': {
      backgroundColor: 'transparent',
    },
  },
  itemActive: {
    '&': {
      fontWeight: 500,
      color: '#2D67BD',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      fill: '#2D67BD',
      marginBottom: '1px',
    },
  },
}));

export default memo(AuthUser);
