import { memo, useCallback } from 'react';
import type { FC } from 'react';
import { useRedirect } from 'react-admin';
import { Typography } from '@mui/material';

interface UserLinkProps {
  record : {
    client? : {
      id: number,
      firstName : string,
      lastName : string,
    }
  }
}

const UserLink:FC <UserLinkProps> = (props) => {
  const { record } = props;
  const redirect = useRedirect();

  const handleUserOpen = useCallback(() => {
    redirect(`/Client/${record?.client?.id}/show`);
  }, [record?.client?.id, redirect]);

  return (
    <Typography
      sx={{
        cursor: 'pointer',
        fontWeight: 'bold',
      }}
      onClick={handleUserOpen}
      color="primary"
    >
      {`${record?.client?.firstName} ${record?.client?.lastName}`}
    </Typography>
  );
};

export default memo(UserLink);
