import {
  type MouseEvent, type PropsWithChildren,
  memo, useCallback,
} from 'react';
import { Loading, RecordContextProvider, useListContext } from 'react-admin';
import { Box } from '@mui/material';

type RowProps = {
  record: any,
  onClick?: (event: MouseEvent<HTMLDivElement>, record: any) => void;
};

const Row = (props: PropsWithChildren<RowProps>) => {
  const {
    children,
    record,
    onClick,
  } = props;

  const handleClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    onClick?.(event, record);
  }, [onClick, record]);

  return (
    <Box onClick={handleClick}>
      {children}
    </Box>
  );
};

type MobileGridProps = {
  onRowClick?: (event: MouseEvent<HTMLDivElement>, record: any) => void;
};

const MobileGrid = (props: PropsWithChildren<MobileGridProps>) => {
  const {
    children,
    onRowClick,
  } = props;

  const { data, isLoading } = useListContext();

  const handleRowClick = useCallback((event: MouseEvent<HTMLDivElement>, record: any) => {
    onRowClick?.(event, record);
  }, [onRowClick]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {data.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
          <Row record={record} onClick={handleRowClick}>{children}</Row>
        </RecordContextProvider>
      ))}
    </>
  );
};

export default memo(MobileGrid);
