import { FC, memo, ReactElement } from 'react';
import { useRecordContext } from 'react-admin';
import { InputLabel, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';

interface RenderFieldProps {
  // eslint-disable-next-line react/no-unused-prop-types
  source?: string,
  label?: string,
  render: (record: Record<string, any>) => string | ReactElement,
}

const RenderField: FC<RenderFieldProps> = (props) => {
  const {
    label,
    render,
  } = props;

  const classes = useStyles();
  const record = useRecordContext();
  const result = render(record);
  const isSet = Boolean(result);
  const isComponent = !['number', 'string'].includes(typeof result);

  if (!isSet) {
    return (
      <div className={classes.container}>
        <InputLabel shrink>{label}</InputLabel>
        <Typography color="textSecondary" className={classes.field}>No data</Typography>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <InputLabel shrink>{label}</InputLabel>
      {!isComponent && (
        <Typography color="textPrimary" className={classes.field}>{result}</Typography>
      )}
      {isComponent && <div className={classes.field}>{result}</div>}
    </div>
  );
};

const useStyles = createUseStyles({
  container: {
    paddingTop: '0.5rem',
  },
  field: {
    paddingTop: '1px',
  },
});

export default memo(RenderField);
