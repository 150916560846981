import { memo, useCallback } from 'react';
import {
  EditButton, FunctionField, useRecordContext, useTranslate,
} from 'react-admin';
import {
  Box,
  InputLabel,
} from '@mui/material';

import DateFieldCustom from 'components/DateFieldCustom';

const UserInfo = () => {
  const translate = useTranslate();
  const rec = useRecordContext();

  const renderName = useCallback((record: { firstName: string; lastName: string; }) => (
    `${record?.firstName || ''} ${record?.lastName || ''}`.trim()
  ), []);

  const renderEmail = useCallback((record: { email: string }) => (
    `${translate('resources.client.fields.email')}: ${record?.email}`
  ), [translate]);

  const renderPhone = useCallback((record: { phone: string }) => (
    `${translate('resources.client.fields.mobilePhone')}: ${record?.phone}`
  ), [translate]);

  return (
    <div>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '1rem',
        alignItems: 'center',
        marginLeft: '1rem',
        marginRight: '1rem',
      }}
      >
        <FunctionField
          sx={{
            fontWeight: 700,
            fontSize: '1.125rem',
            lineHeight: '1.875rem',
          }}
          label="Name"
          render={renderName}
        />
        <EditButton sx={{ marginRight: -3 }} />
      </Box>
      <Box
        sx={{
          display: 'grid',
          margin: '1rem 1rem 0',
        }}
      >
        <InputLabel shrink>About client</InputLabel>
        <FunctionField
          hidden={!rec?.email}
          label={translate('resources.client.fields.email')}
          render={renderEmail}
        />
        <FunctionField
          hidden={!rec?.phone}
          render={renderPhone}
        />
        <DateFieldCustom
          variant="body2"
          hidden={!rec?.dateOfBirth}
          label={translate('resources.client.fields.dateOfBirth')}
          source="dateOfBirth"
        />
      </Box>
      <Box
        hidden={!rec?.addressHouseNumber && !rec?.addressCity && !rec?.addressState && !rec?.addressZipCode}
        sx={{
          marginTop: '1.6875rem',
          marginLeft: '1rem',
          marginRight: '1rem',
        }}
      >
        <InputLabel shrink>{translate('resources.client.fields.address')}</InputLabel>
        <FunctionField
          render={(record:{addressHouseNumber: string, addressCity: string, addressState: string, addressZipCode: string}) => (
            [`${record?.addressHouseNumber} ${record?.addressCity} ${record?.addressState} ${record?.addressZipCode}`].join(',')
          )}
          label={translate('resources.client.fields.address')}
        />
      </Box>
    </div>
  );
};


export default memo(UserInfo);
