import {
  memo, useCallback, useRef,
  type ReactElement, type MouseEvent,
} from 'react';
import { IconButton, Theme, useMediaQuery } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { createUseStyles } from 'react-jss';

import * as Modal from 'components/Modal';
import * as file from 'lib/utils/file';

const DefaultButton = () => (
  <IconButton color="primary">
    <FullscreenIcon />
  </IconButton>
);

const defaultProps = {
  src: '',
  button: <DefaultButton />,
};

type PreviewModalFieldProps = {
  src?: string,
  alt?: string,
  button?: ReactElement,
} & typeof defaultProps;

const PreviewModalField = (props: PreviewModalFieldProps) => {
  const {
    alt,
    src,
    button,
  } = props;
  const classes = useStyles();
  const dialogRef = useRef(null);

  const isImage = file.checkImage(src);
  const isPdf = file.checkPdf(src);

  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const handlePreventClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleOpen = useCallback(() => {
    Modal.open(dialogRef);
  }, []);

  if (!src) {
    return null;
  }

  return (
    <div
      onClick={handlePreventClick}
      role="none"
    >
      <div role="none" onClick={handleOpen} className={classes.button}>{button}</div>
      <Modal.Dialog
        id={`preview-${src}`}
        title="Document Preview"
        ref={dialogRef}
        layout="content"
        fullScreen={isXSmall}
        hasGlobalCloser
        actionsStretchButtons={false}
        bodyContent={(
          <>
            {isImage && (
              <img
                src={src}
                alt={alt}
                style={{
                  maxHeight: isXSmall ? '100vh' : '90vh',
                  maxWidth: isXSmall ? '100vw' : '80vw',
                }}
              />
            )}
            {isPdf && (
              <iframe
                src={src}
                title={alt}
                style={{
                  display: 'block',
                  height: isXSmall ? '100vh' : '90vh',
                  width: isXSmall ? '100vw' : '80vw',
                  border: 0,
                  margin: 0,
                  padding: 0,
                }}
              />
            )}
          </>
        )}
      />
    </div>
  );
};

PreviewModalField.defaultProps = defaultProps;

const useStyles = createUseStyles({
  button: {
    cursor: 'pointer',
  },
  paperMobile: {
    margin: 0,
    borderRadius: 0,
  },
});

export default memo(PreviewModalField);
