import { memo, PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';

import Avatar from 'components/Avatar';

type userProps = {
  record: Record<string, any>
};

const User = (props:PropsWithChildren<userProps>) => {
  const classes = useStyles();
  const { record } = props;
  return (
    <div className={classes.container}>
      <Avatar name={`${record.firstName || ''} ${record.lastName || ''}`} image={record.profilePhotoUrl} />
      <div className={classes.text}>
        {`${record.firstName || ''} ${record.lastName || ''}`}
      </div>
    </div>
  );
};

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    marginLeft: '0.8rem',
    marginRight: '0.8rem',
  },
});

export default memo(User);
