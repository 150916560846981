import { ReactElement } from 'react';
import {
  ApolloClient, InMemoryCache, gql, createHttpLink, NormalizedCacheObject,
} from '@apollo/client';
import {
  DeleteResult,
  GetManyResult,
  GetManyReferenceResult,
  DataProvider,
} from 'react-admin';

import create, { CreateResult, CreateParams, CreateProvider } from './resolvers/create';
import uploadCsv, { UploadCsvResult, UploadCsvParams, UploadCsvProvider } from './resolvers/uploadCsv';
import update, { UpdateResult, UpdateParams, UpdateProvider } from './resolvers/update';
import updateMany, { UpdateManyResult, UpdateManyParams, UpdateManyProvider } from './resolvers/updateMany';
import deleteMany, { DeleteManyResult, DeleteManyParams, DeleteManyProvider } from './resolvers/deleteMany';
import getList, { GetListResult, GetListParams, GetListProvider } from './resolvers/getList';
import getOne, { GetOneResult, GetOneParams, GetOneProvider } from './resolvers/getOne';

export { gql };

const SERVER_URL = (process.env.REACT_APP_SERVER_URL || '').replace(/\/$/, '');

export type {
  CreateParams, CreateProvider,
  UploadCsvParams, UploadCsvProvider,
  UpdateParams, UpdateProvider,
  UpdateManyParams, UpdateManyProvider,
  DeleteManyParams, DeleteManyProvider,
  GetListParams, GetListProvider,
  GetOneParams, GetOneProvider,
};

type TProvider<ParamsType, ResultType> =
  (client: ApolloClient<NormalizedCacheObject>, provider: any, resource: string, params: ParamsType) => Promise<ResultType>;

const createDataProvider = async (resources: ReactElement[]): Promise<DataProvider> => {
  const cache = new InMemoryCache({
    resultCaching: false,
  });

  const link = createHttpLink({
    uri: `${SERVER_URL}/api/graphql`,
    credentials: 'include',
  });

  const client = new ApolloClient({
    // Provide required constructor fields
    cache,
    link,

    // Provide some optional constructor fields
    name: 'docs to do',
    version: '1.3',
    queryDeduplication: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
    },
  });

  const connect = <PrmType, ResType>(resolver: TProvider<PrmType, ResType>, providerName: string) => (resource: string, params: PrmType): Promise<ResType> => {
    const foundResource = resources.find((item) => item.props.name === resource);
    const provider = foundResource?.props?.options?.providers?.[providerName];

    if (!foundResource || !provider) {
      return Promise.reject(new Error(`provider "${providerName}" for resource "${resource}" not found`));
    }

    return resolver(client, provider, resource, params);
  };

  return {
    create: connect<CreateParams, CreateResult<any>>(create, 'create'),
    uploadCsv: connect<UploadCsvParams, UploadCsvResult<any>>(uploadCsv, 'uploadCsv'),
    update: connect<UpdateParams, UpdateResult<any>>(update, 'update'),
    updateMany: connect<UpdateManyParams, UpdateManyResult>(updateMany, 'updateMany'),
    getList: connect<GetListParams, GetListResult<any>>(getList, 'getList'),
    getOne: connect<GetOneParams, GetOneResult<any>>(getOne, 'getOne'),
    deleteMany: connect<DeleteManyParams, DeleteManyResult>(deleteMany, 'deleteMany'),

    delete: <RecordType>(resource: string, params: Record<string, any>): Promise<DeleteResult<any>> => {
      // eslint-disable-next-line no-console
      console.info('delete');
      return Promise.resolve({
        data: {} as RecordType,
      });
    },
    getMany: (resource: string, params: Record<string, any>): Promise<GetManyResult<any>> => {
      // eslint-disable-next-line no-console
      console.info('getMany');
      return Promise.resolve({
        data: [],
      });
    },
    getManyReference: (resource: string, params: Record<string, any>): Promise<GetManyReferenceResult<any>> => {
      // eslint-disable-next-line no-console
      console.info('getManyReference');
      return Promise.resolve({
        data: [],
        total: 0,
      });
    },
  };
};

export default createDataProvider;
