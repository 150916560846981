import qs from 'query-string';

const responseCodes: Record<number, string> = {
  100: 'Continue',
  101: 'Switching Protocol',
  102: 'Processing',
  103: 'Early Hints',
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  300: 'Multiple Choice',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: 'Switch Proxy',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Request Entity Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
};

export interface RequestResult<T> {
  data: T | null,
  error: string | null,
}

const request = async <T = any>(
  endpoint: string,
  query?: Record<string, any> | null,
  body?: Record<string, any> | null,
  options: Record<string, any> = {},
): Promise<RequestResult<T>> => {
  const url = `${(process.env.REACT_APP_SERVER_URL || '').replace(/\/$/, '')}/${endpoint.replace(/^\//ig, '')}`;

  let queryFinal = '';
  if (query && Object.entries(query).length > 0) {
    queryFinal = `?${qs.stringify(query)}`;
  }

  const optionsFinal: Record<string, any> = {
    ...options,
    headers: { ...(options?.headers || {}) },
  };
  if (options?.method === 'post') {
    optionsFinal.headers['Content-Type'] = 'application/json';
  }
  if (body && Object.entries(body).length > 0) {
    optionsFinal.body = JSON.stringify(body);
  }

  try {
    const result = await fetch(`${url}${queryFinal}`, optionsFinal);
    const buffer: string = await result.text();
    if (buffer.length > 0) {
      const parsed = JSON.parse(buffer);
      return {
        data: parsed?.error ? null : (parsed?.data || parsed),
        error: parsed?.error ? (parsed?.error.message?.message || parsed?.error.message) : null,
      };
    }
    if (buffer.length === 0 && result.status === 200) {
      return {
        data: null,
        error: null,
      };
    }
    if (buffer.length === 0 && result.status !== 200) {
      return {
        data: null,
        error: responseCodes[result.status],
      };
    }
  } catch (error) {
    if ((error as Error).name === 'SyntaxError') {
      return {
        data: null,
        error: 'SyntaxError: Response is not JSON',
      };
    }
    return {
      data: null,
      error: (error as Error).message,
    };
  }

  return {
    data: null,
    error: 'unknown error',
  };
};

const requestGet = <T = any>(
  endpoint: string,
  query?: Record<string, any> | null,
  options: Record<string, any> = {},
): Promise<RequestResult<T>> => request<T>(endpoint, query, undefined, {
  ...options,
  method: 'get',
});

const requestPost = <T = any>(
  endpoint: string,
  query?: Record<string, any> | null,
  body?: Record<string, any> | null,
  options: Record<string, any> = {},
): Promise<RequestResult<T>> => request<T>(endpoint, query, body, {
  ...options,
  method: 'post',
});

export default {
  get: requestGet,
  post: requestPost,
};
