import { FC, memo } from 'react';
import {
  Typography, CircularProgress, Paper, Box,
} from '@mui/material';

import type { EmptyComponentProps } from 'components/FileInput';
import { ReactComponent as IconFile } from 'components/FileInput/assets/icon-file.svg';

const DocumentEmpty: FC<EmptyComponentProps> = (props) => {
  const { isLoading, files, percent } = props;

  const progressValue = (percent || 0) * 100;

  if (isLoading) {
    return (
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          boxSizing: 'border-box',
          border: '1px solid #606B7C',
          borderRadius: '0.5rem',
          padding: '1.4rem',
          height: '17rem',
        }}
      >
        <Box
          sx={{
            '&': {
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '6.1rem',
            },
            '& svg': {
              height: '100%',
            },
          }}
        >
          <CircularProgress variant="determinate" value={progressValue} />
          <Typography
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '0.625rem',
            }}
            variant="caption"
            component="div"
            color="textSecondary"
          >
            {`${progressValue.toFixed(0)}%`}
          </Typography>
        </Box>
        <Typography sx={{ marginTop: '0.8rem' }} variant="caption" component="div">
          {`uploading ${files?.length} ${files?.length === 1 ? 'file' : 'files'}`}
          <br />
          please wait
        </Typography>
      </Paper>
    );
  }

  return (
    <Paper
      elevation={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        boxSizing: 'border-box',
        border: '1px solid #606B7C',
        borderRadius: '0.5rem',
        padding: '1.4rem',
        height: '17rem',
      }}
    >
      <Box
        sx={{
          '&': {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '6.1rem',
          },
          '& svg': {
            height: '100%',
          },
        }}
      >
        <IconFile />
      </Box>
      <Typography sx={{ marginTop: '0.8rem' }} variant="caption" component="div">
        Drop file here
        <br />
        to upload
      </Typography>
    </Paper>
  );
};

export default memo(DocumentEmpty);
