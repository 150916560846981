import { gql, UpdateProvider } from 'services/dataProvider';
import { ClientTask } from 'interfaces/entities';

import { fields as ClientTaskFields } from '../fragments';

const update: UpdateProvider = (params) => {
  const mutation = gql`
      ${ClientTaskFields}
      mutation ($input: ClientTaskUpdateInput!) {
          clientTask {
              update (input: $input) {
                  ...ClientTaskFields
              }
          }
      }
  `;
  return {
    mutation,
    variables: {
      input: {
        id: params.data.id,
        dueDateAt: params.data.dueDateAt,
        notes: params.data.notes,
        documents: params?.data?.documents?.map?.((doc: any) => ({ ...doc, __typename: undefined })) || [],
      },
    },
  };
};

const updateState: UpdateProvider<'clientTask', ClientTask> = (params) => {
  const mutation = gql`
    ${ClientTaskFields}
    mutation ($input: ClientTaskUpdateStateInput!) {
      clientTask {
        updateState (input: $input) {
          ...ClientTaskFields
        }
      }
    }
  `;
  return {
    mutation,
    variables: {
      input: {
        id: params.data.id,
        value: params.data.state,
        notes: params.data.notes,
      },
    },
    parser: (data) => {
      return {
        // @todo Добавить возможность установить кастомное поле "update"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data: data?.clientTask.updateState as ClientTask,
      };
    },
  };
};

const provider: UpdateProvider = (params) => {
  if (!params.data.state) {
    return update(params);
  }
  return updateState(params);
};

export default provider;
