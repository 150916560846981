import { gql, CreateProvider } from 'services/dataProvider';
import { ClientTask } from 'interfaces/entities';

import { fields as TaskFields } from '../fragments';

const provider: CreateProvider<'clientTask', ClientTask[], ClientTask> = (params) => {
  const mutation = gql`
    ${TaskFields}
    mutation ($input: ClientTaskCreateInput!) {
      clientTask {
        create (input: $input) {
          ...ClientTaskFields
        }
      }
    }
  `;
  return {
    mutation,
    variables: {
      input: {
        clientIds: params.data.clientIds,
        tasks: params.data.tasks,
        note: params.data.note,
      },
    },
    parser: (data) => ({
      data: data?.clientTask?.create?.[0] || {},
    }),
  };
};

export default provider;
