import { FC, memo } from 'react';
import { useRecordContext } from 'react-admin';
import { InputLabel, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';

interface StringFieldProps {
  label?: string,
  source: string,
}

const StringField: FC<StringFieldProps> = (props) => {
  const {
    label,
    source,
  } = props;

  const classes = useStyles();
  const record = useRecordContext();
  const result = record?.[source];
  const isSet = Boolean(result);

  return (
    <div className={classes.container}>
      <InputLabel shrink>{label}</InputLabel>
      <Typography color={isSet ? 'textPrimary' : 'textSecondary'}>
        {record?.[source] || 'No data'}
      </Typography>
    </div>
  );
};

const useStyles = createUseStyles({
  container: {
    paddingTop: '0.5rem',
  },
  field: {
    paddingTop: '1px',
  },
});

export default memo(StringField);
