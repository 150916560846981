import { DateTime } from 'luxon';
import React, {
  memo,
  PropsWithChildren, useCallback, useEffect, useState,
} from 'react';
import {
  useTranslate, useInput, Identifier, SelectInput, useRecordSelection, ReferenceInput, ResourceContextProvider, InfiniteList,
} from 'react-admin';
import { createUseStyles } from 'react-jss';

import SearchInput from 'components/SearchInput';

import {
  Box, InputLabel, Stack, Theme, useMediaQuery,
} from '@mui/material';

import DatePicker from './elements/DatePicker';
import DesktopTasksInput from './layouts/DesktopTasksInput';
import MobileTasksInput from './layouts/MobileTasksInput';
import DatePickerMobile from './elements/DatePickerMobile';


const filters = [
  <SearchInput
    label="resources.taskType.action.search"
    source="q"
    alwaysOn
  />,
  <ReferenceInput alwaysOn source="tag" reference="Tag">
    <SelectInput
      sx={{
        minWidth: '8rem', marginLeft: '0.5rem', boxShadow: 2, borderRadius: 2, marginBottom: '-0.03rem',
      }}
      alwaysOn
      source="tag"
      variant="outlined"
    />
  </ReferenceInput>,
];

const ListActions = (props: { onDueDateAllChange: any, selectedIds: Identifier[] }) => {
  const {
    onDueDateAllChange,
    selectedIds,
  } = props;
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return (
    <>
      {!isXSmall && (
        <DatePicker onChange={onDueDateAllChange} />
      )}
      {isXSmall && (
      <DatePickerMobile selectedIds={selectedIds} onChange={onDueDateAllChange} />
      )}
    </>

  );
};

export interface ITaskItem {
  id: Identifier,
  dueDateAt: string,
}

type TasksInputProps = {
  source: string,
};

const TasksInput = (props: PropsWithChildren<TasksInputProps>) => {
  const { source } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const [selectedIds] = useRecordSelection('TaskType');
  const [defaultDueDate, setDefaultDueDate] = useState(DateTime.now().toFormat('yyyy-MM-dd'));

  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const { field } = useInput(props);

  const handleBulkSelect = useCallback((Ids: Identifier[]) => {
    const newValue: ITaskItem[] = [];
    selectedIds.forEach((recordId: Identifier) => {
      newValue.push({
        id: recordId,
        dueDateAt: defaultDueDate,
      });
    });
    if (JSON.stringify(field.value) !== JSON.stringify(newValue)) {
      field.onChange(newValue);
    }
  }, [defaultDueDate, field, selectedIds]);

  useEffect(() => {
    handleBulkSelect?.(selectedIds);
  }, [handleBulkSelect, selectedIds]);

  const handleDueDateChange = useCallback((taskId: Identifier, dueDateAt: string, returnValue = false) => {
    setDefaultDueDate(dueDateAt);
    const newValue = [...(field?.value || [])];
    const index = newValue.findIndex((item) => item.id === taskId);
    if (index === -1) {
      return;
    }
    newValue.splice(index, 1);
    newValue.push({
      id: taskId,
      dueDateAt,
    });
    field.onChange(newValue);
  }, [field]);

  const handleDueDateAllChange = useCallback((dueDateAt: string) => {
    setDefaultDueDate(dueDateAt);
    const newValue = [...(field?.value || [])].map((item) => ({
      ...item,
      dueDateAt,
    }));
    field.onChange(newValue);
  }, [field]);


  return (
    <Box
      sx={{
        '&': {
          width: '100%',
        },
        '& .MuiToolbar-root': {
          background: 'transparent',
        },
      }}
    >
      <ResourceContextProvider value="TaskType">
        <InfiniteList
          disableSyncWithLocation
          resource="TaskType"
          perPage={10}
          sort={{ field: 'id', order: 'desc' }}
          actions={!isXSmall ? <ListActions selectedIds={selectedIds} onDueDateAllChange={handleDueDateAllChange} /> : false}
          filters={filters}
        >
          {isXSmall && (
          <Stack
            direction="row"
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'space-between',
              marginTop: '2rem',
              height: '1.25rem',
              alignItems: 'center',
              paddingRight: '0.5rem',
            }}
          >
            <InputLabel sx={{ fontSize: 12, marginLeft: '0.5rem' }}>Assign tasks</InputLabel>
            <ListActions selectedIds={selectedIds} onDueDateAllChange={handleDueDateAllChange} />
          </Stack>
          )}
          {!isXSmall && (
          <DesktopTasksInput
            selectedItems={field?.value}
            onChange={handleDueDateChange}
          />
          )}
          {isXSmall && (

          <MobileTasksInput
            selectedItems={field?.value}
            onChange={handleDueDateChange}
          />
          )}
        </InfiniteList>
      </ResourceContextProvider>
    </Box>
  );
};

const useStyles = createUseStyles({
  container: {
    '&': {
      width: '100%',
    },
    '& .MuiToolbar-root': {
      background: 'transparent',
    },
  },
  topToolbar: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    marginTop: '2rem',
    height: '1.25rem',
    alignItems: 'center',
    paddingRight: '0.5rem',
  },
  toolbar: {
    '& form': {
      display: 'flex',
      flexWrap: 'nowrap',
      '& .RaFilterFormInput-spacer': {
        display: 'none',
      },
    },
  },
});

export default memo(TasksInput);
