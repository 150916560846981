import { FC, memo } from 'react';
import { Show } from 'react-admin';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';

import { Theme, useMediaQuery } from '@mui/material';

import ClientShowLayout from './layouts/ClientShowLayout';

const ClientShow: FC = (props) => {
  const classes = useStyles();
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return (
    <Show
      actions={false}
      {...props}
      sx={{
        '&': {
          marginTop: '-21px',
          marginRight: '-8px',
        },
        '& > div > .MuiPaper-root': {
          boxShadow: 'none',
          background: 'transparent',
        },
      }}
      className={cn({ [classes.show]: !isXSmall })}
    >
      <ClientShowLayout />
    </Show>
  );
};

const useStyles = createUseStyles({
  show: {
    '&': {
      display: 'flex',
      flex: 1,
      marginTop: '-8px',
    },
    '& > div': {
      flex: 1,
      marginTop: 0,
    },
    '& > div > .MuiPaper-root': {
      display: 'flex',
      boxShadow: 'none',
      background: 'transparent',
    },
  },
});

export default memo(ClientShow);
