import { gql, UpdateProvider } from 'services/dataProvider';

import { fields as SettingFields } from '../fragments';

const prepareImage = (source: Record<string, any>) => {
  if (!source?.url) {
    return null;
  }
  return {
    id: source?.id || null,
    name: source?.name || '',
    thumbnail: source?.thumbnail || '',
    url: source?.url || '',
  };
};

const provider: UpdateProvider = (params) => {
  const mutation = gql`
    ${SettingFields}
    mutation ($input: SettingUpdateInput!) {
      setting {
        update (input: $input) {
          ...SettingFields
        }
      }
    }
  `;

  return {
    mutation,
    variables: {
      input: {
        id: params.data.id,
        companyName: params.data.companyName,
        companyWebsite: params.data.companyWebsite,
        companyLogo: prepareImage(params?.data?.companyLogo),
        firstName: params.data.firstName,
        lastName: params.data.lastName,
        phone: params.data.phone,
        email: params.data.email,
        profilePhoto: prepareImage(params?.data?.profilePhoto),
      },
    },
  };
};

export default provider;
