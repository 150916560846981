import { memo, useCallback } from 'react';
import {
  Create,
  SimpleForm,
  useTranslate,
  useCreate,
  useNotify,
  TextInput, useRecordSelection, useRedirect,
} from 'react-admin';
import {
  FieldValues, SubmitHandler, useFormContext,
} from 'react-hook-form';

import { Identifier, InputProps } from 'ra-core';
import {
  Fab, Paper,
} from '@mui/material';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import FloatBackButton from 'components/FloatBackButton';

import TasksInput, { ITaskItem } from './elements/TasksInput';
import UserInfoTaskAddMobile from './elements/UserInfoTaskAddMobile';


type TaskCreateForm = {
  tasks: ITaskItem[],
  userId: Identifier
} & SubmitHandler<FieldValues>;

const MyFabSave = () => {
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();
  const { getValues } = useFormContext();

  const handleSubmit = useCallback(async () => {
    const { ...data } = getValues();
    try {
      await create(
        'Task',
        {
          data: {
            clientIds: Number(data.userId),
            tasks: (data.tasks || []).map(({ id, dueDateAt }: ITaskItem) => ({ id, dueDateAt })),
            note: '',
          },
        },
        {
          returnPromise: true,
          onSuccess: () => {
            notify('Task added');
            redirect('show', 'Client', data.userId);
          },
        },
      );
    } catch (error: any) {
      notify(error?.message, { type: 'warning' });
    }
  }, [create, getValues, notify, redirect]);

  return (
    <Fab
      sx={{ backgroundColor: '#549E56', color: '#fff' }}
      size="large"
      onClick={handleSubmit}
    >
      <PlaylistAddCheckIcon />
    </Fab>
  );
};


const TaskCreate = (props: InputProps<any>) => {
  useTranslate();

  const [selectedIds] = useRecordSelection('TaskType');

  return (
    <Create
      resource="Task"
      sx={{
        '& .RaCreate-card': {
          boxShadow: 'none',
          background: 'transparent',
          marginRight: '-1rem',
          marginLeft: '-0.5rem',
        },
      }}
      {...props}
    >
      <SimpleForm toolbar={false} sx={{ padding: '0.5rem' }}>
        <TextInput sx={{ display: 'none' }} name="userId" source="userId" />
        <UserInfoTaskAddMobile />
        <TasksInput source="tasks" />
        <Paper
          hidden={selectedIds.length === 0}
          sx={{
            position: 'fixed',
            bottom: '3.75rem',
            zIndex: 1000,
            right: '1.25rem',
            borderRadius: '50%',
          }}
        >
          <MyFabSave />
        </Paper>
        <FloatBackButton
          size="small"
          sx={{
            position: 'fixed',
            bottom: 'calc(68rem / 16)',
            zIndex: 1000,
            color: 'white',
            left: '1.25rem',
            backgroundColor: 'grey',
            opacity: 0.9,
          }}
        />
      </SimpleForm>
    </Create>
  );
};
export default memo(TaskCreate);
