import { memo, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import {
  IconButton, Paper, Theme, Typography, useMediaQuery,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';

import Image from 'components/Image';
import PreviewModalField from 'components/PreviewModalField';
import { ReactComponent as IconFile } from 'components/FileInput/assets/icon-file.svg';
import * as file from 'lib/utils/file';

import type { Maybe } from 'interfaces/entities';
import BlockIcon from '@mui/icons-material/Block';
import Icon from './Icon';

const defaultProps = {
  aspectRatio: '1 / 1' as string,
  hasShowButton: false,
  hasDownload: false,
  hasImageName: true,
};

type DocumentPreviewProps = {
  url: string,
  name?: Maybe<string>,
  thumbnail?: Maybe<string>,
  aspectRatio?: string,
  hasShowButton?: boolean,
  hasDownload?: boolean,
  hasImageName?: boolean,
  onRemove?: () => void,
} & typeof defaultProps;

const DocumentPreview = (props: DocumentPreviewProps) => {
  const {
    url,
    name,
    thumbnail,
    aspectRatio,
    hasShowButton,
    hasDownload,
    hasImageName,
    onRemove,
  } = props;

  const classes = useStyles();

  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const ext = file.getExt(url);
  const isImage = file.checkImage(url);
  const hasThumbnail = thumbnail && file.checkImage(thumbnail);
  const nameFinal = name || url.replace(/.*\/([^/]+)$/, '$1');
  const handleDownload = useCallback(() => {
    if (url) {
      file.download(url, nameFinal);
    }
  }, [url, nameFinal]);

  const preview = (
    <>
      {url && (isImage || hasThumbnail) && (
        <Paper sx={{ borderRadius: '0.5rem' }} elevation={1}>
          <Image className={classes.image} style={{ aspectRatio }} src={thumbnail || url} alt={nameFinal} />
        </Paper>
      )}
      {url && !(isImage || hasThumbnail) && (
        <Paper className={classes.document} elevation={1} style={{ aspectRatio }}>
          <IconFile />
          <Typography variant="caption" component="div">{ext}</Typography>
        </Paper>
      )}
      {!(url) && (
        <Paper className={classes.document} elevation={1} style={{ aspectRatio }}>
          <BlockIcon color="disabled" style={{ fontSize: 200 }} />
          <Typography variant="caption" component="div">{ext}</Typography>
        </Paper>
      )}
    </>
  );

  return (
    <div className={classes.container}>
      {hasShowButton && preview}
      {!hasShowButton && (
        <PreviewModalField src={url} button={preview} alt={nameFinal} />
      )}
      {hasImageName && (
        <div>
          <div className={cn(classes.textName, { [classes.textNameMobile]: isXSmall })}>
            <Typography fontSize={isXSmall ? 12 : 14} variant="inherit">{nameFinal}</Typography>
          </div>
            {hasShowButton && (
            <div className={classes.icon}>
              <PreviewModalField src={url} alt={nameFinal} />
            </div>
            )}
            {hasDownload && (
              <div className={classes.icon}>
                <IconButton hidden={hasDownload} onClick={handleDownload} color="primary">
                  <GetAppIcon />
                </IconButton>
              </div>
            )}
        </div>
      )}
      {onRemove && (
        <IconButton
          onClick={onRemove}
          aria-label="delete"
        >
          <Icon name="DeletePreviewDocumentsIcon" fontSize="medium" />
        </IconButton>
      )}
    </div>
  );
};

DocumentPreview.defaultProps = defaultProps;

const useStyles = createUseStyles({
  container: {
    '&': {
      width: '100%',
    },
    '& [aria-label="delete"]': {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
  image: {
    display: 'block',
    width: '100%',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    transform: 'translate(0, 0)',
    boxSizing: 'border-box',
    background: '#EBF3FF',
    objectFit: 'cover',
  },
  document: {
    '&': {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      // borderRadius: '0.5rem',
      // overflow: 'hidden',
      transform: 'translate(0, 0)',
      boxSizing: 'border-box',
      background: '#EBF3FF',
    },
    '& div': {
      position: 'absolute',
      left: 0,
      right: 0,
      top: '55%',
      textAlign: 'center',
    },
    '& svg': {
      width: '6rem',
    },
  },
  bottomBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textName: {
    paddingLeft: '0.5rem',
    textAlign: 'left',
  },
  textNameMobile: {
    marginTop: '0.25rem',
  },
  icon: {
    display: 'flex',
    height: '3rem',
  },
});

export default memo(DocumentPreview);
