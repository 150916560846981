import {
  FC, memo, useCallback, useRef,
} from 'react';
import {
  required, TextInput, useCreate, useNotify, useRefresh,
} from 'react-admin';
import { Button } from '@mui/material';
import { createUseStyles } from 'react-jss';

import * as ModalwithForm from 'components/ModalwithForm';
import { FormButton } from 'components//FormButton';

interface ModalCompanyNewMemberProps {
  ButtonProps?: {
    text: string,
    variant: 'contained' | 'outlined' | 'text',
    color: 'primary' | 'secondary' | 'inherit' | 'error' | 'success' | 'info' | 'warning',
    disabled?: boolean,
  },
}

const ModalCompanyNewMember: FC<ModalCompanyNewMemberProps> = (props) => {
  const {
    ButtonProps,
  } = props;

  const classes = useStyles();
  const dialogRef = useRef(null);
  const [create, { isLoading }] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClickOpen = useCallback(() => {
    ModalwithForm.open(dialogRef);
  }, []);

  const handleSubmit = useCallback(async (data: any) => {
    const {
      firstName,
      lastName,
      phone,
      email,
    } = data;

    try {
      await create('CompanyAdmin', {
        data: {
          firstName,
          lastName,
          phone,
          email,
        },
      }, {
        returnPromise: true,
      });
    } catch (error) {
      notify((error as Error).message, { type: 'warning' });
      return;
    }
    ModalwithForm.close(dialogRef);
    refresh();
  }, [create, notify, refresh]);

  return (
    <>
      <Button
        variant={ButtonProps?.variant || 'contained'}
        color={ButtonProps?.color || 'primary'}
        disabled={ButtonProps?.disabled || false}
        onClick={handleClickOpen}
      >
        {ButtonProps?.text || 'Invite New Member'}
      </Button>

      <ModalwithForm.Dialog
        ref={dialogRef}
        title="Create New Member"
        onSubmit={handleSubmit}
        bodyContent={(
          <div className={classes.container}>
            <div>
              <TextInput source="firstName" fullWidth variant="standard" validate={required()} />
              <TextInput source="email" fullWidth variant="standard" />
            </div>
            <div>
              <TextInput source="lastName" fullWidth variant="standard" validate={required()} />
              <TextInput source="phone" fullWidth variant="standard" validate={required()} />
            </div>
          </div>
        )}
        actionsRightSide={(
          <FormButton disabled={isLoading} variant="contained" color="primary" label="Invite" />
        )}
        layout="slim"
      />
    </>
  );
};

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    columnGap: '2rem',
  },
});

export default memo(ModalCompanyNewMember);
