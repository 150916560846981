import { memo } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import {
  InputProps, useInput, useLocaleState,
} from 'react-admin';
import { Typography, TextField } from '@mui/material';
import { useWatch } from 'react-hook-form';


const DateInputCustom = (props: InputProps) => {
  const isArchived = useWatch({ name: 'isArchived' });

  const { field: { value, onChange } } = useInput(props);

  const [locale] = useLocaleState();

  const format = {
    en: 'MM/dd/yyyy',
    he: 'yyyy.MM.dd',
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        disabled={isArchived}
        disablePast
        value={value}
        onChange={onChange}
        inputFormat={format[locale as 'en' | 'he']}
        InputProps={{ disableUnderline: true }}
        renderInput={(params) => (
          <Typography fontSize={15}>
            Date
            :&nbsp;
            <TextField
              margin="none"
              variant="standard"
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: 'black',
                },
                '.MuiInputBase-root': { width: '5.5rem', color: '#1976D2' },
                '.MuiInputBase-input': {
                  padding: 0, color: '#1976D2', fontSize: 15, fontWeight: 700,
                },
              }}
              {...params}
            />
          </Typography>
        )}
      />
    </LocalizationProvider>
  );
};

export default memo(DateInputCustom);
