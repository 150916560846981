import { memo, useCallback, useRef } from 'react';
import type { FC } from 'react';

import {
  Button, IconButton, Paper, Theme, Typography, useMediaQuery,
} from '@mui/material';
import { createUseStyles } from 'react-jss';
import {
  required, TextInput, useNotify, useUpdate,
  useRefresh,
} from 'react-admin';
import type { Identifier } from 'react-admin';

import { Client } from 'interfaces/entities';
import * as ModalwithForm from 'components/ModalwithForm';
import { FormButton } from 'components/FormButton';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';

interface DataType extends Omit<Client, 'id'> {
  id: Identifier,
  state: 'decline',
  status?: 'to_do' | 'approved',
}

interface ClientModalProps {
  id?: string | number,
  record?: DataType,
  onComplete?: (data: DataType) => void,
  hidden?: boolean,
  FormProps: {
    title: string,
    submitLabel: string,
  },
  ButtonProps?: {
    text: string,
    variant: 'contained' | 'outlined' | 'text',
    color: 'primary' | 'secondary' | 'inherit' | 'error' | 'success' | 'info' | 'warning',
    disabled?: boolean,
  },
}

const ClientModal: FC<ClientModalProps> = (props) => {
  const {
    id,
    ButtonProps,
    FormProps,
    record,
    onComplete,
    hidden,
  } = props;

  const classes = useStyles();
  const dialogRef = useRef(null);
  const [update, { isLoading: updating }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const loading = updating;
  const isDisabled = record?.status === 'to_do';
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const handleClickOpen = useCallback((event: { stopPropagation: () => void; }) => {
    event.stopPropagation();
    ModalwithForm.open(dialogRef);
  }, []);

  const handleSubmit = useCallback(async (data: DataType) => {
    const options = {
      returnPromise: true,
    };
    let task: DataType;
    try {
      const response = await update('Task', { id: record?.id, data, previousData: record }, options);
      task = response?.data;
    } catch (error) {
      notify((error as Error).message, { type: 'warning' });
      return;
    }
    refresh();
    ModalwithForm.close(dialogRef);
    setTimeout(() => {
      onComplete?.(task);
    }, 300);
  }, [refresh, record, update, notify, onComplete]);

  return (
    <>
      {isXSmall && (
        <Paper hidden={hidden || ButtonProps?.disabled || isDisabled} sx={{ borderRadius: '50%' }} elevation={5}>
          <IconButton
            sx={{ backgroundColor: '#D75241', color: '#fff' }}
            size="medium"
            onClick={handleClickOpen}
          >
            <DoNotDisturbAltOutlinedIcon />
          </IconButton>
        </Paper>
      )}
      {!isXSmall && (
      <Button
        variant={ButtonProps?.variant || 'contained'}
        color={ButtonProps?.color || 'primary'}
        disabled={ButtonProps?.disabled || isDisabled || false}
        onClick={handleClickOpen}
      >
        {ButtonProps?.text}
      </Button>
      )}
      <ModalwithForm.Dialog
        id={id}
        ref={dialogRef}
        title={FormProps?.title}
        onSubmit={handleSubmit}
        initialValues={{
          id: record?.id,
          state: 'decline',
        }}
        bodyContent={(
          <div className={classes.container}>
            <Typography className={classes.textField}>What is the reason for not approving the documents?</Typography>
            <TextInput source="notes" variant="standard" multiline fullWidth validate={required()} />
          </div>
                )}
        actionsRightSide={(
          <FormButton variant="contained" color="primary" label={FormProps.submitLabel} />
                )}
        layout="slim"
      />
    </>
  );
};

const useStyles = createUseStyles({
  container: {
    maxWidth: '20rem',
  },
  textField: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
});

export default memo(ClientModal);
