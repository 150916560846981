import { Resource } from 'react-admin';

import TaskTypeList from './TaskTypeList';
import TaskTypeEdit from './TaskTypeEdit';
import TaskTypeCreate from './TaskTypeCreate';

import getList from './graphql/providers/getList';
import getOne from './graphql/providers/getOne';
import create from './graphql/providers/create';
import update from './graphql/providers/update';
import uploadCsv from './graphql/providers/uploadCsv';
import deleteMany from './graphql/providers/deleteMany';

export default (
  <Resource
    name="TaskType"
    list={TaskTypeList}
    edit={TaskTypeEdit}
    create={TaskTypeCreate}
    options={{
      providers: {
        getList,
        getOne,
        update,
        create,
        uploadCsv,
        deleteMany,
      },
    }}
  />
);
