import { Client } from 'interfaces/entities';
import { useMemo } from 'react';

export default (record: Client) => useMemo(() => {
  const numberOfTasksOutstanding = record?.numberOfTasksOutstanding ?? 0;
  const numberOfTasksWaitingForReview = record?.numberOfTasksWaitingForReview ?? 0;
  const numberOfTasksTotal = record?.numberOfTasksTotal ?? 0;
  const isDidntStart = numberOfTasksTotal > 0 && numberOfTasksTotal === numberOfTasksOutstanding;
  const isAwaitingForReview = numberOfTasksTotal > 0 && numberOfTasksWaitingForReview > 0;
  const isCompleted = numberOfTasksTotal > 0 && numberOfTasksOutstanding === 0 && numberOfTasksWaitingForReview === 0;
  const isPartCompleted = !isDidntStart && !isAwaitingForReview && !isCompleted;
  return {
    outstanding: numberOfTasksOutstanding,
    waitingForReview: numberOfTasksWaitingForReview,
    total: numberOfTasksTotal,
    isDidntStart,
    isAwaitingForReview,
    isPartCompleted,
    isCompleted,
  };
}, [record?.numberOfTasksOutstanding, record?.numberOfTasksWaitingForReview, record?.numberOfTasksTotal]);
