import {
  FC, useCallback, memo, useRef, useState,
} from 'react';
import { DateTime } from 'luxon';
import { Button } from '@mui/material';
import { createUseStyles } from 'react-jss';

import * as Modal from 'components/Modal';
import FlatDatePicker from 'components/FlatDatePicker';

interface DatePickerProps {
  initValue?: string,
  onChange?: (value: string) => void,
}

const DatePicker: FC<DatePickerProps> = memo((props) => {
  const {
    initValue,
    onChange,
  } = props;

  const classes = useStyles();
  const dialogRef = useRef(null);
  const [value, setValue] = useState(initValue || DateTime.now().toFormat('yyyy-MM-dd'));

  const handleOpen = useCallback(() => {
    Modal.open(dialogRef);
  }, []);

  const handleSubmit = useCallback(() => {
    onChange?.(value);
    Modal.close(dialogRef);
  }, [onChange, value]);

  const handleChange = useCallback((newValue: string) => {
    setValue(newValue);
  }, []);

  return (
    <>
      {/*
        <Button
          variant="outlined"
          color="primary"
          endIcon={<DateRangeIcon />}
          onClick={handleOpen}
          className={classes.button}
        >
          Set due data for all
        </Button>
      */}
      <Modal.Dialog
        title="Set Due Date for All Tasks"
        ref={dialogRef}
        classes={{ body: classes.calendarBody }}
        bodyContent={(
          <FlatDatePicker
            className={classes.calendar}
            onChange={handleChange}
            value={value}
          />
        )}
        actionsStretchButtons
        actionsRightSide={(
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Apply
          </Button>
        )}
      />
    </>
  );
});

export default memo(DatePicker);

const useStyles = createUseStyles({
  calendarBody: {
    padding: 0,
  },
  calendar: {
    marginLeft: '-2.2rem',
    marginRight: '-2.2rem',
  },
  button: {
    whiteSpace: 'nowrap',
    maxWidth: 'none',
    minWidth: 'none',
    height: 40,
    marginBottom: 8,
  },
});
