import { Resource } from 'react-admin';

import NotificationEdit from './NotificationEdit';

import getOne from './graphql/providers/getOne';
import update from './graphql/providers/update';

export default <Resource
  name="Notification"
  edit={NotificationEdit}
  options={{
    providers: {
      getOne,
      update,
    },
  }}
/>;
