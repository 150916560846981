import { Resource } from 'react-admin';

import TaskList from './TaskList';
import TaskEdit from './TaskEdit';
import TaskCreate from './TaskCreate';

import getList from './graphql/providers/getList';
import getOne from './graphql/providers/getOne';
import create from './graphql/providers/create';
import update from './graphql/providers/update';
import updateMany from './graphql/providers/updateMany';
import deleteMany from './graphql/providers/deleteMany';

export default <Resource
  name="Task"
  list={TaskList}
  edit={TaskEdit}
  create={TaskCreate}
  options={{
    providers: {
      getList,
      getOne,
      create,
      update,
      updateMany,
      deleteMany,
    },
  }}
/>;
