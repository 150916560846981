import { memo, PropsWithChildren, ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { Typography, IconButton } from '@mui/material';
import IconRemoveCircle from '@mui/icons-material/RemoveCircle';

import { ReactComponent as IconFile } from '../assets/icon-file.svg';

export type ItemComponentProps = {
  data?: {
    name?: string,
    url?: string,
  },
  onRemove?: () => void,
};

const DefaultItemComponent = (props:PropsWithChildren<ItemComponentProps>): ReactElement => {
  const { data, onRemove } = props;

  const classes = useStyles();

  const isImage = /\.(png|jpg|jpeg|gif|tif|webp)$/.test(data?.url || '');
  const ext = (data?.name || data?.url || '').replace(/.*\.([A-z0-9]{3,4})$/, '$1');

  return (
    <div className={classes.item}>
      {isImage && <img className={classes.image} src={data?.url} alt={data?.name} />}
      {!isImage && (
        <div className={classes.document}>
          <IconFile />
          <Typography variant="caption" component="div">{ext}</Typography>
        </div>
      )}
      <Typography className={classes.name} variant="caption">{data?.name}</Typography>
      <IconButton onClick={onRemove} aria-label="delete" color="secondary">
        <IconRemoveCircle fontSize="inherit" />
      </IconButton>
    </div>
  );
};

const useStyles = createUseStyles({
  item: {
    '&': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      maxWidth: '8rem',
    },
    '& [aria-label="delete"]': {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
  image: {
    display: 'block',
    width: '8rem',
    height: '8rem',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    transform: 'translate(0, 0)',
    boxSizing: 'border-box',
    background: '#EBF3FF',
  },
  document: {
    '&': {
      display: 'flex',
      width: '8rem',
      height: '8rem',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '0.5rem',
      overflow: 'hidden',
      transform: 'translate(0, 0)',
      boxSizing: 'border-box',
      background: '#EBF3FF',
    },
    '& div': {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '2rem',
      textAlign: 'center',
    },
    '& svg': {
      width: '6rem',
    },
  },
  name: {
    margin: '0.3rem 0.2rem 0',
  },
});

export default memo(DefaultItemComponent);
