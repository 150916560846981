import { memo } from 'react';
import { Box, Typography } from '@mui/material';

const Empty = () => {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '10rem',
    }}
    >
      <Typography sx={{ color: '#D9E1ED' }}>PLEASE ADD NEW TASK</Typography>
    </Box>
  );
};

export default memo(Empty);
