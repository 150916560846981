import React, { memo, PropsWithChildren } from 'react';
import {
  Datagrid, Identifier, TextField, useTranslate,
} from 'react-admin';
import { createUseStyles } from 'react-jss';
import DueDate, { Item } from '../elements/DueDate';

type DesktopTasksInputProps = {
  selectedItems: Item[],
  onChange?: (taskId: Identifier, dueDateAt: string) => void,
};
const DesktopTasksInput = (props: PropsWithChildren<DesktopTasksInputProps>) => {
  const {
    selectedItems, onChange,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Datagrid
      sx={{
        '& .RaBulkActionsToolbar-toolbar': {
          display: 'none',
        },
      }}
      className={classes.tasks}
    >
      <TextField source="id" label="Id" />
      <TextField source="name" label={translate('resources.task.taskName')} />
      <DueDate source={translate('resources.task.dueDate')} selectedItems={selectedItems} onChange={onChange} />
    </Datagrid>
  );
};

const useStyles = createUseStyles({
  tasks: {
    '& tr:last-child td': {
      borderBottom: 0,
    },
    '& .MuiTableCell-paddingCheckbox ': {
      width: '3rem',
      height: '3.1rem',
      paddingRight: 0,
      paddingLeft: '0.5rem',
    },
    '& .column-id': {
      width: '3rem',
      paddingRight: 0,
    },
    '& .column-selector': {
      width: '3rem',
      paddingRight: 0,
      paddingLeft: '0.5rem',
    },
    '& .column-Due.Date': {
      '&': {
        width: '8rem',
        paddingRight: 0,
        paddingLeft: 0,
      },
      '& > div': {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
  },
});

export default memo(DesktopTasksInput);
