import type { FC } from 'react';
import { memo } from 'react';

import type { ItemComponentProps } from 'components/FileInput';
import DocumentPreview from 'components/DocumentPreview';
import { Box } from '@mui/material';

interface ImageItemProps extends Omit<ItemComponentProps, 'data'> {
  data?: {
    id?: number,
    thumbnail?: string,
  } & ItemComponentProps['data']
}

const ImageItem: FC<ImageItemProps> = (props) => {
  const { data, onRemove } = props;

  if (!data?.url && !data?.id) {
    return null;
  }

  if (!data?.url) {
    return (
      <Box
        sx={{
          boxSizing: 'border-box',
          border: '1px solid #2D67BD',
          borderRadius: '0.5rem',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          maxWidth: '14rem',
          maxHeight: '11rem',
          marginRight: '2rem',
        }}
      >
        <DocumentPreview
          onRemove={onRemove}
          url=""
          name="Unavailable Document"
          aspectRatio="5 / 4"
          hasImageName={false}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        border: '1px solid #2D67BD',
        borderRadius: '0.5rem',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        maxWidth: '14rem',
        maxHeight: '11rem',
        marginRight: '2rem',
      }}
    >
      <DocumentPreview
        onRemove={onRemove}
        url={data.url}
        name=""
        thumbnail={data.thumbnail}
        aspectRatio="5 / 4"
        hasImageName={false}
      />
    </Box>
  );
};

export default memo(ImageItem);
