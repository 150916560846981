import type { PropsWithChildren } from 'react';
import {
  CSSProperties, memo, ReactNode, useCallback, useMemo, useState,
} from 'react';
import { Box, CircularProgress } from '@mui/material';

type LinkButtonProps = {
  icon?: ReactNode,
  href?: string,
  style?: CSSProperties,
  text?: any,
  disable?: boolean,
  variant?: 'bulk' | 'text' | 'mobile'
};

const LinkButton = (props:PropsWithChildren<LinkButtonProps>) => {
  const {
    icon, href, style, text, disable, variant,
  } = props;

  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
    setLoading(true);
  }, []);

  const styles = useMemo(() => {
    const baseStyle: CSSProperties = {};
    if (variant === 'bulk') {
      baseStyle.textDecoration = 'none';
      baseStyle.alignSelf = 'flex-end';
      baseStyle.position = 'relative';
      baseStyle.display = 'flex';
      baseStyle.fontSize = '0.875rem';
      baseStyle.letterSpacing = '0.02857em';
      baseStyle.color = '#1976d2';
      baseStyle.justifyContent = 'space-between';
    }
    if (variant === 'text') {
      baseStyle.textDecoration = 'none';
      baseStyle.alignSelf = 'flex-end';
      baseStyle.position = 'relative';
      baseStyle.display = 'flex';
      baseStyle.fontSize = '0.8125rem';
      baseStyle.letterSpacing = '0.02857em';
      baseStyle.color = '#1976d2';
      baseStyle.paddingRight = '0.5rem';
      baseStyle.paddingLeft = '0.5rem';
      baseStyle.marginLeft = '0.25rem';
      baseStyle.paddingBottom = '0.25rem';
    }
    if (variant === 'mobile') {
      baseStyle.textDecoration = 'none';
      baseStyle.alignSelf = 'flex-end';
      baseStyle.position = 'relative';
      baseStyle.display = 'flex';
      baseStyle.fontSize = '0.875rem';
      baseStyle.letterSpacing = '0.02857em';
      baseStyle.justifyContent = 'center';
      baseStyle.alignItems = 'center';
      baseStyle.backgroundColor = '#757575';
      baseStyle.borderRadius = '50%';
      baseStyle.width = '2.5rem';
      baseStyle.height = '2.5rem';
      baseStyle.color = '#fff';
    }
    if (disable) {
      baseStyle.filter = 'grayscale(1)';
      baseStyle.pointerEvents = 'none';
    }
    return { ...baseStyle, ...style };
  }, [style, variant, disable]);

  return (
    <a onClick={handleClick} style={styles} download href={href}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          opacity: loading ? 0.3 : 1,
        }}
      >
        {icon}
        {!!text && (
          <Box
            sx={{
              display: 'flex',
              marginLeft: '4px',
            }}
          >
            {text}
          </Box>
        )}
      </Box>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <CircularProgress
            size={22}
            color="inherit"
          />
        </Box>
      )}
    </a>
  );
};

export default memo(LinkButton);
