import { gql, UpdateProvider } from 'services/dataProvider';

import { fields as NotificationFields } from '../fragments';

const provider: UpdateProvider = (params) => {
  const mutation = gql`
    ${NotificationFields}
    mutation ($input: NotificationUpdateInput!) {
      notification {
        update (input: $input) {
          ...NotificationFields
        }
      }
    }
  `;
  return {
    mutation,
    variables: {
      input: {
        id: params.data.id,
        shouldNotifyWhenAllTasksSubmittedByClient: params.data.shouldNotifyWhenAllTasksSubmittedByClient,
        shouldNotifyWhenEachUploadedTask: params.data.shouldNotifyWhenEachUploadedTask,
        countDaysAfterPastDueForTasks: params.data.countDaysAfterPastDueForTasks,
        shouldNotifyNewTaskWasAdded: params.data.shouldNotifyNewTaskWasAdded,
        shouldNotifyTaskWasDenied: params.data.shouldNotifyTaskWasDenied,
        shouldNotifyWhenAllApproved: params.data.shouldNotifyWhenAllApproved,
        shouldNotifyViaSMS: params.data.shouldNotifyViaSMS,
        shouldNotifyViaEmail: params.data.shouldNotifyViaEmail,
        shouldNotifyViaPush: params.data.shouldNotifyViaPush,
        countDaysBeforeDueDate: params.data.countDaysBeforeDueDate,
      },
    },
  };
};

export default provider;
