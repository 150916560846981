import { memo } from 'react';
import {
  Box, Card, CardHeader, CardContent, Typography,
} from '@mui/material';
import {
  EditButton,
  TextField,
  useTranslate,
  useListContext,
  RecordContextProvider,
} from 'react-admin';

import DateFieldCustom from 'components/DateFieldCustom';
import ClientTaskStatus from 'components/ClientTaskStatus';

const MobileGrid = () => {
  const { data, isLoading } = useListContext();

  const translate = useTranslate();
  if (isLoading || data.length === 0) {
    return null;
  }
  return (
    <Box>
      {data.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
          <Card sx={{ margin: '0.5rem 0' }}>
            <CardHeader
              title={(
                <TextField fontSize="1rem" source="task.name" label="resources.task.taskName" />
              )}
              titleTypographyProps={{ variant: 'body1' }}
              action={<EditButton />}
              sx={{ pt: '0.5rem', pb: 0 }}
            />
            <CardContent sx={{ pt: 0 }}>
              <DateFieldCustom
                variant="body2"
                colorEmptyText="grey"
                emptyText="still not updates"
                label={translate('resources.task.lastUpdate')}
                source="lastUpdatedAt"
              />
              <Typography variant="body2">
                {translate('resources.task.clientName')}
                :&nbsp;
                <TextField source="client.firstName" />
                &nbsp;
                <TextField source="client.lastName" />
              </Typography>
              <DateFieldCustom variant="body2" label={translate('resources.task.dueDate')} source="dueDateAt" />
              <Typography variant="body2">
                {translate('resources.task.docs')}
                :&nbsp;
                <TextField source="documents.length" />
              </Typography>
              <Typography hidden={!record.notes} variant="body2">
                {translate('resources.task.notes')}
                :&nbsp;
                <TextField source="notes" />
              </Typography>
              <Typography sx={{ display: 'grid', gridTemplateColumns: '0.1fr 1fr' }} variant="body2">
                {translate('resources.task.status')}
                :&nbsp;
                <ClientTaskStatus source="status" label={translate('resources.task.status')} />
              </Typography>
            </CardContent>
          </Card>
        </RecordContextProvider>
      ))}
    </Box>
  );
};

MobileGrid.defaultProps = {
  data: {},
  ids: [],
};

export default memo(MobileGrid);
