import { memo, useCallback } from 'react';
import type { ReactElement } from 'react';
import { useTranslate, useInput } from 'react-admin';
import { blue, grey } from '@mui/material/colors';
import { Checkbox, FormControlLabel } from '@mui/material';


type isCheckIsOngoingProps = {
  label: string,
  source: string,
  alwaysOn?: boolean,
};

const CheckIsOngoing = (props: isCheckIsOngoingProps): ReactElement => {
  const {
    label,
    source,
    alwaysOn,
  } = props;

  const { field } = useInput({ source });

  const handleChange = useCallback((event: { target: { type: string; checked: boolean; }; }) => {
    if (event.target.type === 'checkbox' && event.target.checked === true) {
      field.onChange(true);
      return;
    }
    if (event.target.type === 'checkbox') {
      field.onChange(false);
    }
  }, [field]);

  const translate = useTranslate();

  return (
    <FormControlLabel
      control={(
        <Checkbox
          sx={{
            color: grey[700],
            '&.Mui-checked': {
              color: blue[600],
            },
          }}
          checked={field.value === true}
          onChange={handleChange}
        />
      )}
      label={translate(label)}
    />
  );
};

export default memo(CheckIsOngoing);
