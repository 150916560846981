import EventEmitter from 'events';
import type { History } from 'history';

import request from 'lib/request';
import type { RequestResult } from 'lib/request';

import type {
  ServerCheckResponse, UserIdentity, AuthProviderInterface, RequestCodeParams, LoginParams, CheckAuthParams, RequestCodeResponse, LoginResponse, GetPermissionsResult,
} from './types';
import { OnboardParams, OnboardResponse } from './types';

class AuthProvider extends EventEmitter implements AuthProviderInterface {
  public identity: UserIdentity = {
    id: '',
  };

  private permissions: string[] = [];

  private role: 'owner' | 'member' | 'guest' = 'guest';

  private $history: History | null = null;

  set history(pointer: History) {
    this.$history = pointer;
  }

  set phone(phone: string) {
    this.identity.phone = phone;
  }

  get phone(): string {
    return this.identity.phone || '';
  }

  async checkAuth(params?: CheckAuthParams): Promise<void> {
    const isIncludeIdentity = !this?.identity?.id || (params?.include || []).includes('identity');
    if (isIncludeIdentity) {
      this.emit('IDENTITY_LOADING');
    }
    const { data, error } = await request.get<ServerCheckResponse>('/api/auth/check', {
      include: isIncludeIdentity ? 'identity' : undefined,
    });
    if (isIncludeIdentity) {
      this.emit('IDENTITY_LOADED');
    }
    if (error || !data) {
      this?.$history?.push('/login');
      return Promise.reject();
    }
    if (!data?.hasSession) {
      this?.$history?.push('/login');
      return Promise.reject();
    }
    if (isIncludeIdentity && data?.identity) {
      this.identity = {
        ...this.identity,
        id: data?.identity?.id,
        email: data?.identity?.email,
        phone: data?.identity?.phone,
        firstName: data?.identity?.firstName,
        lastName: data?.identity?.lastName,
        avatarUrl: data?.identity?.avatarUrl,
        didCompleteRegistration: data?.identity?.didCompleteRegistration,
        company: {
          id: data?.identity?.company?.id,
          name: data?.identity?.company?.name,
          website: data?.identity?.company?.website,
          logoUrl: data?.identity?.company?.logoUrl,
        },
      };
      this.permissions = data?.permissions || [];
      this.role = data?.role || 'guest';
      this.emit('IDENTITY_UPDATED');
    }
    if (/^\/code/.test(this?.$history?.location?.pathname || '') && !this.identity.phone) {
      return Promise.reject();
    }
    if (/^\/onboard/.test(this?.$history?.location?.pathname || '') && !this.identity.id) {
      delete this.identity.phone;
      return Promise.reject();
    }
    if (!this.identity?.didCompleteRegistration) {
      this?.$history?.push('/onboard');
      return Promise.resolve();
    }
    if (/^\/login|code|onboard/.test(this?.$history?.location?.pathname || '')) {
      this?.$history?.push('/Task');
    }
    return Promise.resolve();
  }

  async requestCode(params: RequestCodeParams): Promise<RequestResult<RequestCodeResponse>> {
    const { phone } = params;
    if (!phone) {
      return {
        data: null,
        error: 'Phone number was not provided',
      };
    }
    this.identity.phone = phone;
    const response = await request.post<RequestCodeResponse>('/api/auth/requestCode', null, { phone });
    if (response.error) {
      return response;
    }
    this?.$history?.push('/code');
    return response;
  }

  async login(params: LoginParams): Promise<RequestResult<LoginResponse>> {
    const { code } = params;
    if (!this.identity.phone) {
      return {
        data: null,
        error: 'Phone number was not registered',
      };
    }
    const { phone } = this.identity;
    const response = await request.post<LoginResponse>('/api/auth/login', null, { phone, code });
    if (response.error) {
      return response;
    }
    await this.checkAuth({ include: ['identity'] });
    return response;
  }

  async onboard(params: OnboardParams): Promise<RequestResult<OnboardResponse>> {
    const response = await request.post<OnboardResponse>('/api/auth/onboard', null, params);
    if (response.error) {
      return response;
    }
    await this.checkAuth({ include: ['identity'] });
    return response;
  }

  async logout(): Promise<void> {
    await request.post('/api/auth/logout');
    this.identity = {
      id: '',
    };
    this.emit('IDENTITY_UPDATED');
    // @todo После логаута повторный логин открывает пустой интерфейс. Надо разобраться почему, возможно обновление версии это решил. Временное решение обновить страницу.
    window.location.href = '/login';
    return Promise.resolve();
  }

  async checkError(error: any): Promise<void> {
    return undefined;
  }

  async getIdentity(): Promise<UserIdentity> {
    return this.identity || { id: '' };
  }

  async getPermissions(params?: any): Promise<any> {
    return this.permissions || [];
  }

  async getRole(params?: any): Promise<any> {
    return this.role || 'guest';
  }
}

export default new AuthProvider();
