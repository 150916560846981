import { Resource } from 'react-admin';

import CompanyAdminList from './CompanyAdminList';
import CompanyAdminCreate from './CompanyAdminCreate';

import getList from './graphql/providers/getList';
import create from './graphql/providers/create';
import deleteMany from './graphql/providers/deleteMany';

export default <Resource
  name="CompanyAdmin"
  list={CompanyAdminList}
  create={CompanyAdminCreate}
  options={{
    providers: {
      getList,
      create,
      deleteMany,
    },
  }}
/>;
