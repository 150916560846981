import { memo, useCallback } from 'react';
import {
  Box, Card, CardHeader, CardContent, Typography, Checkbox,
} from '@mui/material';
import ForwardIcon from '@mui/icons-material/Forward';

import {
  EditButton,
  TextField,
  useTranslate,
  useListContext,
  RecordContextProvider, useRedirect, useLocaleState,
} from 'react-admin';
import DateFieldCustom from 'components/DateFieldCustom';
import ClientTaskStatus from 'components/ClientTaskStatus';

const MobileGrid = () => {
  const redirect = useRedirect();
  const {
    data, isLoading, onToggleItem,
  } = useListContext();

  const [locale] = useLocaleState();

  const translate = useTranslate();

  const handleCheck = useCallback((event: {
    target: any ;
    stopPropagation: () => void; }) => {
    event.stopPropagation();
    onToggleItem(Number(event.target.id));
  }, [onToggleItem]);

  if (isLoading || !data) {
    return null;
  }

  return (
    <Box>
      {data.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
          <Card
            onClick={() => {
              redirect('edit', 'Task', record.id);
            }}
            sx={{ margin: '0.5rem 0' }}
          >
            <CardHeader
              title={(
                <>
                  <Checkbox
                    sx={{ marginLeft: '-0.7rem' }}
                    onClick={handleCheck}
                    id={record.id}
                  />
                  <TextField source="task.name" label="resources.task.taskName" />
                </>
              )}
              titleTypographyProps={{ variant: 'body1' }}
              sx={{ padding: '0.5rem 1rem 0 1rem' }}
              action={locale === 'en' ? <EditButton icon={<ForwardIcon />} /> : <EditButton icon={<ForwardIcon sx={{ transform: 'scaleX(-1)' }} />} />}
            />
            <CardContent sx={{
              pt: 0, '&:last-child': { pb: '1rem' },
            }}
            >
              <DateFieldCustom
                variant="body2"
                colorEmptyText="grey"
                emptyText="still not updates"
                label={translate('resources.task.lastUpdate')}
                source="lastUpdatedAt"
              />
              <DateFieldCustom variant="body2" label={translate('resources.task.dueDate')} source="dueDateAt" />
              <Typography variant="body2">
                {translate('resources.task.docs')}
                :&nbsp;
                <TextField source="documents.length" />
              </Typography>
              <Typography sx={{ display: 'grid', gridTemplateColumns: '0.1fr 1fr' }} variant="body2">
                {translate('resources.task.status')}
                :&nbsp;
                <ClientTaskStatus source="status" label={translate('resources.task.status')} />
              </Typography>
              <Typography hidden={!record.notes} variant="body2">
                {translate('resources.task.notes')}
                :&nbsp;
                <TextField source="notes" />
              </Typography>
            </CardContent>
          </Card>
        </RecordContextProvider>
      ))}
    </Box>
  );
};

export default memo(MobileGrid);
