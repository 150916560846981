import { gql } from 'services/dataProvider';

export const fields = gql`
  fragment ClientTaskFields on ClientTask {
    id
    status
    dueDateAt
    notes
    documents {
      id
      thumbnail
      url
      name
    }
    client {
      id
      firstName
      lastName
    }
    task {
      id
      name
      description
      isOngoing
    }
    isArchived
    createdAt
    lastUpdatedAt  
  }
`;
