import { memo } from 'react';
import { List } from '@mui/material';
import { RecordContextProvider, useListContext } from 'react-admin';

import { TaskTypeItem } from './TaskTypeItem';

const TaskTypeListMobile = () => {
  const { data, isLoading, total } = useListContext();
  if (isLoading || Number(total) === 0) {
    return null;
  }

  return (
    <List>
      {data.map((review) => (
        <RecordContextProvider value={review} key={review.id}>
          <TaskTypeItem />
        </RecordContextProvider>
      ))}
    </List>
  );
};

export default memo(TaskTypeListMobile);
