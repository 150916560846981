import { memo, useCallback } from 'react';
import type { FC } from 'react';
import {
  Edit, SimpleForm, useTranslate, DateInput,
} from 'react-admin';
import { Theme, Typography, useMediaQuery } from '@mui/material';

import { createUseStyles } from 'react-jss';
import FileInput from 'components/FileInput';
import RenderField from 'components/RenderField';

import DocumentEmpty from './elements/DocumentEmpty';
import DocumentItem from './elements/DocumentItem';
import DocumentAdd from './elements/DocumentAdd';
import DocumentProgress from './elements/DocumentProgress';
import ActionsToolbar from './elements/ActionsToolbar';
import UserLink from './elements/UserLink';
import DownloadAll from './elements/DownloadAll';
import TaskEditMobile from './elements/TaskEditMobile';

const TaskEdit: FC = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const renderStatus = useCallback((record: { status: 'to_do' | 'approved' | 'waiting_for_review' } | any): string => {
    const values: Record<string, string> = {
      to_do: translate('resources.task.values.status.todo'),
      approved: translate('resources.task.values.status.approved'),
      waiting_for_review: translate('resources.task.values.status.inReview'),
    };
    return values[record?.status];
  }, [translate]);

  return (
    <>
      {isXSmall && (<TaskEditMobile />)}
      {!isXSmall && (
        <Edit className={classes.container} {...props}>
          <SimpleForm toolbar={<ActionsToolbar />}>
            <div className={classes.textGroup}>
              <div className={classes.info}>
                <DateInput variant="standard" name="dueDateAt" source="dueDateAt" label={translate('resources.task.dueDate')} />
                <div className={classes.status} />
                <RenderField
                  label={translate('resources.task.clientName')}
                  render={(record) => (
                    <UserLink record={record} />
                  )}
                />
                <RenderField label="Id" render={(record) => `${record?.id}`} />
                <RenderField
                  label={translate('resources.task.status')}
                  render={(record) => <Typography className={classes.statusText}>{`${renderStatus(record)}`}</Typography>}
                />
                <RenderField
                  label={translate('resources.task.archived')}
                  render={(record) => `${record?.isArchived ? translate('resources.task.yes') : translate('resources.task.no')}`}
                />
              </div>
              <DownloadAll />
            </div>
            <FileInput
              source="documents"
              label=""
              multiple
              name="documents"
              accept={['image/jpeg', 'image/png', 'image/gif', 'image/tiff', 'application/pdf']}
              progressComponent={<DocumentProgress />}
              addComponent={<DocumentAdd />}
              emptyComponent={<DocumentEmpty />}
              itemComponent={<DocumentItem />}
            />
          </SimpleForm>
        </Edit>
      ) }
    </>
  );
};

const useStyles = createUseStyles({
  status: {
    flex: 1,
  },
  statusText: {
    minWidth: '5rem',
  },
  container: {
    '&': {
      width: '65%',
      flex: 0,
    },
  },
  textGroup: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'stretch',
  },
  info: {
    '&': {
      display: 'flex',
    },
    '& > div': {
      marginLeft: '2rem',
    },
    '& > div:first-child': {
      marginLeft: 0,
    },
  },
});

export default memo(TaskEdit);
