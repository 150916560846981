import {
  Box, Button, Typography,
} from '@mui/material';
import {
  ChangeEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import {
  useTranslate, useDataProvider, useRefresh,
} from 'react-admin';
import * as Modal from 'components/Modal';
import DownloadButton from './DownloadButton';


const UploadCSVButton = (props: any) => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [uploadError, setUploadError] = useState<string | null>(null);
  const refresh = useRefresh();
  const dialogRef = useRef(null);

  useEffect(() => {
    if (uploadError) {
      Modal.open(dialogRef);
    }
  }, [uploadError]);

  const handleFileChange = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const changeFile = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64 = (reader.result as string).split(',')[1];
        try {
          await dataProvider.uploadCsv('TaskType', { data: { base64 } });
          refresh();
        } catch (err) {
          setUploadError(err.message);
        }
      };
      reader.readAsDataURL(changeFile);
    }
    event.target.value = '';
    setUploadError(null);
  }, [refresh, dataProvider]);

  const handleClickClose = useCallback(() => {
    Modal.close(dialogRef);
    setTimeout(() => {
      setUploadError(null);
    }, 300);
  }, []);

  return (
    <div>
      <input
        type="file"
        accept=".csv"
        style={{ display: 'none' }}
        id="upload-csv"
        onChange={handleFileChange}
      />
      <label htmlFor="upload-csv">
        <Button
          variant="outlined"
          color="primary"
          sx={{
            marginRight: '1rem',
            marginLeft: '1rem',
          }}
          component="span"
        >
          {translate('resources.taskType.action.uploadCsv')}
        </Button>
      </label>
      <Modal.Dialog
        ref={dialogRef}
        title="Error upload csv file"
        layout="slim"
        bodyContent={(<Typography>{uploadError}</Typography>)}
        actionsRightSide={(
          <Box marginLeft={8}>
            <DownloadButton variant="button" onClick={handleClickClose} url="/Example.csv" filename="Example.csv" />
          </Box>
        )}
      />
    </div>
  );
};

export default UploadCSVButton;
