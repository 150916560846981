import {
  memo, useCallback, useEffect, useRef,
} from 'react';
import type { FC } from 'react';
import {
  useNotify, useRefresh, useUnselectAll, useDeleteMany, useListContext, Button,
} from 'react-admin';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Typography, Button as ButtonMUI } from '@mui/material';
import { createUseStyles } from 'react-jss';

import * as Modal from 'components/Modal';

interface BulkActionButtonProps {
  label?: string
}

const BulkDeleteButton: FC<BulkActionButtonProps> = (props) => {
  const { label } = props;
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll('CompanyAdmin');
  const { selectedIds } = useListContext();
  const dialogRef = useRef(null);
  const classes = useStyles();

  const [deleteMany, {
    isLoading, error, data,
  }] = useDeleteMany();

  const handleClickOpen = useCallback(() => {
    Modal.open(dialogRef);
  }, []);


  const handleClick = useCallback(async () => {
    await deleteMany('CompanyAdmin', { ids: selectedIds });
    Modal.close(dialogRef);
  }, [deleteMany, selectedIds]);

  useEffect(() => {
    if (!isLoading && error) {
      notify(`Member not deleted: ${error}`, { type: 'warning' });
    }
    if (!isLoading && !error && data) {
      notify('Member deleted', { type: 'info' });
      unselectAll();
      refresh();
    }
  }, [data, error, isLoading, notify, refresh, unselectAll]);

  return (
    <>
      <Button
        startIcon={<DeleteForeverOutlinedIcon />}
        className={classes.button}
        label={label}
        onClick={handleClickOpen}
        disabled={isLoading}
        color="error"
      />
      <Modal.Dialog
        ref={dialogRef}
        title="Deleting company members"
        layout="slim"
        bodyContent={(<Typography>Are you sure want to delete selected members?</Typography>)}
        actionsRightSide={(
          <ButtonMUI
            variant="contained"
            color="primary"
            onClick={handleClick}
            disabled={isLoading}
          >
            Delete
          </ButtonMUI>
        )}
      />
    </>
  );
};

const useStyles = createUseStyles({
  button: {
    '&': {
      paddingRight: '0.5rem',
      paddingLeft: '0.5rem',
      marginLeft: '0.25rem',
      fontSize: '0.8125rem',
      color: 'red',
    },
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
  },
});

export default memo(BulkDeleteButton);
