import { memo, useCallback } from 'react';
import type { ReactElement } from 'react';
import { useTranslate, useInput } from 'react-admin';
import { grey } from '@mui/material/colors';
import { Checkbox, FormControlLabel } from '@mui/material';


type isArchivedFilterProps = {
  label: string,
  source: string,
  alwaysOn?: boolean,
};

const IsArchivedFilter = (props: isArchivedFilterProps): ReactElement => {
  const {
    label,
    source,
    alwaysOn,
  } = props;

  const { field } = useInput({ source });

  const handleChange = useCallback((event: { target: { type: string; checked: boolean; }; }) => {
    if (event.target.type === 'checkbox' && event.target.checked === true) {
      field.onChange('archived');
      return;
    }
    if (event.target.type === 'checkbox') {
      field.onChange('unarchived');
    }
  }, [field]);

  const translate = useTranslate();

  return (
    <FormControlLabel
      control={(
        <Checkbox
          sx={{
            color: grey[800],
            '&.Mui-checked': {
              color: grey[600],
            },
          }}
          checked={field.value === 'archived'}
          onChange={handleChange}
        />
)}
      label={translate(label)}
    />
  );
};

export default memo(IsArchivedFilter);
