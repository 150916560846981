import { FC, memo, useCallback } from 'react';
import {
  Typography, CircularProgress, useMediaQuery, Theme, Portal, Paper, Fab,
} from '@mui/material';
import { createUseStyles } from 'react-jss';

import AddIcon from '@mui/icons-material/Add';
import { useWatch } from 'react-hook-form';

import type { EmptyComponentProps } from 'components/FileInput';

const DocumentEmptyMobile: FC<EmptyComponentProps> = (props) => {
  const { isLoading, files, percent } = props;
  const classes = useStyles();

  const isArchived = useWatch({ name: 'isArchived' });
  const progressValue = (percent || 0) * 100;

  const handleStopPropagation = useCallback((event: { stopPropagation: () => void; }) => {
    event.stopPropagation();
  }, []);

  if (isLoading) {
    return (
      <div className={classes.emptyMobile}>
        <div className={classes.icon}>
          <CircularProgress variant="determinate" value={progressValue} />
          <Typography className={classes.loading} variant="caption" component="div" color="textSecondary">
            {`${progressValue.toFixed(0)}%`}
          </Typography>
        </div>
        <Typography className={classes.text} variant="caption" component="div">
          {`uploading ${files?.length} ${files?.length === 1 ? 'file' : 'files'}`}
          <br />
          please wait
        </Typography>
      </div>
    );
  }

  return (
    <>
      <div className={classes.emptyMobile}>
        <Typography className={classes.text} variant="caption" component="div">
          There are no documents here yet
        </Typography>
      </div>
      <Portal>
        <Paper
          hidden={isArchived}
          sx={{
            borderRadius: '50%',
            position: 'fixed',
            bottom: '4.3rem',
            zIndex: 100,
            right: '1.3rem',
            backgroundColor: '#1976D2',
          }}
          elevation={5}
        >
          <Fab sx={{ backgroundColor: 'transparent' }} onClick={handleStopPropagation} disabled>
            <AddIcon sx={{ color: 'white' }} />
          </Fab>
        </Paper>
      </Portal>
    </>
  );
};

const useStyles = createUseStyles({
  emptyMobileLoading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0.5rem',
    padding: 0,
    width: 'calc(50% - 0.25rem)',
  },
  emptyMobile: {
    '&': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '0.5rem',
      padding: 0,
      width: '100%',
    },
  },
  icon: {
    '&': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '6.1rem',
    },
    '& svg': {
      height: '100%',
    },
  },
  loading: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.625rem',
  },
  text: {
    marginTop: '40%',
    fontSize: '15px',
    color: '#717171',
    lineHeight: '18px',
  },
});

export default memo(DocumentEmptyMobile);
