import { FC, memo } from 'react';
import { Typography, CircularProgress, Box } from '@mui/material';
import { useTranslate } from 'react-admin';
import { createUseStyles } from 'react-jss';

import type { EmptyComponentProps } from 'components/FileInput';
import { ReactComponent as DownloadIcon } from 'assets/images/DownloadIcon.svg';

const ImageEmpty: FC<EmptyComponentProps> = (props) => {
  const { isLoading, files, percent } = props;
  const classes = useStyles();
  const progressValue = (percent || 0) * 100;
  const translate = useTranslate();

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          boxSizing: 'border-box',
          border: '1px solid #2D67BD',
          borderRadius: '0.5rem',
          height: '11rem',
          width: '14rem',
          marginRight: '2rem',
        }}
      >
        <div className={classes.icon}>
          <CircularProgress variant="determinate" value={progressValue} />
          <Typography
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '0.625rem',
            }}
            variant="caption"
            component="div"
            color="textSecondary"
          >
            {`${progressValue.toFixed(0)}%`}
          </Typography>
        </div>
        <Typography sx={{ marginTop: '0.8rem' }} variant="caption" component="div">
          {`uploading ${files?.length} ${files?.length === 1 ? 'file' : 'files'}`}
          <br />
          please wait
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        boxSizing: 'border-box',
        border: '1px solid #2D67BD',
        borderRadius: '0.5rem',
        height: '11rem',
        width: '14rem',
        marginRight: '2rem',
      }}
    >
      <div className={classes.icon}>
        <DownloadIcon />
      </div>
      <Typography sx={{ marginTop: '0.8rem' }} variant="caption" component="div">
        {translate('resources.taskType.fields.dropFileHereToUpload')}
      </Typography>
    </Box>
  );
};

const useStyles = createUseStyles({
  icon: {
    '&': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '4.1rem',
    },
    '& svg': {
      height: '100%',
    },
  },
  loading: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.625rem',
  },
});

export default memo(ImageEmpty);
