import {
  useState, useCallback, useEffect,
} from 'react';
import authProvider from './index';

import type { UserIdentity, AuthProviderInterface } from './types';

interface UseIdentityResult {
  loading: boolean,
  loaded: boolean,
  user: UserIdentity | null | undefined,
  permissions: string[],
  role: 'owner' | 'member' | 'guest',
}

export const useIdentity = (): UseIdentityResult => {
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<UserIdentity>({
    id: '',
  });
  const [permissions, setPermissions] = useState<string[]>([]);
  const [role, setRole] = useState<'owner' | 'member' | 'guest'>('guest');

  const handleIdentityLoading = useCallback(() => {
    setLoading(true);
  }, []);

  const handleIdentityLoaded = useCallback(() => {
    setLoading(false);
  }, []);

  const handleIdentityUpdated = useCallback(() => {
    Promise.all([
      authProvider?.getIdentity?.(),
      authProvider?.getPermissions?.(),
      authProvider?.getRole?.(),
    ]).then(([identityResult, permissionsResult, roleResult]) => {
      setUser(identityResult);
      setPermissions(permissionsResult);
      setRole(roleResult);
    });
  }, []);

  useEffect(() => {
    handleIdentityUpdated();
    authProvider.addListener('IDENTITY_LOADING', handleIdentityLoading);
    authProvider.addListener('IDENTITY_LOADED', handleIdentityLoaded);
    authProvider.addListener('IDENTITY_UPDATED', handleIdentityUpdated);
    return () => {
      authProvider.removeListener('IDENTITY_LOADING', handleIdentityLoading);
      authProvider.removeListener('IDENTITY_LOADED', handleIdentityLoaded);
      authProvider.removeListener('IDENTITY_UPDATED', handleIdentityUpdated);
    };
  }, [handleIdentityUpdated, handleIdentityLoading, handleIdentityLoaded]);

  return {
    loading,
    loaded: !loading && Boolean(user?.id),
    user: !user?.id ? null : { ...user, company: { ...user?.company } },
    permissions,
    role,
  };
};

export const useAuthProvider = (): AuthProviderInterface => authProvider;
