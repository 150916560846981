import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import { useTranslate } from 'react-admin';
import { Typography } from '@mui/material';

type DownloadButtonProps = {
  url: string;
  filename: string;
  variant: 'text' | 'button';
  onClick?: () => void;
};

const DownloadButton = (props: DownloadButtonProps) => {
  const {
    url, filename, variant, onClick,
  } = props;
  const translate = useTranslate();


  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  if (variant === 'text') {
    return (
      <a
        href={url}
        download={filename}
        onClick={onClick}
        style={{
          textDecoration: 'none',
          margin: '7px 0 ',
        }}
      >
        <Typography color="#535353" variant="caption">
          {translate('resources.taskType.action.downloadExampleCsv')}
        </Typography>
      </a>
    );
  }

  if (variant === 'button') {
    return (
      <a
        href={url}
        download={filename}
        onClick={onClick}
        style={{
          textDecoration: 'none',
          margin: '7px 0 ',
        }}
      >
        <Button variant="outlined">
          {translate('resources.taskType.action.downloadExampleCsv')}
        </Button>
      </a>
    );
  }

  return null;
};
export default DownloadButton;
