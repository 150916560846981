import { memo, useCallback } from 'react';
import {
  ListActionsProps, useRedirect, useTranslate,
} from 'react-admin';

import ClientModal from '../../../elements/ClientModal';

const ListActions = (props: ListActionsProps) => {
  const translate = useTranslate();
  const { className } = props;
  const redirect = useRedirect();

  const handleComplete = useCallback((client: { id: any; }) => {
    redirect(`/Client/${client.id}/show`);
  }, [redirect]);

  return (
    <ClientModal
      id="create-client"
      ButtonProps={{
        variant: 'contained',
        color: 'primary',
        text: translate('resources.client.action.createClient'),
        className,
      }}
      FormProps={{
        title: translate('resources.client.action.createClient'),
        submitLabel: translate('resources.client.action.create'),
      }}
      method="create"
      onComplete={handleComplete}
    />
  );
};

export default memo(ListActions);
