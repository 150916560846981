import { FC } from 'react';
import {
  List, Datagrid, TextField, FunctionField, ListActionsProps, TopToolbar, useTranslate, CreateButton,
} from 'react-admin';

import SearchInput from 'components/SearchInput';
import RenderField from 'components/RenderField';

import { useIdentity } from 'services/AuthProvider/hooks';

import { Theme, useMediaQuery } from '@mui/material';
import BulkDeleteButton from './elements/BulkDeleteButton';
import User from './elements/User';
import MobileGrid from './elements/MobileGrid';
import ModalCompanyNewMember from './elements/ModalCompanyNewMember';


const ListActions: FC<ListActionsProps> = (props) => {
  const { className } = props;
  const identity = useIdentity();
  const translate = useTranslate();

  return (
    <TopToolbar className={className}>
      <ModalCompanyNewMember
        ButtonProps={{
          variant: 'contained',
          color: 'primary',
          text: translate('resources.companyAdmin.newMember'),
          disabled: identity.role !== 'owner',
        }}
      />
    </TopToolbar>
  );
};

const filters = [
  <SearchInput
    label="resources.taskType.action.search"
    source="q"
    alwaysOn
  />,
];

const CompanyAdminList: FC = (props) => {
  const translate = useTranslate();
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const identity = useIdentity();
  return (
    <>
      {isXSmall && (
      <List
        {...props}
        filters={filters}
        actions={isXSmall ? <CreateButton disabled={identity.role !== 'owner'} /> : <ListActions hidden={identity.role !== 'owner'} />}
        sort={{ field: 'id', order: 'desc' }}
        empty={false}
        title={translate('resources.companyAdmin.name')}
        sx={{
          '&': {
            marginRight: '-8px',
          },
          '& .MuiToolbar-root': {
            background: 'transparent',
          },
          '& > div > .MuiPaper-root': {
            boxShadow: 'none',
            background: 'transparent',
          },
        }}
      >
        <MobileGrid />
      </List>
      )}
      {!isXSmall && (
      <List
        {...props}
        filters={filters}
        actions={isXSmall ? <CreateButton disabled={identity.role !== 'owner'} /> : <ListActions hidden={identity.role !== 'owner'} />}
        sort={{ field: 'id', order: 'desc' }}
        empty={false}
        title={translate('resources.companyAdmin.name')}
      >
        <Datagrid
          bulkActionButtons={<BulkDeleteButton label="Delete members" />}
          sx={{
            '& .column-id': {
              width: '3rem',
              paddingRight: 0,
            },
            '& .column-role': {
              width: '10%',
            },
            '& .column-status': {
              width: '10%',
            },
          }}
        >
          <FunctionField source="first_name" label={translate('resources.companyAdmin.fields.name')} render={(record: any) => <User record={record} />} />
          <TextField source="phone" label={translate('resources.companyAdmin.fields.phone')} />
          <TextField source="email" label={translate('resources.companyAdmin.fields.email')} />
          <RenderField source={translate('resources.companyAdmin.fields.role')} render={(record) => record.role} />
          <TextField source="status" label={translate('resources.companyAdmin.fields.status')} />
        </Datagrid>
      </List>
      )}
    </>
  );
};

export default CompanyAdminList;
