import type { PropsWithChildren, ReactElement } from 'react';
import { Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';

import Avatar from '../../Avatar';

type AuthUserProps = {
  firstName: string,
  lastName: string,
  company: string,
  loading: boolean,
  profilePhotoUrl: string,
  className?: string,
};

const AuthUserSideBar = (props: PropsWithChildren<AuthUserProps>): ReactElement => {
  const {
    firstName,
    lastName,
    company,
    loading,
    profilePhotoUrl,
    className,
  } = props;

  const classes = useStyles();

  return (
    <div className={className}>
      <div className={classes.info}>
        <div className={classes.logo}>
          <Avatar name={`${firstName || ''} ${lastName || ''}`} image={profilePhotoUrl} />
        </div>
        <div className={classes.name}>
          {loading && (
          <Typography color="textPrimary" variant="caption" component="div">Pending data...</Typography>
          )}
          {!loading && (
          <>
            <Typography color="textPrimary" variant="caption" component="div">{`${firstName || ''} ${lastName || ''}`.trim()}</Typography>
            <Typography color="primary" variant="caption" component="div">{company}</Typography>
          </>
          )}
        </div>
      </div>
    </div>
  );
};

const useStyles = createUseStyles((theme) => ({
  info: {
    display: 'flex',
    minWidth: '10rem',
    alignItems: 'center',
  },
  logo: {
    display: 'flex',
    width: '2.25rem',
    height: '2.25rem',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    borderRadius: '50%',
    fontSize: '1rem',
  },
  name: {
    display: 'block',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
  company: {
    display: 'block',
  },
}));

export default AuthUserSideBar;
