import {
  memo,
  useCallback, useRef, useState,
} from 'react';
import type { FC } from 'react';
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  SingleFieldList,
  ArrayField,
  TopToolbar,
  ListActionsProps, SelectField, useTranslate, CreateButton, type Identifier,
} from 'react-admin';
import {
  Theme, useMediaQuery,
} from '@mui/material';

import EmptyList from 'components/EmptyList';
import SearchInput from 'components/SearchInput';

import TaskTypeModal from './elements/TaskTypeModal';
import TaskTypeListMobile from './elements/TaskTypeListMobile';
import UploadCSVButton from './elements/UploadCSVButton';
import DownloadButton from './elements/DownloadButton';
import BulkDeleteButton from './elements/BulkDeleteButton';


const ListActions: FC<ListActionsProps> = (props) => {
  const {
    className,
  } = props;
  const translate = useTranslate();

  return (
    <TopToolbar className={className}>
      <DownloadButton variant="text" url="/Example.csv" filename="Example.csv" />
      <UploadCSVButton />
      <TaskTypeModal
        ButtonProps={{
          variant: 'contained',
          color: 'primary',
          text: 'Create new task',
        }}
        method="create"
        FormProps={{
          title: translate('resources.taskType.action.newTask'),
          submitLabel: translate('resources.taskType.action.newTask'),
        }}
      />
    </TopToolbar>
  );
};

const filters = [
  <SearchInput
    label="resources.taskType.action.search"
    source="q"
    alwaysOn
  />,
];

const TaskTypeList: FC = (props) => {
  const [editingRecord, setEditingRecord] = useState();
  const translate = useTranslate();

  const editRef = useRef<any>(null);

  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const handleRowClick = useCallback((id: Identifier, resource: string, record: any) => {
    setEditingRecord(record);
    editRef.current.open();
    return '';
  }, []);

  return (
    <>
      <>
        {isXSmall && (
        <List
          {...props}
          filters={filters}
          actions={isXSmall ? <CreateButton /> : <ListActions />}
          sort={{ field: 'id', order: 'desc' }}
          empty={<EmptyList body="Create new common tasks to be able to assign them to your future clients" toolbar={isXSmall ? <CreateButton /> : <ListActions />} />}
          title={translate('resources.taskType.name')}
          sx={{
            '&': {
              marginRight: '-10px',
              marginLeft: '-3px',
            },
            '& .MuiToolbar-root': {
              background: 'transparent',
            },
            '& > div > .MuiPaper-root': {
              boxShadow: 'none',
              background: 'transparent',
            },
          }}
        >
          <TaskTypeListMobile />
        </List>
        )}
        {!isXSmall && (
        <List
          {...props}
          filters={filters}
          actions={isXSmall ? <CreateButton /> : <ListActions />}
          sort={{ field: 'id', order: 'desc' }}
          empty={<EmptyList body="Create new common tasks to be able to assign them to your future clients" toolbar={isXSmall ? <CreateButton /> : <ListActions />} />}
          title={translate('resources.taskType.name')}
        >
          <Datagrid
            sx={{
              '& .column-document': {
                '&': {
                  width: '7rem',
                  paddingTop: '1px',
                  paddingRight: 0,
                  paddingBottom: '1px',
                },
                '& div': {
                  '&': {
                    height: '4rem',
                    aspectRatio: '16 / 9',
                  },
                  '& img': {
                    display: 'block',
                    height: '100%',
                    margin: '0 auto 0 0',
                  },
                },
              },
              '& .column-name': {
                paddingTop: '0.5em',
                paddingBottom: '0.5em',
              },
              '& .column-tags': {
                paddingTop: '0em',
                paddingBottom: '0em',
              },
              '& .column-actions': {
                width: '3rem',
              },
            }}
            rowClick={handleRowClick}
            bulkActionButtons={<BulkDeleteButton label="Delete task" />}
          >
            <TextField label={translate('resources.taskType.fields.name')} source="name" />
            <TextField label={translate('resources.taskType.fields.description')} source="description" />
            <SelectField
              source="priorityLevel"
              label={translate('resources.taskType.fields.priorityLevel')}
              choices={[
                { id: '1', name: translate('resources.taskType.fields.low') },
                { id: '2', name: translate('resources.taskType.fields.medium') },
                { id: '3', name: translate('resources.taskType.fields.high') },
              ]}
            />
            <ArrayField source="tags" label="resources.taskType.fields.tags" sortable={false}>
              <SingleFieldList sx={{ pointerEvents: 'none' }}>
                <ChipField source="name" clickable={false} size="small" />
              </SingleFieldList>
            </ArrayField>
          </Datagrid>
        </List>
        )}
      </>
      <TaskTypeModal
        ref={editRef}
        ButtonProps={{
          show: false,
        }}
        method="update"
        record={editingRecord}
        id="task-type-edit-modal"
        FormProps={{
          title: translate('resources.task.editTask'),
          submitLabel: translate('resources.taskType.action.edit'),
        }}
      />
    </>
  );
};


export default memo(TaskTypeList);
