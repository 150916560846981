import { memo, type ReactElement } from 'react';
import { MenuItemLink as RAMenuItemLink } from 'react-admin';

interface MenuItemLinkProps {
  className: string,
  activeClassName: string,
  to: string,
  primaryText: string,
  leftIcon: ReactElement,
  onClick?: () => void,
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const MenuItemLink = (props: MenuItemLinkProps) => <RAMenuItemLink {...props} />;

export default memo(MenuItemLink);
