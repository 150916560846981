import { memo, useCallback, useMemo } from 'react';
import type { FC } from 'react';
import { Typography } from '@mui/material';
import { SelectField, useTranslate } from 'react-admin';

interface ClientTaskStatusProps {
  label?: string,
  source: string,
}

const ClientTaskStatus: FC<ClientTaskStatusProps> = (props) => {
  const { label, source } = props;
  const translate = useTranslate();

  const choices = useMemo(() => (
    [
      { id: 'to_do', name: translate('resources.task.values.status.todo') },
      { id: 'approved', name: translate('resources.task.values.status.approved') },
      { id: 'waiting_for_review', name: translate('resources.task.values.status.inReview') },
    ]
  ), [translate]);

  const optionRenderer = useCallback((choice: any) => {
    const isApproved = choice.id === 'approved';
    const isReview = choice.id === 'waiting_for_review';
    const isTodo = choice.id === 'to_do';
    return (
      <>
        {isApproved && (
          <Typography style={{ color: 'green', fontSize: '0.875rem' }}>
            {choice.name}
          </Typography>
        )}
        {isReview && (
          <Typography style={{ color: 'blue', fontSize: '0.875rem' }}>
            {choice.name}
          </Typography>
        )}
        {isTodo && (
          <Typography style={{ color: 'gray', fontSize: '0.875rem' }}>
            {choice.name}
          </Typography>
        )}
      </>
    );
  }, []);

  return (
    <SelectField
      source={source}
      label={label}
      choices={choices}
      optionText={optionRenderer}
    />
  );
};

export default memo(ClientTaskStatus);
