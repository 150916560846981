import { gql } from 'services/dataProvider';

export const fields = gql`
  fragment ClientFields on Client {
    id
    firstName
    lastName
    email
    phone
    dateOfBirth
    addressHouseNumber
    addressCity
    addressState
    addressZipCode
    notes
    taskCount
    numberOfTasksTotal
    numberOfTasksOutstanding
    numberOfTasksWaitingForReview
  }
`;
