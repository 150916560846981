import { useCallback, useEffect } from 'react';
import type { FC } from 'react';
import {
  Edit, SimpleForm, TextInput, useNotify, useLoading, useTranslate,
} from 'react-admin';
import { createUseStyles } from 'react-jss';
import {
  Box, Theme, Typography, useMediaQuery,
} from '@mui/material';

import { useAuthProvider, useIdentity } from 'services/AuthProvider/hooks';
import FileInput from 'components/FileInput';

import Toolbar from './elements/Toolbar';
import AvatarInput from './elements/AvatarInput';
import ImageInput from './elements/ImageInput';

const SettingEdit: FC = (props) => {
  const classes = useStyles();
  const authProvider = useAuthProvider();
  const identity = useIdentity();
  const notify = useNotify();
  const translate = useTranslate();
  const isLoading = useLoading();
  const isLargeEnough = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const handleSuccess = useCallback(() => {
    authProvider.checkAuth({ include: ['identity'] }).catch((error) => {
      notify(error.message, { type: 'error' });
    });
  }, [authProvider, notify]);

  // @todo удалить костыль когда заработает onSuccess для Edit
  useEffect(() => {
    if (!isLoading) {
      handleSuccess();
    }
  }, [handleSuccess, isLoading]);

  // @todo починить onSuccess для Edit (разобраться почему не работает)
  return (
    <Edit
      {...props}
      title={translate('resources.setting.fields.setting')}
      className={classes.container}
      redirect="/Setting/1"
      queryOptions={{
        onSuccess: handleSuccess,
      }}
    >
      <SimpleForm toolbar={<Toolbar />}>
        <Typography variant="h6">{translate('resources.setting.fields.accountSetting')}</Typography>
        <Box
          sx={{
            gridTemplateColumns: isLargeEnough ? '1fr 1fr' : '1fr',
            display: 'grid',
            width: '100%',
            columnGap: '2rem',
            marginTop: '1rem',
          }}
        >
          <TextInput source="firstName" label={translate('resources.setting.fields.firstName')} variant="standard" />
          <TextInput source="email" label={translate('resources.setting.fields.email')} variant="standard" />
          <TextInput source="lastName" label={translate('resources.setting.fields.lastName')} variant="standard" />
          <TextInput source="phone" label={translate('resources.setting.fields.phone')} disabled variant="standard" />
          <AvatarInput
            source="profilePhoto"
            label={translate('resources.setting.fields.userPic')}
            textField="UserPic"
            firstName={authProvider.identity?.firstName}
            lastName={authProvider.identity?.lastName}
          />
        </Box>
        {identity.role === 'owner' && (
          <>
            <Typography variant="h6">{translate('resources.setting.fields.companySetting')}</Typography>
            <Box
              sx={{
                gridTemplateColumns: isLargeEnough ? '1fr 1fr' : '1fr',
                display: 'grid',
                width: '100%',
                columnGap: '2rem',
                marginTop: '1rem',
              }}
            >
              <TextInput source="companyName" label={translate('resources.setting.fields.companyName')} variant="standard" />
              <TextInput source="companyWebsite" label={translate('resources.setting.fields.companyWebsite')} variant="standard" />
              <FileInput
                source="companyLogo"
                label={translate('resources.setting.fields.companyLogo')}
                itemComponent={<ImageInput textField={translate('resources.setting.fields.companyLogo')} />}
                emptyComponent={<ImageInput textField={translate('resources.setting.fields.companyLogo')} />}
              />
            </Box>
          </>
        )}
      </SimpleForm>
    </Edit>
  );
};

const useStyles = createUseStyles({
  container: {
    '&': {
      width: '55%',
      flex: 0,
    },
    '@media (max-width: 1024px)': {
      width: 'auto',
      flex: 1,
    },
  },
});

export default SettingEdit;
