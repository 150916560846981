import {
  FC, useCallback, useRef, ReactElement, memo,
} from 'react';
import { DateTime } from 'luxon';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { TextField } from '@mui/material';
import { useLocaleState } from 'react-admin';


interface FlatDateInputProps {
  className?: string,
  button?: ReactElement,
  onChange?: (value: string) => void,
  value?: string,
}

const FlatDatePicker: FC<FlatDateInputProps> = (props) => {
  const {
    button,
    onChange,
    value = '',
  } = props;

  const pickerRef = useRef(null);

  const [locale] = useLocaleState();


  const handleDateChange = useCallback((date: unknown) => {
    const format = {
      en: 'MM/dd/yyyy',
      he: 'yyyy.MM.dd',
    };
    const newValue = DateTime.fromJSDate(date as Date).toFormat(format[locale as 'en' | 'he']);
    onChange?.(newValue);
  }, [locale, onChange]);

  const currValue = value ? DateTime.fromFormat(value, 'yyyy-MM-dd') : DateTime.now();

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          disablePast
          closeOnSelect
          inputRef={pickerRef}
          openTo="day"
          value={currValue}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      {button}
    </div>
  );
};

export default memo(FlatDatePicker);
