import { FC, memo } from 'react';
import {
  Edit, required, SimpleForm, TextInput,
} from 'react-admin';

import ToolbarMobileGrid from '../elements/ToolbarMobileGrid';

const ClientEdit: FC = (props) => {
  return (
    <Edit
      actions={false}
      title="About Client"
      {...props}
    >
      <SimpleForm toolbar={<ToolbarMobileGrid />}>
        <TextInput size="small" variant="standard" disabled name="userId" source="id" />
        <TextInput name="firstName" source="firstName" variant="standard" validate={required()} fullWidth />
        <TextInput name="lastName" source="lastName" variant="standard" validate={required()} fullWidth />
        <TextInput source="email" variant="standard" fullWidth />
        <TextInput source="phone" variant="standard" disabled validate={required()} />
        <TextInput source="addressHouseNumber" label="address" variant="standard" fullWidth />
        <TextInput source="addressCity" label="city" variant="standard" fullWidth />
        <TextInput source="dateOfBirth" label="D.O.B." variant="standard" />
        <TextInput source="addressState" label="state" variant="standard" fullWidth />
        <TextInput source="addressZipCode" label="zipcode" variant="standard" />
        <TextInput source="notes" fullWidth variant="standard" multiline />
      </SimpleForm>
    </Edit>
  );
};
export default memo(ClientEdit);
