import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { UpdateManyResult, UpdateManyParams, UpdateManyProvider } from './types';

export type { UpdateManyResult, UpdateManyParams, UpdateManyProvider };

export default async (client: ApolloClient<NormalizedCacheObject>, provider: UpdateManyProvider, resource: string, params: UpdateManyParams): Promise<UpdateManyResult> => {
  const { parser, ...clientArgs } = provider(params);
  const { data, errors } = await client.mutate(clientArgs);

  if (errors) {
    return Promise.reject(errors[0].message);
  }

  if (!data) {
    return Promise.reject(new Error('Not data found'));
  }

  if (parser) {
    return parser(data);
  }

  const firstNode = data[Object.keys(data)[0]];

  return {
    data: firstNode.updateMany,
  };
};
