import {
  memo, useCallback, useRef, useState,
} from 'react';
import { Button } from '@mui/material';
import {
  email,
  required, TextInput, useCreate, useNotify, useUpdate,
  useRefresh, useTranslate, DateInput,
} from 'react-admin';
import type { Identifier } from 'react-admin';
import { createUseStyles } from 'react-jss';

import { Client } from 'interfaces/entities';
import * as ModalwithForm from 'components/ModalwithForm';
import { FormButton } from 'components/FormButton';

type DataType = {
  id: Identifier
} & Omit<Client, 'id'>;

type ClientModalProps = {
  id?: string,
  record?: DataType,
  method: 'create' | 'update',
  onComplete?: (data: DataType) => void,
  FormProps: {
    title: string,
    submitLabel: string,
  },
  ButtonProps?: {
    className?: string,
    text: string,
    variant: 'contained' | 'outlined' | 'text',
    color: 'primary' | 'secondary' | 'inherit' | 'error' | 'success' | 'info' | 'warning',
    disabled?: boolean,
  },
};

const ClientModal = (props: ClientModalProps) => {
  const {
    id,
    method,
    ButtonProps,
    FormProps,
    record,
    onComplete,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();
  const dialogRef = useRef(null);
  const [create, { isLoading: creating }] = useCreate();
  const [update, { isLoading: updating }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const loading = creating || updating;

  const emailValidators = [email('Must be an email')];
  const phoneValidators = [required()];

  const handleClickOpen = useCallback((event: { stopPropagation: () => void; }) => {
    event.stopPropagation();
    ModalwithForm.open(dialogRef);
  }, []);

  const handleSubmit = useCallback(async (data: DataType) => {
    const options = {
      returnPromise: true,
    };
    let client: DataType;
    try {
      let response;
      if (method === 'create') {
        response = await create('Client', { data }, options);
      } else {
        response = await update('Client', { id: record?.id, data, previousData: record }, options);
      }
      client = response?.data;
    } catch (error) {
      notify((error as Error).message, { type: 'warning' });
      return;
    }
    refresh();
    ModalwithForm.close(dialogRef);
    setTimeout(() => {
      onComplete?.(client);
    }, 300);
  }, [create, method, notify, onComplete, record, update, refresh]);

  const [selectedDate, handleDateChange] = useState(new Date());

  return (
    <>
      <Button
        variant={ButtonProps?.variant || 'contained'}
        color={ButtonProps?.color || 'primary'}
        disabled={ButtonProps?.disabled || false}
        onClick={handleClickOpen}
        className={ButtonProps?.className}
      >
        {ButtonProps?.text}
      </Button>
      <ModalwithForm.Dialog
        id={id}
        ref={dialogRef}
        title={FormProps?.title}
        onSubmit={handleSubmit}
        initialValues={record}
        bodyContent={(
          <div>
            <div className={classes.gridTwoColumns}>
              <TextInput label={translate('resources.client.fields.firstName')} source="firstName" variant="standard" validate={required()} />
              <TextInput label={translate('resources.client.fields.lastName')} source="lastName" variant="standard" validate={required()} />
              <TextInput label={translate('resources.client.fields.email')} source="email" variant="standard" validate={emailValidators} />
              <TextInput
                label="resources.client.fields.mobilePhone"
                source="phone"
                variant="standard"
                validate={phoneValidators}
              />
              <DateInput margin="dense" variant="standard" label={translate('resources.client.fields.dateOfBirth')} source="dateOfBirth" />
            </div>
            <TextInput source="addressHouseNumber" label={translate('resources.client.fields.address')} variant="standard" fullWidth />
            <div className={classes.gridThreeColumns}>
              <TextInput source="addressCity" label={translate('resources.client.fields.city')} variant="standard" />
              <TextInput source="addressZipCode" label={translate('resources.client.fields.zipcode')} variant="standard" />
              <TextInput source="addressState" label={translate('resources.client.fields.state')} variant="standard" />
            </div>
            <TextInput source="notes" label={translate('resources.client.fields.notes')} variant="standard" multiline fullWidth />
          </div>
                )}
        actionsRightSide={(
          <FormButton variant="contained" color="primary" label={FormProps.submitLabel} />
                )}
        layout="slim"
      />

    </>
  );
};

const useStyles = createUseStyles({
  gridTwoColumns: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
    columnGap: '2rem',
    marginTop: '1rem',
  },
  gridThreeColumns: {
    display: 'grid',
    gridTemplateColumns: '1fr 0.5fr 1fr',
    width: '100%',
    columnGap: '2rem',
  },
});

export default memo(ClientModal);
