import { memo } from 'react';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  useTranslate,
} from 'react-admin';
import { createUseStyles } from 'react-jss';
import {
  Typography,
} from '@mui/material';

import { Client } from 'interfaces/entities';

import EmptyList from 'components/EmptyList';
import SearchInput from 'components/SearchInput';

import BulkDeleteButton from '../../../elements/BulkDeleteButton';

import ListActions from '../elements/ListActions';
import TaskInfoChip from '../elements/TaskInfoChip';

const filters = [
  <SearchInput
    label="resources.taskType.action.search"
    source="q"
    alwaysOn
  />,
];

const ClientList = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <List
      {...props}
      filters={filters}
      actions={<ListActions className={classes.actions} />}
      sort={{ field: 'id', order: 'desc' }}
      empty={<EmptyList body="Create a new client and append tasks to him" toolbar={<ListActions />} />}
      title={translate('resources.client.name')}
    >
      <Datagrid bulkActionButtons={<BulkDeleteButton label="Delete clients" />} className={classes.grid} rowClick="show">
        <FunctionField
          source="firstName"
          label="resources.client.fields.clientName"
          render={(record: Client) => (
            <div className={classes.columnTask}>
              <Typography>{`${record.firstName || ''} ${record.lastName || ''}`}</Typography>
              <TaskInfoChip record={record} size="small" mh={4} />
            </div>
          )}
        />
        <TextField emptyText="No task" textAlign="center" source="numberOfTasksTotal" label="resources.client.fields.totalTasks" />
        <TextField emptyText="No task" textAlign="center" source="numberOfTasksOutstanding" label="resources.client.fields.tasksStillOutstanding" />
        <TextField source="notes" label="resources.client.fields.notes" sortable={false} />
      </Datagrid>
    </List>
  );
};

const useStyles = createUseStyles({
  actions: {
    // height: 'auto',
    // minHeight: 'auto',
    width: '11rem',
    height: '2.5rem',
    marginBottom: '0.5rem',
  },
  grid: {
    '& .column-numberOfTasksTotal': {
      width: '10rem',
    },
    '& .column-numberOfTasksOutstanding': {
      width: '10rem',
    },
  },
  columnTask: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default memo(ClientList);
