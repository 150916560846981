import {
  useState, ReactElement, useCallback, useEffect, MutableRefObject, forwardRef, useRef, ForwardedRef,
} from 'react';
import { useTranslate } from 'react-admin';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import {
  Typography,
  Button,
  Dialog as UIDialog,
  DialogActions as UIDialogActions,
  DialogContent as UIDialogContent,
  DialogTitle as UIDialogTitle,
  IconButton,
  Portal, useMediaQuery, Theme,
} from '@mui/material';
import IconClose from '@mui/icons-material/Close';

interface DialogStore {
  open: CallableFunction,
  close: CallableFunction,
  setFirst: (value: boolean) => void,
  setCovered: (value: boolean) => void,
}

class DialogController {
  public store: Record<string, DialogStore> = {};

  public showStack: string[] = [];

  private reposition = () => {
    this.showStack.forEach((item, key, object) => {
      const { setFirst, setCovered } = this.store[String(item)];
      setFirst(key === 0);
      setCovered(key < object.length - 1);
    });
  };

  private stackAdd = (id: string | number) => {
    this.showStack.push(String(id));
  };

  private stackRemove = (id: string | number) => {
    const stackIndex = this.showStack.findIndex((itemId) => String(itemId) === String(id));
    if (stackIndex > -1) {
      this.showStack.splice(stackIndex, 1);
    }
  };

  public add = (id: string | number, object: DialogStore) => {
    this.store[String(id)] = object;
  };

  public remove = (id: string | number) => {
    this.stackRemove(id);
    delete this.store[String(id)];
  };

  public open = (id: string | number) => {
    this.store?.[String(id)]?.open();
  };

  public close = (id: string | number) => {
    this.store?.[String(id)]?.close();
  };

  public opened = (id: string | number) => {
    this.stackAdd(id);
    this.reposition();
  };

  public closed = (id: string | number) => {
    this.stackRemove(id);
    this.reposition();
  };
}

const dialogController = new DialogController();

interface IForwardedRef {
  id: string | number,
}

export const open = (id: string | number | MutableRefObject<IForwardedRef | null>): void => {
  let targetId: string | number = '';
  if (typeof id === 'object' && id?.current?.id) {
    targetId = id?.current?.id;
  }
  if (typeof id === 'string' || typeof id === 'number') {
    targetId = id;
  }
  dialogController.open(targetId);
};

export const close = (id: string | number | MutableRefObject<IForwardedRef | null>): void => {
  let targetId: string | number = '';
  if (typeof id === 'object' && id?.current?.id) {
    targetId = id?.current?.id;
  }
  if (typeof id === 'string' || typeof id === 'number') {
    targetId = id;
  }
  dialogController.close(targetId);
};

const defaultProps = {
  actionsStretchButtons: false as boolean | undefined,
  layout: 'default' as 'default' | 'slim' | 'content' | undefined,
};

type TEvent = {
  name: string,
  type: string,
};

type DialogProps = {
  id?: string | number,
  title?: string,
  classes?: {
    paper?: string,
    header?: string,
    body?: string,
    actions?: string,
  },
  fullScreen?: boolean,
  headerContent?: ReactElement,
  bodyContent?: ReactElement,
  actionsLeftSide?: ReactElement,
  actionsRightSide?: ReactElement,
  actionsStretchButtons?: boolean,
  hasGlobalCloser?: boolean,
  layout?: 'default' | 'slim' | 'content',
  onOpen?: () => void,
  onOpened?: () => void,
  onClose?: (event?: TEvent, reason?: string) => void,
  onClosed?: (event?: TEvent, reason?: string) => void,
} & typeof defaultProps;

const Modal = (props: DialogProps, ref: ForwardedRef<IForwardedRef>) => {
  const {
    title,
    classes: classNames,
    fullScreen,
    headerContent,
    bodyContent,
    actionsLeftSide,
    actionsRightSide,
    actionsStretchButtons,
    hasGlobalCloser,
    layout,
  } = props;

  const translate = useTranslate();

  const id = (props.id || title);

  if (!id) {
    throw new Error('Modal: You shou set one of property (id, title)');
  }

  const events = useRef({
    onOpen: props.onOpen,
    onOpened: props.onOpened,
    onClose: props.onClose,
    onClosed: props.onClosed,
  });

  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const reference = ref as MutableRefObject<IForwardedRef> | null;
  if (reference && !reference?.current?.id) {
    reference.current = { id };
  }

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isFirst, setFirst] = useState(false);
  const [isCovered, setCovered] = useState(false);

  const handleOpen = useCallback(() => {
    const { onOpen, onOpened } = events.current;
    dialogController.opened(id);
    setIsOpen(true);
    onOpen?.();
    if (onOpened) {
      setTimeout(() => {
        onOpened();
      }, 200);
    }
  }, [id]);

  const handleClose = useCallback((event = {}, reason = '') => {
    const { onClose, onClosed } = events.current;
    dialogController.closed(id);
    setIsOpen(false);
    onClose?.(event as TEvent || {}, reason || 'cancel');
    if (onClosed) {
      setTimeout(() => {
        onClosed(event as TEvent || {}, reason || 'cancel');
      }, 200);
    }
  }, [id]);

  const handleStopPropagation = useCallback((event: { stopPropagation: () => void; }) => {
    event.stopPropagation();
  }, []);

  useEffect(() => {
    dialogController.add(id, {
      open: handleOpen,
      close: handleClose,
      setFirst,
      setCovered,
    });
    return () => {
      dialogController.remove(id);
      setIsOpen(false);
    };
  }, [id, handleClose, handleOpen]);

  return (
    <UIDialog
      onClick={handleStopPropagation}
      open={isOpen}
      onClose={handleClose}
      className={cn(classes.modal, { [classes.isCovered]: isCovered })}
      fullScreen={fullScreen}
      slotProps={{
        backdrop: {
          style: {
            background: isFirst ? 'rgba(96, 107, 124, 0.61)' : 'transparent',
            backdropFilter: isFirst ? 'blur(4px)' : 'none',
          },
        },
      }}
      classes={{
        paper: cn(classes.paper, classNames?.paper, { layoutContent: layout === 'content', fullScreen }),
      }}
    >
      {(Boolean(title) || Boolean(headerContent)) && (
        <UIDialogTitle className={cn(classes.header, classNames?.header, { layoutSlim: layout === 'slim', layoutContent: layout === 'content' })}>
          {Boolean(title) && <Typography variant="h2">{title}</Typography>}
          {headerContent}
        </UIDialogTitle>
      )}
      <UIDialogContent className={cn(classes.body, classNames?.body, { layoutSlim: layout === 'slim', layoutContent: layout === 'content' })}>
        {bodyContent}
      </UIDialogContent>
      <UIDialogActions
        className={cn(classes.actions, classNames?.actions, {
          stretch: actionsStretchButtons,
          layoutSlim: layout === 'slim',
          layoutContent: layout === 'content',
        })}
      >
        <Button variant="outlined" onClick={handleClose} color="primary">
          {translate('resources.taskType.action.cancel')}
        </Button>
        {actionsLeftSide}
        <span />
        {actionsRightSide}
      </UIDialogActions>
      {hasGlobalCloser && (
        <Portal container={() => document.querySelector('body > [role="presentation"]')}>
          <IconButton
            sx={isXSmall ? {
              position: 'fixed', left: 16, top: 8, backgroundColor: 'rgba(0,0,0,0.3)',
            } : { position: 'fixed', right: 16, top: 16 }}
            onClick={handleClose}
          >
            <IconClose fontSize="large" htmlColor="#ffffff" />
          </IconButton>
        </Portal>
      )}
    </UIDialog>
  );
};

Modal.defaultProps = defaultProps;

const useStyles = createUseStyles({
  modal: {
    '&': {
    },
    '& h2': {
      fontSize: '1.125rem',
      fontWeight: 700,
    },
    '& [role="dialog"]': {
      position: 'relative',
      filter: 'blur(0)',
      transition: 'filter 200ms ease',
    },
    '& [role="dialog"]:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'rgba(0, 0, 0, 0)',
      pointerEvents: 'none',
      zIndex: 10,
      transition: 'background 200ms ease',
    },
  },
  isCovered: {
    '& [role="dialog"]': {
      filter: 'blur(4px)',
    },
    '& [role="dialog"]:after': {
      background: 'rgba(96, 107, 124, 0.61)',
    },
  },
  paper: {
    '&': {
      borderRadius: '1rem',
    },
    '&.layoutContent': {
      maxWidth: 'none',
      maxHeight: 'none',
    },
    '&.fullScreen': {
      borderRadius: 0,
    },
  },
  header: {
    '&': {
      padding: '2.6rem 2.5rem 1.5rem',
    },
    '&.layoutSlim': {
      padding: '1.5rem 2.1875rem 0.5rem',
    },
    '&.layoutContent': {
      display: 'none',
    },
  },
  body: {
    '&': {
      padding: '0 2.5rem 1.5rem',
    },
    '&.layoutSlim': {
      padding: '0 2.1875rem 0',
    },
    '&.layoutContent': {
      padding: 0,
    },
  },
  actions: {
    '&': {
      padding: '2rem 2.5rem 2.2rem',
      borderTop: '1px solid #b0b6be',
    },
    '&.layoutSlim': {
      padding: '1rem 2.1875rem 1.5rem',
      borderTop: 0,
    },
    '&.layoutContent': {
      display: 'none',
    },
    '& > span': {
      display: 'block',
      flex: 1,
    },
    '&.stretch > span': {
      display: 'none',
    },
    '&.stretch .MuiButton-root': {
      width: 'auto',
      flex: 1,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const Dialog = forwardRef(Modal);

export default Dialog;
