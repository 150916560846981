import { gql, UpdateProvider } from 'services/dataProvider';

import { fields as ClientFields } from '../fragments';

const provider: UpdateProvider = (params) => {
  const mutation = gql`
    ${ClientFields}
    mutation ($input: ClientUpdateInput!) {
      client {
        update (input: $input) {
          ...ClientFields
        }
      }
    }
  `;
  return {
    mutation,
    variables: {
      input: {
        id: params.data.id,
        firstName: params.data.firstName,
        lastName: params.data.lastName,
        phone: params.data.phone,
        email: params.data.email,
        dateOfBirth: params.data.dateOfBirth,
        addressHouseNumber: params.data.addressHouseNumber,
        addressCity: params.data.addressCity,
        addressState: params.data.addressState,
        addressZipCode: params.data.addressZipCode,
        notes: params.data.notes,
      },
    },
  };
};

export default provider;
