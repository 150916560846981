import { FC } from 'react';
import {
  Create, SimpleForm, TextInput, required,
} from 'react-admin';

const CompanyAdminCreate: FC = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="id" disabled variant="standard" InputProps={{ value: 'will generated' }} />
        <TextInput source="firstName" variant="standard" validate={required()} />
        <TextInput source="lastName" variant="standard" validate={required()} />
        <TextInput source="email" variant="standard" />
        <TextInput source="phone" variant="standard" validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export default CompanyAdminCreate;
