import { useCallback, useEffect, useRef } from 'react';
import * as uuid from 'uuid';

import type {
  UploaderProps,
  FileData,
} from '../types';

type UseFileUploadCallbackResult = (file: File | File[]) => void;

const useFileUploadCallback = (uploadUrl: string, props: UploaderProps): UseFileUploadCallbackResult => {
  const {
    onUploadStart,
    onUploadEnd,
    onUploadProgress,
    onUploadError,
  } = props;

  const callbacks = useRef<UploaderProps>();

  useEffect(() => {
    callbacks.current = {
      onUploadStart,
      onUploadEnd,
      onUploadProgress,
      onUploadError,
    };
  }, [onUploadEnd, onUploadError, onUploadProgress, onUploadStart]);

  return useCallback((file) => {
    if (!uploadUrl) {
      throw new Error('Wrong upload address');
    }

    if (!file) {
      return;
    }

    const files: File[] = (!Array.isArray(file) ? [file] : file).filter((item) => item instanceof File && item instanceof Blob);
    if (!files.length) {
      return;
    }

    files.forEach((item) => {
      const sessionUuid = uuid.v4();
      const uploadingFiles: FileData[] = [];

      // @todo rewrite FormData abd xhr using singleton
      const formData = new FormData();
      formData.append(item.name, item);
      uploadingFiles.push({
        name: item.name,
        url: '',
        hash: '',
      });

      const request = new XMLHttpRequest();
      request.upload.onprogress = (event) => {
        callbacks.current?.onUploadProgress?.({
          type: 'UploadProgress',
          payload: {
            uuid: sessionUuid,
            files: uploadingFiles,
            abort: request.abort,
            loaded: event.loaded,
            total: event.total,
          },
        });
      };
      request.onload = () => {
        if (request.status !== 200) {
          callbacks.current?.onUploadError?.({
            type: 'UploadError',
            payload: {
              uuid: sessionUuid,
              reason: 'error-server',
            },
          });
          return;
        }
        try {
          const { files: fileList } = JSON.parse(request.response);
          callbacks.current?.onUploadEnd?.({
            type: 'UploadEnd',
            payload: {
              uuid: sessionUuid,
              files: fileList,
            },
          });
        } catch (e) {
          callbacks.current?.onUploadError?.({
            type: 'UploadError',
            payload: {
              uuid: sessionUuid,
              reason: 'error-response',
            },
          });
        }
      };
      request.onerror = () => {
        callbacks.current?.onUploadError?.({
          type: 'UploadError',
          payload: {
            uuid: sessionUuid,
            reason: 'error-server',
          },
        });
      };
      request.onabort = () => {
        callbacks.current?.onUploadError?.({
          type: 'UploadError',
          payload: {
            uuid: sessionUuid,
            reason: 'abort',
          },
        });
      };
      request.open('POST', uploadUrl);
      request.send(formData);

      callbacks.current?.onUploadStart?.({
        type: 'UploadStart',
        payload: {
          uuid: sessionUuid,
          files: uploadingFiles,
          abort: () => { request.abort(); },
        },
      });
    });
  }, [uploadUrl]);
};

export default useFileUploadCallback;
