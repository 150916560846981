import { FC, memo } from 'react';
import { createUseStyles } from 'react-jss';
import {
  Typography, CircularProgress, Badge, IconButton,
} from '@mui/material';
import IconRemoveCircle from '@mui/icons-material/RemoveCircle';

import AvatarCustom from 'components/Avatar';
import { EmptyComponentProps, ItemComponentProps } from 'components/FileInput';

export interface ImageInputProps extends EmptyComponentProps, ItemComponentProps {
  textField?: string,
  name?: string,
}

const ImageInput: FC<ImageInputProps> = (props) => {
  const {
    isLoading, percent, textField, data, onRemove, name, files,
  } = props;
  const image = data?.url;
  const classes = useStyles();
  const isImage = /\.(png|jpg|jpeg|gif|tif|webp)$/.test(data?.url || '');
  const progressValue = (percent || 0) * 100;
  return (
    <div className={classes.empty}>
      <Badge
        badgeContent={isImage && (
          <IconButton onClick={onRemove} aria-label="delete" color="secondary">
            <IconRemoveCircle fontSize="inherit" />
          </IconButton>
        )}
      >
        <div className={classes.wrapper}>
          {!isLoading && <AvatarCustom className={classes.avatarSize} style={{ position: 'absolute' }} name={name} />}
          <AvatarCustom style={{ opacity: isLoading ? 0.1 : 2 }} className={classes.avatarSize} name={name} image={image} />
          {isLoading && (
            <div className={classes.icon}>
              <CircularProgress variant="determinate" value={progressValue} />
              <Typography className={classes.loading} variant="caption" component="div" color="textSecondary">
                {`${progressValue.toFixed(0)}%`}
              </Typography>
            </div>
          )}
        </div>
      </Badge>
      {isLoading && (
        <Typography className={classes.text} variant="caption" component="div">
          {`uploading ${files?.length} ${files?.length === 1 ? 'file' : 'files'}`}
          <br />
          please wait
        </Typography>
      )}
      {!isLoading && (
        <Typography className={classes.text} variant="caption" component="div">
          {textField}
          <br />
          drop image file here
          <br />
          or
          {' '}
          <u>select file</u>
        </Typography>
      )}
    </div>
  );
};

const useStyles = createUseStyles({
  empty: {
    '&': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      boxSizing: 'border-box',
      border: '1px solid #606B7C',
      borderRadius: '0.5rem',
      padding: '1.4rem',
    },
  },
  icon: {
    '&': {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '4.1rem',
    },
    '& svg': {
      height: '100%',
    },
  },
  loading: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.625rem',
  },
  text: {
    flex: 1,
    margin: '0 0 0 2rem',
    textAlign: 'left',
    lineHeight: '1rem',
    color: '#606B7C',
  },
  avatarSize: {
    width: '4rem',
    height: '4rem',
    transition: 'opacity 2s',
  },
  wrapper: {
    position: 'relative',
  },
});

export default memo(ImageInput);
