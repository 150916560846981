import { gql, UpdateManyProvider } from 'services/dataProvider';
import { ClientTask } from 'interfaces/entities';

import { fields as ClientTaskFields } from '../fragments';

const updateMany: UpdateManyProvider = (params) => {
  const mutation = gql`
    ${ClientTaskFields}
    mutation ($input: ClientTaskBulkUpdateInput!) {
      clientTask {
        updateMany (input: $input){
          ...ClientTaskFields
        }
      }
    }
  `;
  return {
    mutation,
    variables: {
      input: {
        id: params.ids,
        setArchived: params.data.setArchived,
      },
    },
  };
};

const approveMany: UpdateManyProvider<any, any> = (params) => {
  const mutation = gql`
        ${ClientTaskFields}
        mutation ($input: ClientTaskApproveManyInput!) {
            clientTask {
                approveMany (input: $input) {
                    ...ClientTaskFields
                }
            }
        }
    `;
  return {
    mutation,
    variables: {
      input: {
        id: params.ids,
        value: params.data.state,
      },
    },
    parser: (data) => {
      return {
        data: data?.clientTask.updateMany as ClientTask,
      };
    },
  };
};

const provider: UpdateManyProvider = (params) => {
  if (!params.data.state) {
    return updateMany(params);
  }
  return approveMany(params);
};

export default provider;
