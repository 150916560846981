import {
  useCallback, FC, ReactNode, memo,
} from 'react';
import {
  useNotify, Button, useRefresh, useUnselectAll, useUpdateMany,
} from 'react-admin';

interface BulkActionButtonProps {
  selectedIds? : number[]
  action: 'setApproved' | 'setArchived'
  value: boolean
  label: string
  startIcon?: ReactNode
}

const BulkActionButton: FC<BulkActionButtonProps> = ({
  selectedIds, action, value, label, startIcon,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll('Task');

  const [updateMany, { isLoading }] = useUpdateMany();

  const handleClick = useCallback(async () => {
    try {
      await updateMany('Task', { ids: selectedIds, data: { [action]: value } });
      refresh();
      unselectAll();
      notify('Task updated', { type: 'success' });
    } catch (err: any) {
      notify(`Task not updated error: ${err?.message}`, { type: 'warning' });
    }
  }, [action, notify, refresh, selectedIds, unselectAll, updateMany, value]);
  return (
    <Button
      sx={{
        '&': {
          paddingRight: '0.5rem',
          paddingLeft: '0.5rem',
          marginLeft: '0.25rem',
          fontSize: '0.8125rem',
        },
        '& .MuiButton-startIcon': {
          marginRight: '4px',
        },
      }}
      startIcon={startIcon}
      label={label}
      onClick={handleClick}
      disabled={isLoading}
    />
  );
};

export default memo(BulkActionButton);
