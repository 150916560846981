import { memo, useCallback, ReactNode } from 'react';
import { IconButton, Paper } from '@mui/material';
import { createUseStyles } from 'react-jss';
import {
  useNotify, useRefresh, useUnselectAll, useUpdateMany,
} from 'react-admin';

type BulkActionArchiveProps = {
  selectedIds? : number[]
  action: 'setApproved' | 'setArchived'
  value: boolean
  label: string
  icon?: ReactNode
};

const BulkActionArchive = (props: BulkActionArchiveProps) => {
  const {
    selectedIds, action, value, label, icon,
  } = props;
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll('Task');
  const classes = useStyles();

  const [updateMany, { isLoading }] = useUpdateMany();

  const handleClick = useCallback(async () => {
    try {
      await updateMany('Task', { ids: selectedIds, data: { [action]: value } });
      refresh();
      unselectAll();
      notify('Task updated', { type: 'success' });
    } catch (err: any) {
      notify(`Task not updated error: ${err?.message}`, { type: 'warning' });
    }
  }, [action, notify, refresh, selectedIds, unselectAll, updateMany, value]);

  return (
    <Paper sx={{ borderRadius: '50%' }} elevation={5}>
      <IconButton
        onClick={handleClick}
        sx={{
          backgroundColor: '#757575',
          color: '#fff',
        }}
      >
        {icon}
      </IconButton>
    </Paper>
  );
};

const useStyles = createUseStyles({
  button: {
    '&': {
      paddingRight: '0.5rem',
      paddingLeft: '0.5rem',
      marginLeft: '0.25rem',
      fontSize: '0.8125rem',
    },
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
  },
});

export default memo(BulkActionArchive);
