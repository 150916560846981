import { gql, CreateProvider } from 'services/dataProvider';

import { fields as TaskTypeFields } from '../fragments';

const provider: CreateProvider = (params) => {
  const { data } = params;

  const mutation = gql`
      ${TaskTypeFields}
      mutation ($input: TaskTypeCreateInput!) {
          taskType {
              create (input: $input) {
                  ...TaskTypeFields
              }
          }
      }
  `;

  let example = null;
  if (data?.example) {
    example = {
      id: data.example.id,
      thumbnail: data.example.thumbnail,
      url: data.example.url,
      name: data.example.name,
    };
  }

  return {
    mutation,
    variables: {
      input: {
        name: data?.name || '',
        description: data?.description || '',
        priorityLevel: String(data?.priorityLevel ?? 2),
        isOngoing: data?.isOngoing,
        example,
        tags: data?.tags.map?.((tag: any) => ({
          name: tag.name,
        })),
      },
    },
  };
};

export default provider;
