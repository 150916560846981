import { memo } from 'react';
import { Button } from 'react-admin';
import { createUseStyles } from 'react-jss';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

type BulkApproveButtonProps = {
  label?: string
  isLoading?: boolean
  handleApprove?: () => void
};

const BulkApproveButton = (props: BulkApproveButtonProps) => {
  const { label, handleApprove, isLoading } = props;
  const classes = useStyles();

  return (
    <Button
      startIcon={<CheckOutlinedIcon />}
      className={classes.button}
      label={label}
      onClick={handleApprove}
      disabled={isLoading}
    />
  );
};

const useStyles = createUseStyles({
  button: {
    '&': {
      paddingRight: '0.5rem',
      paddingLeft: '0.5rem',
      marginLeft: '0.25rem',
      fontSize: '0.8125rem',
    },
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
  },
});

export default memo(BulkApproveButton);
