import React, { FC, memo, useCallback } from 'react';
import {
  useNotify, useRecordContext, useRefresh, useTranslate, useUpdate,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { DateTime } from 'luxon';
import {
  Fab,
  IconButton, Paper, Portal, Stack,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Document } from 'interfaces/entities';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';

import FloatBackButton from 'components/FloatBackButton';

import NotesAddModal from './NotesAddModal';


interface IFormData {
  id: string | number,
  documents: Document[],
  notes: string,
  dueDateAt: string,
  isArchived: boolean,
  state: 'decline',
  status?: 'to_do' | 'approved',
  client?: {
    id: number | string,
  },
}

export const checkHasChanges = (recordData: IFormData, date: string, watch: Document[]): boolean => {
  const documentFormState = watch.map((document) => document.id || 0).join('|');
  const documentRecord = recordData.documents.map((document) => document.id || 0).join('|');
  const dueDateAtFormState = DateTime.fromISO(date).toFormat('ddLLyyyy');
  const dueDateAtRecord = DateTime.fromISO(recordData.dueDateAt).toFormat('ddLLyyyy');

  if (documentFormState !== documentRecord) {
    return true;
  }

  if (dueDateAtFormState !== dueDateAtRecord) {
    return true;
  }
  return false;
};

const ActionsToolbarMobile: FC = (props) => {
  const record = useRecordContext<IFormData>();
  const watch = useWatch<IFormData>();

  const [update, { isLoading }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const { isArchived, status } = record;
  const dueDateAtFormState = useWatch({ name: 'dueDateAt' });
  const documentFormState = useWatch({ name: 'documents' });

  const { reset } = useFormContext();
  const hasChanges = checkHasChanges(record, dueDateAtFormState, documentFormState);
  const navigate = useNavigate();
  const translate = useTranslate();

  const handleReset = useCallback(() => {
    reset();
  }, [reset]);

  const handleSave = useCallback(async () => {
    try {
      await update('Task', { id: watch.id, data: watch, previousData: record }, {
        returnPromise: true,
      });
    } catch (error) {
      notify((error as Error).message, { type: 'warning' });
      return;
    }

    refresh();
  }, [refresh, update, watch, record, notify]);

  const handleApprove = useCallback(async () => {
    try {
      await update('Task', { id: watch.id, data: { state: 'approve', id: watch.id } }, {
        returnPromise: true,
        onSuccess: () => {
          notify('Task updated');
          navigate(-1);
        },
        onError: (error) => notify(`Task not updated error: ${error}`, { type: 'warning' }),
      });
    } catch (error) {
      notify((error as Error).message, { type: 'warning' });
    }
  }, [update, watch.id, notify, navigate]);

  const handleFinalize = useCallback(async () => {
    try {
      await update('Task', { id: watch.id, data: { state: 'finalize', id: watch.id } }, {
        returnPromise: true,
        onSuccess: () => {
          notify('Task updated');
          navigate(-1);
        },
        onError: (error) => notify(`Task not updated error: ${error}`, { type: 'warning' }),
      });
    } catch (error) {
      notify((error as Error).message, { type: 'warning' });
    }
  }, [update, watch.id, notify, navigate]);

  const handleArchive = useCallback(async () => {
    try {
      await update('Task', { id: watch.id, data: { state: 'archive', id: watch.id } }, {
        returnPromise: true,
        onSuccess: () => {
          notify('Task updated');
          navigate(-1);
        },
        onError: (error) => notify(`Task not updated error: ${error}`, { type: 'warning' }),
      });
    } catch (error) {
      notify((error as Error).message, { type: 'warning' });
    }
  }, [update, watch.id, notify, navigate]);

  const handleUnArchive = useCallback(async () => {
    try {
      await update('Task', { id: watch.id, data: { state: 'restore', id: watch.id } }, {
        returnPromise: true,
        onSuccess: () => {
          notify('Task updated');
          navigate(-1);
        },
        onError: (error) => notify(`Task not updated error: ${error}`, { type: 'warning' }),
      });
    } catch (error) {
      notify((error as Error).message, { type: 'warning' });
    }
  }, [update, watch.id, notify, navigate]);

  const handleComplete = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Portal>
      {hasChanges && (
      <Stack
        spacing={2}
        sx={{
          position: 'fixed', bottom: '10rem', zIndex: 100, right: '1.3rem',
        }}
      >
        <Paper sx={{ borderRadius: '50%' }} elevation={5}>
          <IconButton
            disabled={isLoading}
            sx={{ backgroundColor: '#549E56', color: '#fff' }}
            size="medium"
            onClick={handleSave}
          >
            <SaveOutlinedIcon />
          </IconButton>
        </Paper>
        <Paper sx={{ borderRadius: '50%' }} elevation={5}>
          <IconButton
            disabled={isLoading}
            sx={{ backgroundColor: '#D75241', color: '#fff' }}
            size="medium"
            onClick={handleReset}
          >
            <RestoreOutlinedIcon />
          </IconButton>
        </Paper>
      </Stack>
      )}
      {!hasChanges && (
      <Stack
        hidden={isArchived}
        spacing={2}
        sx={{
          position: 'fixed', bottom: '10rem', zIndex: 100, right: '1.3rem',
        }}
      >
        <Paper hidden={isArchived || status === 'approved'} sx={{ borderRadius: '50%' }} elevation={5}>
          <IconButton
            disabled={isLoading}
            sx={{ backgroundColor: '#237236', color: '#fff' }}
            size="medium"
            onClick={handleFinalize}
          >
            <DoneAllOutlinedIcon />
          </IconButton>
        </Paper>
        <Paper hidden={isArchived || status === 'approved'} sx={{ borderRadius: '50%' }} elevation={5}>
          <IconButton
            disabled={isLoading}
            sx={{ backgroundColor: '#549E56', color: '#fff' }}
            size="medium"
            onClick={handleApprove}
          >
            <CheckOutlinedIcon />
          </IconButton>
        </Paper>
        <NotesAddModal
          id="decline-confirm"
          hidden={isArchived}
          ButtonProps={{
            text: translate('resources.task.dontApprove'),
            color: 'primary',
            variant: 'contained',
          }}
          FormProps={{
            title: 'Don\'t approve',
            submitLabel: 'Confirm',
          }}
          record={record}
          onComplete={handleComplete}
        />
        {!isArchived && (
        <Paper sx={{ borderRadius: '50%' }} elevation={5}>
          <IconButton
            disabled={isLoading}
            sx={{ backgroundColor: '#757575', color: '#fff' }}
            size="medium"
            onClick={handleArchive}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Paper>
        )}
      </Stack>
      )}
      {isArchived && (
      <Fab
        hidden={hasChanges}
        disabled={isLoading}
        sx={{
          backgroundColor: '#757575',
          color: '#fff',
          position: 'fixed',
          bottom: '3.75rem',
          zIndex: 1000,
          right: '1.25rem',
        }}
        size="large"
        onClick={handleUnArchive}
      >
        <RestoreFromTrashIcon />
      </Fab>
      )}
      <FloatBackButton
        size="small"
        sx={{
          position: 'fixed',
          bottom: 'calc(77rem / 16)',
          zIndex: 1000,
          color: 'white',
          left: '1.25rem',
          backgroundColor: 'grey',
          opacity: 0.9,
        }}
      />
    </Portal>
  );
};


export default memo(ActionsToolbarMobile);
