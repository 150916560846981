import { gql, GetListProvider } from 'services/dataProvider';

import { fields as ClientFields } from '../fragments';

const provider: GetListProvider = (params) => {
  const query = gql`
    ${ClientFields}
    query(
      $pagination: ListPaginationPayload
      $sort: ListSortPayload
      $filter: ClientManyFilter
    ) {
      client {
        many(
          pagination: $pagination
          sort: $sort
          filter: $filter
        ) {
          items {
            ...ClientFields
            tasks {
              items {
                notes
                dueDateAt
                status
                documents {
                  id
                }
                createdAt
                updatedAt
              }
            }
          }
          pageInfo {
            page
            perPage
            totalItems
            totalPages
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }
  `;
  return {
    query,
    variables: {
      pagination: params.pagination,
      sort: params.sort,
      filter: params.filter,
    },
  };
};

export default provider;
