import {
  memo,
  PropsWithChildren, ReactElement, useCallback,
} from 'react';
import {
  Toolbar as RAToolbar,
} from 'react-admin';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { FormButton } from 'components/FormButton';

const Toolbar = (props:PropsWithChildren): ReactElement => {
  const navigate = useNavigate();

  const handleBack = useCallback(async () => {
    navigate(-1);
  }, [navigate]);

  return (
    <RAToolbar
      sx={{
        display: 'flex',
        columnGap: '1rem',
      }}
    >
      <>
        <Button onClick={handleBack} variant="outlined">Back</Button>
        <Box
          sx={{
            flex: 1,
          }}
        />
        <FormButton label="Save Changes" />
      </>
    </RAToolbar>
  );
};

export default memo(Toolbar);
