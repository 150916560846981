import english from 'ra-language-english';

export default {
  ...english,
  resources: {
    taskType: {
      name: 'Tasks pool',
      action: {
        newTask: 'New task',
        add: 'Add',
        edit: 'Edit',
        cancel: 'Cancel',
        search: 'Search',
        uploadCsv: 'UPLOAD CSV',
        downloadExampleCsv: 'get csv example',
      },
      fields: {
        name: 'Name',
        description: 'Description',
        example: 'Example',
        priorityLevel: 'Priority level',
        low: 'Low',
        medium: 'Medium',
        high: 'High',
        dropFileHereToUpload: 'Drop file here to upload',
        tags: 'Tags',
        repeatTask: 'Repeat task every month',
      },
    },
    client: {
      name: 'Clients',
      didntStart: 'Didn\'t start yet',
      taskAwaitingApproval: 'task awaiting approval',
      allTasksCompleted: 'Completed',
      taskOutstanding: 'Completed',
      client: 'Client',
      editClient: 'Edit client',
      action: {
        createClient: 'Create client',
        create: 'create',
        cancel: 'cancel',
      },
      fields: {
        clientName: 'Client Name',
        taskApprovedWaiting: 'Task/Approved/Waiting',
        documents: 'Documents',
        nextDueDate: 'Next due date',
        notes: 'Notes',
        address: 'Address',
        state: 'State',
        zipcode: 'Zipcode',
        city: 'City',
        firstName: 'First name',
        lastName: 'Last Name',
        email: 'Email',
        mobilePhone: 'Mobile Phone',
        dateOfBirth: 'Date of birth',
        totalTasks: 'Total Tasks',
        tasksStillOutstanding: 'Tasks Still Outstanding',
      },
    },
    task: {
      name: 'Client Tasks',
      addNewTask: 'Add new tasks',
      editTask: 'Edit task',
      save: 'save',
      downloadAll: 'DOWNLOAD ALL',
      approve: 'Approve',
      finalize: 'Finalize',
      dontApprove: 'Don\'t approve',
      download: 'DOWNLOAD',
      taskName: 'Task',
      clientName: 'Client',
      description: 'Description',
      dueDate: 'Due Date',
      lastUpdate: 'Last Update',
      docs: 'Docs',
      notes: 'Notes',
      status: 'Status',
      archived: 'Archived',
      yes: 'Yes',
      no: 'No',
      values: {
        status: {
          todo: 'To do',
          inReview: 'In review',
          approved: 'Approved',
          showArchived: 'Show archived',
          archive: 'Archived',
          unArchive: 'Unarchived',
        },
      },
    },
    companyAdmin: {
      name: 'Members',
      newMember: 'New member',
      fields: {
        name: 'Name',
        phone: 'Phone',
        email: 'E-mail',
        role: 'Role',
        status: 'Status',
      },
    },
    setting: {
      fields: {
        setting: 'Setting',
        accountSetting: 'Account Settings',
        companySetting: 'Company Settings',
        companyName: 'Company name',
        companyWebsite: 'Company website',
        companyLogo: 'Company Logo',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'E-mail',
        phone: 'Phone',
        userPic: 'UserPic',
      },
    },
  },
  'SyntaxError: Response is not JSON': 'SyntaxError: Response is not JSON',
};
