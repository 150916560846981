import { memo, useCallback } from 'react';
import {
  Fab,
  Paper, Portal,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useWatch } from 'react-hook-form';

import type { AddComponentProps } from 'components/FileInput';

const DocumentAddMobile = (props: AddComponentProps) => {
  const isArchived = useWatch({ name: 'isArchived' });
  const handleStopPropagation = useCallback((event: { stopPropagation: () => void; }) => {
    event.stopPropagation();
  }, []);

  return (
    <Portal>
      <Paper
        hidden={isArchived}
        sx={{
          borderRadius: '50%',
          position: 'fixed',
          bottom: '4.3rem',
          zIndex: 100,
          right: '1.3rem',
          backgroundColor: '#1976D2',
        }}
        elevation={5}
      >
        <Fab sx={{ backgroundColor: 'transparent' }} onClick={handleStopPropagation} disabled>
          <AddIcon sx={{ color: 'white' }} />
        </Fab>
      </Paper>
    </Portal>
  );
};

export default memo(DocumentAddMobile);
