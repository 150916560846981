import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { GetListResult, GetListParams, GetListProvider } from './types';

export type { GetListResult, GetListParams, GetListProvider };

export default async (client: ApolloClient<NormalizedCacheObject>, provider: GetListProvider, resource: string, params: GetListParams): Promise<GetListResult<any>> => {
  const { parser, ...clientArgs } = provider(params);
  const { data, error } = await client.query({
    fetchPolicy: 'no-cache',
    ...clientArgs,
  });

  if (error) {
    return Promise.reject(error.graphQLErrors[0]);
  }

  if (!data) {
    return Promise.reject(new Error('Not data found'));
  }

  if (parser) {
    return parser(data);
  }

  const firstNode = data[Object.keys(data)[0]];

  return {
    data: firstNode?.many?.items ?? firstNode?.many,
    total: firstNode?.many?.pageInfo?.totalItems ?? firstNode?.manyMeta?.total,
  };
};
