import {
  FC, memo, useCallback, useRef,
} from 'react';
import {
  useCreate, useNotify, useRefresh, Identifier, useUnselectAll, useTranslate,
} from 'react-admin';

import { Button } from '@mui/material';

import { createUseStyles } from 'react-jss';

import * as ModalwithForm from 'components/ModalwithForm';
import { FormButton } from 'components/FormButton';

import TasksInput, { ITaskItem } from './TasksInput';


interface IFormData {
  tasks: ITaskItem[],
}

interface ModalTaskCreateProps {
  ButtonProps?: {
    className?: string,
    text: string,
    variant: 'contained' | 'outlined' | 'text',
    color: 'primary' | 'secondary' | 'inherit' | 'error' | 'success' | 'info' | 'warning',
    disabled?: boolean,
  },
  client?: {
    id?: Identifier,
    name?: string,
  },
}

const TaskAddModal: FC<ModalTaskCreateProps> = (props) => {
  const {
    ButtonProps,
    client,
  } = props;
  const clientId = client?.id;
  const classes = useStyles();
  const dialogRef = useRef(null);

  const [create, { isLoading }] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const unSelectAll = useUnselectAll('TaskType');
  const translate = useTranslate();

  const handleClickOpen = useCallback(() => {
    ModalwithForm.open(dialogRef);
    unSelectAll();
  }, [unSelectAll]);

  const handleSubmit = useCallback(async (data: IFormData) => {
    const { tasks } = data;
    try {
      await create(
        'Task',
        {
          data: {
            clientIds: clientId,
            tasks: (tasks || []).map(({ id, dueDateAt }) => ({ id, dueDateAt })),
            note: '',
          },
        },
        {
          returnPromise: true,
        },
      );
    } catch (error: any) {
      notify(error?.message, { type: 'warning' });
      return;
    }

    ModalwithForm.close(dialogRef);
    refresh();
  }, [clientId, create, notify, refresh]);


  return (
    <>
      <Button
        className={ButtonProps?.className}
        variant={ButtonProps?.variant || 'contained'}
        color={ButtonProps?.color || 'primary'}
        disabled={ButtonProps?.disabled || false}
        onClick={handleClickOpen}
      >
        {ButtonProps?.text}
      </Button>
      <ModalwithForm.Dialog
        ref={dialogRef}
        title="Add Task for"
        onSubmit={handleSubmit}
        classes={{
          paper: classes.paper,
        }}
        headerContent={(
          <div>
            {client?.name || ''}
          </div>
        )}
        bodyContent={(
          <div className={classes.bodyWrapper}>
            <div className={classes.bodyList}>
              <TasksInput source="tasks" />
            </div>
          </div>
        )}
        actionsRightSide={(
          <FormButton variant="contained" color="primary" label="Add Task" />
        )}
      />
    </>
  );
};

const useStyles = createUseStyles((theme) => ({
  paper: {
    width: '100%',
    height: '100%',
    maxWidth: '60rem',
    maxHeight: '45rem',
  },
  bodyWrapper: {
    position: 'relative',
    flex: 1,
  },
  bodyList: {
    position: 'absolute',
    top: 0,
    right: '-2.5rem',
    bottom: '-1.5rem',
    left: '-2.5rem',
    padding: '0 2.5rem',
    overflow: 'auto',
  },
  button: {
    width: '9rem',
  },
  tasks: {
    '&': {
      marginTop: '3rem',
    },
    '& tr:last-child td': {
      borderBottom: 0,
    },
    '& .MuiTableCell-paddingCheckbox ': {
      width: '3rem',
      height: '3.1rem',
      paddingRight: 0,
      paddingLeft: '0.5rem',
    },
    '& .column-id': {
      width: '3rem',
      paddingRight: 0,
    },
    '& .column-selector': {
      width: '3rem',
      paddingRight: 0,
      paddingLeft: '0.5rem',
    },
    '& .column-document': {
      '&': {
        width: '7rem',
        paddingTop: '1px',
        paddingRight: 0,
        paddingBottom: '1px',
      },
      '& div': {
        '&': {
          height: '4rem',
          aspectRatio: '16 / 9',
        },
      },
    },
    '& .column-Due.Date': {
      '&': {
        width: '8rem',
        paddingRight: 0,
        paddingLeft: 0,
      },
      '& > div': {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
  },
}));

export default memo(TaskAddModal);
