import { memo, type PropsWithChildren, Fragment } from 'react';
import {
  AppBar as RaAppBar,
} from 'react-admin';
import {
  Box, Theme, Typography, useMediaQuery,
} from '@mui/material';

import { useIdentity } from 'services/AuthProvider/hooks';
import AuthUser from 'components/AuthUser';

const AppBar = (props: PropsWithChildren) => {
  const isLargeEnough = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  const identity = useIdentity();
  return (
    <RaAppBar
      {...props}
      elevation={1}
      container={Fragment}
      sx={{
        height: '3rem',
        backgroundColor: 'rgb(33, 150, 243)',
      }}
      userMenu={isLargeEnough ? (
        <AuthUser
          firstName={identity?.user?.firstName || ''}
          lastName={identity?.user?.lastName || ''}
          company={identity?.user?.company?.name || ''}
          loading={identity?.loading}
          profilePhotoUrl={identity?.user?.avatarUrl || ''}
        />
      ) : false}
    >
      <Typography
        variant="h6"
        color="inherit"
        id="react-admin-title"
      />
      {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
    </RaAppBar>
  );
};

export default memo(AppBar);
