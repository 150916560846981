import { type CSSProperties } from 'react';
import {
  ListToolbar, Identifier, ListBase,
  useRedirect,
} from 'react-admin';
import { Box, Fab, InputLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import IsArchivedFilter from 'components/IsArhcivedFilter';
import FloatBackButton from 'components/FloatBackButton';

import BulkActionToolbar from '../elements/BulkActionToolbar';
import MobileGrid from '../elements/MobileGrid';

type TaskAddButtonProps = {
  clientId?: Identifier,
};
const TaskAddButton = (props: TaskAddButtonProps) => {
  const { clientId } = props;
  const redirect = useRedirect();
  const handleClick = () => {
    redirect('create', 'Task', 1, {}, { record: { userId: clientId } });
  };
  return (
    <Fab
      sx={{
        position: 'fixed',
        bottom: '3.75rem',
        zIndex: 1000,
        right: '1.25rem',
        color: 'white',
        backgroundColor: '#1976D2',
      }}
      onClick={handleClick}
    >
      <AddIcon />
    </Fab>
  );
};

const filters = [
  <IsArchivedFilter
    label="resources.task.values.status.showArchived"
    source="isArchived"
    alwaysOn
  />,
];

const defaultProps = {
  hasPagination: false,
  perPage: 10,
};

type MobileTasksFieldProps = {
  clientName?: string,
  className?: string,
  style?: CSSProperties,
  clientId?: Identifier,
  perPage?: number,
  hasPagination?: boolean,
} & typeof defaultProps;

const MobileTasksField = (props: MobileTasksFieldProps) => {
  const {
    clientName,
    className,
    style,
    clientId,
    hasPagination,
    perPage,
  } = props;


  return (
    <Box sx={{
      flex: 1,
      '& .MuiToolbar-root:first-child': {
        '&': {
          display: 'flex',
          flex: 1,
          background: 'transparent',
        },
        '& form': {
          '&': {
            display: 'flex',
            flex: 1,
          },
          '& .RaFilterFormInput-spacer': {
            display: 'none',
          },
          '& [data-source="isArchived"]': {
            marginLeft: 'auto',
            marginBottom: '-2rem',
          },
        },
      },
    }}
    >
      <ListBase
        resource="Task"
        filter={{ clientId: clientId || 0 }}
        sort={{ field: 'id', order: 'desc' }}
        perPage={perPage}
      >
        <ListToolbar
          filters={filters}
        />
        <>
          <InputLabel sx={{ marginLeft: '1rem' }} shrink>Tasks</InputLabel>
          <BulkActionToolbar />
          <TaskAddButton clientId={clientId} />
          <FloatBackButton
            size="small"
            sx={{
              position: 'fixed',
              bottom: 'calc(68rem / 16)',
              zIndex: 1000,
              color: 'white',
              left: '1.25rem',
              backgroundColor: 'grey',
              opacity: 0.9,
            }}
          />
          <MobileGrid />
        </>
      </ListBase>
    </Box>
  );
};

MobileTasksField.defaultProps = defaultProps;

export default MobileTasksField;
