import { FC, memo } from 'react';

import type { ItemComponentProps } from 'components/FileInput';
import DocumentPreview from 'components/DocumentPreview';
import { Box } from '@mui/material';

import { useWatch } from 'react-hook-form';

interface DocumentItemProps extends Omit<ItemComponentProps, 'data'> {
  data?: {
    id?: number,
    thumbnail?: string,
  } & ItemComponentProps['data']
}

const DocumentItem: FC<DocumentItemProps> = (props) => {
  const { data, onRemove } = props;

  const isArchived = useWatch({ name: 'isArchived' });

  if (!data?.url && !data?.id) {
    return null;
  }

  if (isArchived && !data?.url) {
    return (
      <Box
        sx={{
          pointerEvents: 'all',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          overflow: 'hidden',
          width: 'calc(50% - 0.25rem)',
        }}
      >
        <DocumentPreview
          url=""
          name="Unavailable Document"
          aspectRatio="1"
        />
      </Box>
    );
  }

  if (!data?.url) {
    return (
      <Box
        sx={{
          pointerEvents: 'all',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          overflow: 'hidden',
          width: 'calc(50% - 0.25rem)',
        }}
      >
        <DocumentPreview
          onRemove={onRemove}
          url=""
          name="Unavailable Document"
          aspectRatio="1"
        />
      </Box>
    );
  }

  return (
    <>
      {!isArchived && (
        <Box sx={{
          pointerEvents: 'all',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          overflow: 'hidden',
          width: 'calc(50% - 0.25rem)',
        }}
        >
          <DocumentPreview
            onRemove={onRemove}
            url={data.url}
            name={data.name}
            thumbnail={data.thumbnail}
            aspectRatio="1"
          />
        </Box>
      )}
      {isArchived && (
        <Box sx={{
          pointerEvents: 'all',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          overflow: 'hidden',
          width: 'calc(50% - 0.25rem)',
        }}
        >
          <DocumentPreview
            url={data.url}
            name={data.name}
            thumbnail={data.thumbnail}
            aspectRatio="1"
          />
        </Box>
      )}
    </>
  );
};

export default memo(DocumentItem);
