import React, {
  memo, type CSSProperties, type PropsWithChildren, type ReactElement,
} from 'react';
import { Avatar as MaterialAvatar } from '@mui/material';

const stringToColor = (string?: string): string | undefined => {
  let hash = 0;
  let i;
  if (!string) {
    return undefined;
  }
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return `${color}ff`;
};

export const getAvatarText = (name = ''): string | null => {
  const preparedName = name?.toUpperCase().replace(/\s\s+/g, ' ').trim();
  const [firstWord, secondWord] = preparedName.split(' ');
  if (!firstWord) {
    return null;
  }
  if (!secondWord) {
    return firstWord.slice(0, 2);
  }
  return (`${firstWord[0]}${secondWord[0]}`);
};

const isImageUrl = (url: string): boolean => url.match(/\.(jpg|gif|png|webp)$/) != null;

type AvatarProps = {
  name?: string,
  image?: string,
  className?: string,
  style?: CSSProperties,
};

const Avatar = (props:PropsWithChildren<AvatarProps>): ReactElement => {
  const {
    name, image, className, style,
  } = props;
  if (typeof image === 'string' && isImageUrl(image)) {
    return (
      <MaterialAvatar className={className} style={style} src={image} />
    );
  }
  return (
    <MaterialAvatar
      className={className}
      style={{
        ...style,
        backgroundColor: stringToColor(name),
      }}
    >
      {getAvatarText(name)}
    </MaterialAvatar>
  );
};

export default memo(Avatar);
