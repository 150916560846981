import FileSaver from 'file-saver';

export const getMimetypeByExt = (ext: string): string => {
  const mimetypeByExt: Record<string, string> = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    pdf: 'application/pdf',
    zip: 'application/zip',
  };
  return mimetypeByExt[`${ext}`.toLowerCase()] || '';
};

export const getExt = (url?: string): string => {
  return `${url}`.replace(/.*\.([A-z0-9]{3,4})$/, '$1');
};

export const download = (url: string, name?: string): void => {
  const ext = getExt(url);
  if (!url) {
    return;
  }

  const typeExt = getMimetypeByExt(ext);
  if (!typeExt) {
    return;
  }
  const altName = url.replace(/.*\/([^/]+)$/, '$1');
  const oReq = new XMLHttpRequest();
  oReq.open('GET', url, true);
  oReq.responseType = 'blob';
  oReq.onload = () => {
    const file = new Blob([oReq.response], { type: typeExt });
    FileSaver.saveAs(file, name || altName);
  };
  oReq.send();
};

export const checkImage = (url: string): boolean => {
  if (!url) {
    return false;
  }
  const ext = getExt(url);
  return getMimetypeByExt(ext).startsWith('image/');
};

export const checkPdf = (url: string): boolean => {
  if (!url) {
    return false;
  }
  const ext = getExt(url);
  return getMimetypeByExt(ext) === 'application/pdf';
};
