import { memo, PropsWithChildren } from 'react';
import type {
  CSSProperties, ReactElement,
} from 'react';
import { Box, Paper, Typography } from '@mui/material';

const defaultProps = {
  height: '40vh',
};

type EmptyListProps = {
  body: string,
  height?: CSSProperties['height'],
  toolbar?: ReactElement,
}& typeof defaultProps;

const EmptyList = (props:PropsWithChildren<EmptyListProps>): ReactElement => {
  const {
    body,
    height,
    toolbar,
  } = props;

  return (
    <Box sx={{ width: '100%', marginTop: '1.4rem' }}>
      {toolbar}
      <Paper
        sx={{
          height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '0.2rem',
        }}
      >
        <Typography
          sx={{
            color: '#2B2F36',
            fontWeight: 400,
            fontSize: '1.5rem',
            textAlign: 'center',
          }}
        >
          {body}
        </Typography>
      </Paper>
    </Box>
  );
};

EmptyList.defaultProps = defaultProps;

export default memo(EmptyList);
