import { FC, memo } from 'react';
import { TextInput } from 'react-admin';
import { InputAdornment } from '@mui/material';
import IconSearch from '@mui/icons-material/Search';


interface SearchInputProps {
  label: string,
  source: string,
}

const SearchInput: FC<SearchInputProps> = (props) => {
  const {
    label,
    source,
  } = props;

  const endAdornment = (
    <InputAdornment position="end">
      <IconSearch style={{ color: '#606B7C' }} />
    </InputAdornment>
  );

  return (
    <TextInput
      label={label}
      helperText={' '}
      variant="outlined"
      sx={{ backgroundColor: '#FFFFFF', boxShadow: 2, borderRadius: 1 }}
      InputProps={{
        endAdornment,
      }}
      resettable
      name={source}
      source={source}
    />
  );
};


export default memo(SearchInput);
