import { FC, memo } from 'react';
import {
  Typography, CircularProgress, IconButton,
} from '@mui/material';
import IconRemoveCircle from '@mui/icons-material/RemoveCircle';
import { createUseStyles } from 'react-jss';

import type { ProgressComponentProps } from 'components/FileInput';

const DocumentProgress: FC<ProgressComponentProps> = (props) => {
  const {
    files = [],
    percent,
    abort,
  } = props;
  const classes = useStyles();

  const progressValue = (percent || 0) * 100;

  return (
    <div className={classes.progress}>
      <div className={classes.document}>
        <div className={classes.icon}>
          <CircularProgress variant="determinate" value={progressValue} />
          <Typography className={classes.loading} variant="caption" component="div" color="textSecondary">
            {`${progressValue.toFixed(0)}%`}
          </Typography>
        </div>
        {files?.length === 1 && (
          <Typography className={classes.text} variant="caption" component="div">
            {`uploading .${(files[0].name || files[0].url || '').replace(/.*\.([A-z0-9]{3,4})$/, '$1')} file`}
            <br />
            please wait
          </Typography>
        )}
        {files?.length > 1 && (
          <Typography className={classes.text} variant="caption" component="div">
            {`uploading ${files?.length} ${files?.length === 1 ? 'file' : 'files'}`}
            <br />
            please wait
          </Typography>
        )}
      </div>
      {files?.length === 1 && <Typography className={classes.name} variant="caption">{files[0].name}</Typography>}
      <IconButton onClick={abort} aria-label="delete" color="secondary">
        <IconRemoveCircle fontSize="inherit" />
      </IconButton>
    </div>
  );
};

const useStyles = createUseStyles({
  progress: {
    '&': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      width: '12rem',
    },
    '& [aria-label="delete"]': {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
  icon: {
    '&': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '2.4rem',
    },
    '& svg': {
      height: '100%',
    },
  },
  loading: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.625rem',
  },
  document: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    border: '1px solid #606B7C',
    borderRadius: '0.5rem',
    padding: 0,
    aspectRatio: '150 / 132',
  },
  text: {
    marginTop: '0.6rem',
  },
  name: {
    margin: '0.3rem 0.2rem 0',
  },
});

export default memo(DocumentProgress);
