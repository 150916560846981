import { gql } from 'services/dataProvider';

export const fields = gql`
  fragment TaskTypeFields on TaskType {
    id
    name
    description
    example {
      id
      name
      thumbnail
      url
    }
    isOngoing  
    priorityLevel
    tags {
      id
      name
    }
  }
`;
