import { gql, CreateProvider } from 'services/dataProvider';

import { fields as TaskTypeFields } from '../fragments';

const uploadCsv: CreateProvider = (params) => {
  const { data } = params;
  console.log(data, 'DATA');
  const mutation = gql`
      ${TaskTypeFields}
      mutation ($input: TaskTypeUploadCsvInput!) {
          taskType {
              uploadCsv (input: $input) {
                  ...TaskTypeFields
              }
          }
      }
  `;

  return {
    mutation,
    variables: {
      input: {
        base64: data?.base64 || '',
      },
    },
  };
};

const provider: CreateProvider = (params) => {
  if (!params.data.base64) {
    throw new Error('Base64 data is required');
  }
  return uploadCsv(params);
};

export default provider;
