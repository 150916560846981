import { FC, memo } from 'react';
import { createUseStyles } from 'react-jss';
import { Typography, CircularProgress } from '@mui/material';


import { FileData } from '../types';
import { ReactComponent as IconFile } from '../assets/icon-file.svg';

export interface EmptyComponentProps {
  // uuid?: string,
  files?: FileData[],
  percent?: number,
  isLoading?: boolean,
  // abort?: () => void,
}

const DefaultEmptyComponent: FC<EmptyComponentProps> = (props) => {
  const { isLoading, files, percent } = props;
  const classes = useStyles();

  const progressValue = (percent || 0) * 100;

  if (isLoading) {
    return (
      <div className={classes.empty}>
        <div className={classes.icon}>
          <CircularProgress variant="determinate" value={progressValue} />
          <Typography className={classes.loading} variant="caption" component="div" color="textSecondary">
            {`${progressValue.toFixed(0)}%`}
          </Typography>
        </div>
        <Typography className={classes.text} variant="caption" component="div">
          {`uploading ${files?.length} ${files?.length === 1 ? 'file' : 'files'}`}
          <br />
          please wait
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.empty}>
      <div className={classes.icon}>
        <IconFile />
      </div>
      <Typography className={classes.text} variant="caption" component="div">
        drop file here
        <br />
        to upload
      </Typography>
    </div>
  );
};

const useStyles = createUseStyles({
  empty: {
    '&': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      boxSizing: 'border-box',
      border: '1px solid #606B7C',
      borderRadius: '0.5rem',
      padding: '1.4rem',
    },
  },
  icon: {
    '&': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '4.1rem',
    },
    '& svg': {
      height: '100%',
    },
  },
  loading: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.625rem',
  },
  text: {
    marginTop: '0.8rem',
  },
});

export default memo(DefaultEmptyComponent);
