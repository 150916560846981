import {
  memo,
  PropsWithChildren, ReactElement,
} from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import {
  Theme, useMediaQuery,
} from '@mui/material';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import Icon from 'components/Icon';
import LinkButton from 'components/LinkButton';

const SERVER_URL = (process.env.REACT_APP_SERVER_URL || '').replace(/\/$/, '');

const DownloadAll = (props: PropsWithChildren): ReactElement => {
  const record = useRecordContext();
  const translate = useTranslate();
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const isDocumentsEmpty = !record.documents?.length;

  return (
    <>
      {isXSmall && (
        <LinkButton
          disable={isDocumentsEmpty}
          variant="bulk"
          href={`${SERVER_URL}/api/download-all/client-task/${record.id}/${record.client.firstName}-docs.pdf`}
          icon={<FileDownloadOutlinedIcon />}
        />
      )}
      {!isXSmall && (
        <LinkButton
          disable={isDocumentsEmpty}
          variant="bulk"
          href={`${SERVER_URL}/api/download-all/client-task/${record.id}/${record.client.firstName}-docs.pdf`}
          icon={<Icon name="DownloadFill" fontSize="small" />}
          text={translate('resources.task.downloadAll')}
        />
      )}
    </>
  );
};

export default memo(DownloadAll);
