import {
  FC, useCallback, memo, useRef, useState,
} from 'react';
import { DateTime } from 'luxon';
import { Button, TextField, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';

import * as Modal from 'components/Modal';
import FlatDatePicker from 'components/FlatDatePicker';
import { Identifier, useLocaleState } from 'react-admin';

interface DatePickerMobileProps {
  initValue?: string,
  onChange?: (value: string) => void,
  selectedIds: Identifier[],
}

const DatePickerMobile: FC<DatePickerMobileProps> = memo((props) => {
  const {
    initValue,
    onChange,
    selectedIds,
  } = props;

  const [locale] = useLocaleState();

  const format = {
    en: 'MM/dd/yyyy',
    he: 'yyyy.MM.dd',
  };

  const classes = useStyles();
  const dialogRef = useRef(null);
  const [value, setValue] = useState(initValue || DateTime.now().toFormat(format[locale as 'en' | 'he']));

  const handleOpen = useCallback(() => {
    if (selectedIds.length !== 0) Modal.open(dialogRef);
  }, [selectedIds.length]);

  const handleSubmit = useCallback(() => {
    onChange?.(value);
    Modal.close(dialogRef);
  }, [onChange, value]);

  const handleChange = useCallback((newValue: string) => {
    setValue(newValue);
  }, []);

  return (
    <>
      <Typography hidden={selectedIds.length === 0} fontSize={14}>
        Due data for all:
        &nbsp;
        <TextField
          onClick={handleOpen}
          margin="none"
          value={value}
          variant="standard"
          disabled={selectedIds.length === 0}
          InputProps={{ disableUnderline: true }}
          sx={{
            '.MuiInputBase-root': { width: '5rem', color: '#1976D2' },
            '.MuiInputBase-input': {
              padding: 0, color: '#1976D2', fontSize: 15, fontWeight: 700,
            },
          }}
        />
      </Typography>
      <Modal.Dialog
        title="Set Due Date for All Tasks"
        ref={dialogRef}
        classes={{ body: classes.calendarBody, paper: classes.calendarPaper }}
        bodyContent={(
          <FlatDatePicker
            className={classes.calendar}
            onChange={handleChange}
            value={value}
          />
        )}
        actionsStretchButtons
        actionsRightSide={(
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Apply
          </Button>
        )}
      />
    </>
  );
});

export default memo(DatePickerMobile);

const useStyles = createUseStyles({
  calendarBody: {
    padding: 0,
  },
  calendarPaper: {
    margin: '1rem',
  },
  calendar: {
    marginLeft: '-2.2rem',
    marginRight: '-2.2rem',
  },
});
