import type { FC } from 'react';
import {
  Edit,
} from 'react-admin';
import { createUseStyles } from 'react-jss';


const NotificationEdit: FC = (props) => {
  const classes = useStyles();
  return (
    <Edit title="Notifications" className={classes.container} {...props}>
      {/* <SimpleForm redirect={false} toolbar={<Toolbar />} className={classes.form}> */}
      {/*   <Typography variant="h5" className={classes.labelCompany}> */}
      {/*     Company Notifications */}
      {/*   </Typography> */}
      {/*   <Setting source="shouldNotifyWhenAllTasksSubmittedByClient" label="Notify when all tasks have been submitted by the client" /> */}
      {/*   <Setting source="shouldNotifyWhenEachUploadedTask" label="Notify for each uploaded task" /> */}
      {/*   <Setting source="countDaysAfterPastDueForTasks" label="How many days after past due for tasks" /> */}
      {/*   <Typography variant="h5" className={classes.labelClient}> */}
      {/*     Client Notifications */}
      {/*   </Typography> */}
      {/*   <Setting source="shouldNotifyNewTaskWasAdded" label="New task was added (only after client registration)" /> */}
      {/*   <Setting source="shouldNotifyTaskWasDenied" label="Task was denied" /> */}
      {/*   <Setting source="shouldNotifyWhenAllApproved" label="When all tasks approved" /> */}
      {/*   <Setting source="countDaysBeforeDueDate" label="Days before due date" /> */}
      {/*   <Setting source="shouldNotifyViaSMS" label="Should notify via sms past due date" /> */}
      {/*   <Setting source="shouldNotifyViaEmail" label="Should notify via emai past due date" /> */}
      {/* </SimpleForm> */}
    </Edit>
  );
};

const useStyles = createUseStyles({
  container: {
    '&': {
      width: '55%',
      flex: 0,
    },
    '@media (max-width: 1024px)': {
      width: 'auto',
      flex: 1,
    },
  },
  labelCompany: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  labelClient: {
    marginTop: '4rem',
    marginBottom: '1rem',
  },
  form: {
    paddingBottom: '0.5rem',
  },
});

export default NotificationEdit;
