import {
  useCallback, memo,
} from 'react';
import {
  Identifier, useLocaleState, useRecordContext,
} from 'react-admin';
import { createUseStyles } from 'react-jss';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Typography, TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

export type Item = {
  id: Identifier,
  dueDateAt: string,
};

export type DueDateProps = {
  selectedItems: Item[],
  source: string,
  onChange?: (taskId: Identifier, dueDateAt: string) => void,
};

const DueDateMobile = (props: DueDateProps) => {
  const {
    selectedItems,
    onChange,
    source,
  } = props;
  const record = useRecordContext();

  const [locale] = useLocaleState();

  const format = {
    en: 'MM/dd/yyyy',
    he: 'yyyy.MM.dd',
  };

  const value = (selectedItems || []).find((item) => Number(item.id) === record?.id);

  const handleChange = useCallback((newDueDate: any) => {
    if (!value?.id) {
      return;
    }
    onChange?.(value.id, newDueDate);
  }, [onChange, value]);


  // if (!value) {
  //   return null;
  // }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        onChange={handleChange}
        disabled={!value}
        readOnly
        inputFormat={format[locale as 'en' | 'he']}
        value={value?.dueDateAt}
        InputProps={{ disableUnderline: true }}
        renderInput={(params) => (
          <Typography color={!value ? '#CCCCCC' : '#212121'} fontSize={15}>
            Due Date
            :&nbsp;
            {!value && (
              <TextField
                margin="none"
                variant="standard"
                InputProps={{ disableUnderline: true, value: 'unavailable' }}
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: 'black',
                  },
                  '.MuiInputBase-root': { width: '6rem', color: '#1976D2' },
                  '.MuiInputBase-input': {
                    padding: 0, color: '#CCCCCC', fontSize: 15, fontWeight: 700,
                  },
                }}
              />
            )}
            {value && (
              <TextField
                margin="none"
                variant="standard"
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: 'black',
                  },
                  '.MuiInputBase-root': { width: '6rem', color: '#1976D2' },
                  '.MuiInputBase-input': {
                    padding: 0, color: '#212121', fontSize: 15, fontWeight: 700,
                  },
                }}
                {...params}
              />
            )}
          </Typography>
        )}
      />
    </LocalizationProvider>
  );
};

const useStyles = createUseStyles({
  button: {
    '&': {
      textTransform: 'none',
    },
    '& .MuiButton-endIcon': {
      transition: 'opacity 300ms ease',
      opacity: 0,
    },
    '&:hover .MuiButton-endIcon': {
      opacity: 1,
    },
  },
});

export default memo(DueDateMobile);
