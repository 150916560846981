import { memo, MouseEvent, useCallback } from 'react';
import {
  useTranslate,
  List,
  TextField,
  FunctionField,
  CreateButton, EditButton, useRedirect,
} from 'react-admin';
import { createUseStyles } from 'react-jss';
import {
  Typography, Card, CardHeader, CardContent,
} from '@mui/material';

import { Client } from 'interfaces/entities';

import EmptyList from 'components/EmptyList';
import SearchInput from 'components/SearchInput';
import MobileGrid from 'components/MobileGrid';

import ListActions from '../elements/ListActions';
import TaskInfoChip from '../elements/TaskInfoChip';

const filters = [
  <SearchInput
    label="resources.taskType.action.search"
    source="q"
    alwaysOn
  />,
];

const MobileLayout = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const redirect = useRedirect();

  const handleRowClick = useCallback((event: MouseEvent<HTMLDivElement>, record: Client) => {
    if (!record.id) {
      return;
    }
    redirect('show', 'Client', record.id);
  }, [redirect]);

  return (
    <List
      {...props}
      filters={filters}
      sx={{
        '&': {
          marginRight: '-8px',
        },
        '& .MuiToolbar-root': {
          background: 'transparent',
        },
        '& > div > .MuiPaper-root': {
          boxShadow: 'none',
          background: 'transparent',
        },
      }}
      actions={<CreateButton />}
      sort={{ field: 'id', order: 'desc' }}
      empty={<EmptyList body="Create a new client and append tasks to him" toolbar={<ListActions />} />}
      title={translate('resources.client.name')}
    >
      <MobileGrid onRowClick={handleRowClick}>
        <Card sx={{ margin: '0.6rem 0' }}>
          <CardHeader
            title={(
              <FunctionField
                source="firstName"
                label="resources.client.fields.clientName"
                render={(record: Client) => {
                  return (
                    <div>
                      <Typography fontWeight={500}>{`${record.firstName || ''} ${record.lastName || ''}`}</Typography>
                    </div>
                  );
                }}
              />
            )}
            titleTypographyProps={{ variant: 'body1' }}
            sx={{ pt: 0.5, pb: 0 }}
            action={<EditButton />}
          />
          <CardContent sx={{ pt: 0, '&:last-child': { pb: '1rem' } }}>
            <Typography variant="body2">
              {translate('resources.client.fields.totalTasks')}
              :&nbsp;
              <TextField emptyText="0" source="numberOfTasksTotal" />
            </Typography>
            <Typography variant="body2">
              {translate('resources.client.fields.tasksStillOutstanding')}
              :&nbsp;
              <TextField emptyText="0" source="numberOfTasksOutstanding" />
            </Typography>
            <FunctionField
              source="firstName"
              label="resources.client.fields.clientName"
              render={(record: Client) => (
                <TaskInfoChip record={record} size="small" mv={1} />
              )}
            />
            <FunctionField
              source="notes"
              render={(record: any, source?: string) => {
                if (!source) {
                  return null;
                }
                const value = record[source];
                if (!value) {
                  return null;
                }
                return (
                  <Typography variant="body2" gutterBottom>
                    {`${translate('resources.client.fields.notes')}: ${value}`}
                  </Typography>
                );
              }}
            />
          </CardContent>
        </Card>
      </MobileGrid>
    </List>
  );
};

const useStyles = createUseStyles({
  list: {
    '&': {
      marginRight: '-8px',
    },
    '& .MuiToolbar-root': {
      background: 'transparent',
    },
    '& > div > .MuiPaper-root': {
      boxShadow: 'none',
      background: 'transparent',
    },
  },
});

export default memo(MobileLayout);
