import { gql } from 'services/dataProvider';

export const fields = gql`
  fragment TagFields on Tag {
    id
    name
    createdAt
    updatedAt
    deletedAt
  }
`;
