import type { FC } from 'react';
import { memo } from 'react';
import { createUseStyles } from 'react-jss';

import type { ItemComponentProps } from 'components/FileInput';
import DocumentPreview from 'components/DocumentPreview';

interface DocumentItemProps extends Omit<ItemComponentProps, 'data'> {
  data?: {
    id?: number,
    thumbnail?: string,
  } & ItemComponentProps['data']
}

const DocumentItem: FC<DocumentItemProps> = (props) => {
  const { data, onRemove } = props;
  const classes = useStyles();

  if (!data?.url && !data?.id) {
    return null;
  }

  if (!data?.url) {
    return (
      <div className={classes.item}>
        <DocumentPreview
          hasImageName={false}
          onRemove={onRemove}
          url=""
          name="Unavailable Document"
          aspectRatio="192 / 160"
        />
      </div>
    );
  }

  return (
    <div className={classes.item}>
      <DocumentPreview
        hasImageName={false}
        onRemove={onRemove}
        url={data.url}
        name={data.name}
        thumbnail={data.thumbnail}
        aspectRatio="192 / 160"
      />
    </div>
  );
};

const useStyles = createUseStyles({
  item: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '12rem',
  },
});

export default memo(DocumentItem);
