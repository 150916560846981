import { gql } from 'services/dataProvider';

export const fields = gql`
  fragment SettingFields on Setting {
    id
    lastName
    firstName
    companyLogo {
      id
      name
      thumbnail
      url
    }
    companyName
    companyWebsite
    email
    phone
    profilePhoto {
      id
      name
      thumbnail
      url
    }
  }
`;
