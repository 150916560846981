import {
  useState, useCallback, useEffect, SetStateAction, memo,
} from 'react';
import {
  Notification,
  useNotify,
  useLogout,
} from 'react-admin';

import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  Button,
  Card,
  CardActions,
  CircularProgress,
  Typography,
  TextField,
} from '@mui/material';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { createUseStyles } from 'react-jss';

import { useAuthProvider, useIdentity } from 'services/AuthProvider/hooks';
import { ReactComponent as Logo } from 'assets/images/logo.svg';


import CodeInput from './CodeInput';

const Login = () => {
  const classes = useStyles();
  const authProvider = useAuthProvider();
  const identity = useIdentity();
  const notify = useNotify();
  const location = useLocation();
  const navigate = useNavigate();
  const logout = useLogout();

  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<'/preparing' | '/login' | '/code' | '/onboard' | '/hide'>();
  const [phone, setPhone] = useState<string>(''); // 17186834668
  const [code, setCode] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [companyWebsite, setCompanyWebsite] = useState<string>('');

  useEffect(() => {
    setStep(location?.pathname as any || '/preparing');
  }, [location.pathname]);

  const handlePhoneChange = useCallback((value: SetStateAction<string>) => {
    setPhone(value);
  }, []);

  const handleCodeChange = useCallback((value: SetStateAction<string>) => {
    setCode(value);
  }, []);

  const handleCompanyName = useCallback((event: { target: { value: SetStateAction<string>; }; }) => {
    setCompanyName(event.target?.value);
  }, []);

  const handleFirstName = useCallback((event: { target: { value: SetStateAction<string>; }; }) => {
    setFirstName(event.target?.value);
  }, []);

  const handleLastName = useCallback((event: { target: { value: SetStateAction<string>; }; }) => {
    setLastName(event.target?.value);
  }, []);

  const handleEmail = useCallback((event: { target: { value: SetStateAction<string>; }; }) => {
    setEmail(event.target?.value);
  }, []);

  const handleCompanyWebsite = useCallback((event: { target: { value: SetStateAction<string>; }; }) => {
    setCompanyWebsite(event.target?.value);
  }, []);

  const handleRequestCode = useCallback(() => {
    setLoading(true);
    authProvider.requestCode({ phone }).then((response) => {
      if (response.error) {
        setLoading(false);
        notify(response.error, { type: 'warning' });
        return;
      }
      navigate('/code');
      setLoading(false);
    });
  }, [authProvider, navigate, notify, phone]);

  const handleLogin = useCallback(() => {
    setLoading(true);
    authProvider.login({ code }).then((response) => {
      setLoading(false);
      if (response.error) {
        notify(response.error, { type: 'warning' });
        return;
      }
      if (!response?.data?.identity.didCompleteRegistration) {
        navigate('/onboard');
        return;
      }
      if (/^\/login|code|onboard/.test(location?.pathname || '')) {
        navigate('/Task');
      }
    });
  }, [authProvider, code, location?.pathname, navigate, notify]);

  const handleOnboard = useCallback(() => {
    setLoading(true);
    const params = {
      companyName,
      email,
      firstName,
      lastName,
      companyWebsite,
    };
    authProvider.onboard(params).then((response) => {
      setLoading(false);
      if (response.error) {
        notify(response.error, { type: 'warning' });
      }
      if (/^\/login|code|onboard/.test(location?.pathname || '')) {
        navigate('/Task');
      }
    });
  }, [authProvider, companyName, companyWebsite, email, firstName, lastName, location?.pathname, navigate, notify]);

  const handleLogout = useCallback(() => logout(null, '', false), [
    logout,
  ]);

  useEffect(() => {
    if (code.length === 6) {
      handleLogin?.();
    }
  }, [code.length, handleLogin]);

  useEffect(() => {
    if (!identity.loading && !identity.user?.id) {
      setPhone('');
      setCode('');
      return;
    }
    if (identity.user?.id && (!identity.user?.email || !identity.user?.company?.name)) {
      setCompanyName('');
      setEmail('');
    }
  }, [identity.user?.company?.name, identity.user?.email, identity.user?.id, identity.loading]);

  const isStepPreparing = step === '/preparing';
  const isStepLogin = step === '/login';
  const isStepCode = step === '/code';
  const isStepOnboard = step === '/onboard';
  const isStepHide = step === '/hide';

  if (isStepHide) {
    return null;
  }

  return (
    <Card className={classes.main}>
      <Card className={classes.panel} elevation={10}>
        <div className={classes.company}>
          <div><Logo /></div>
          <Typography component="div">DocsToDo</Typography>
        </div>
        <div className={classes.form}>
          {isStepPreparing && (
            <div className={classes.preparing}>
              <CircularProgress
                size={50}
                thickness={2}
              />
            </div>
          )}
          {isStepLogin && (
            <>
              <Typography component="div" variant="caption" className={classes.typographyDiv}>Enter phone number</Typography>
              <PhoneInput
                value={phone}
                enableLongNumbers={17}
                onChange={handlePhoneChange}
                autoFormat
                disableDropdown
                inputStyle={{
                  width: '100%',
                }}
              />
            </>
          )}
          {isStepCode && (
            <>
              <Typography component="div" variant="caption" className={classes.typographyDiv}>Enter code</Typography>
              <CodeInput
                value={code}
                onChange={handleCodeChange}
              />
            </>
          )}
          {isStepOnboard && (
            <div className={classes.onboard}>
              <TextField
                label="First Name"
                value={firstName}
                onChange={handleFirstName}
              />
              <TextField
                label="Last Name"
                value={lastName}
                onChange={handleLastName}
              />
              <TextField
                label="E-Mail"
                value={email}
                onChange={handleEmail}
              />
              <TextField
                label="Company Name"
                value={companyName}
                onChange={handleCompanyName}
              />
              <TextField
                label="Company Website"
                value={companyWebsite}
                onChange={handleCompanyWebsite}
              />
              <Typography component="div" className={classes.terms}>
                By signing up, you agree to our
                {' '}
                <a style={{ color: '#2D67BD' }} href="https://doc.documentstodo.com/terms-of-service.html" target="_blank" rel="noreferrer">Terms of Service</a>
              </Typography>
              <Typography component="div" className={classes.policy}>
                and acknowledge that you read our
                {' '}
                <a style={{ color: '#2D67BD' }} href="https://doc.documentstodo.com/privacy-policy.html" target="_blank" rel="noreferrer">Privacy Policy</a>
              </Typography>
            </div>
          )}
        </div>
        <CardActions className={classes.actions}>
          {step === '/login' && (
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
              onClick={handleRequestCode}
              size="large"
              className={classes.button}
            >
              GET CODE
            </Button>
          )}
          {step === '/code' && (
            <div className={classes.toolbar}>
              <Button
                variant="outlined"
                onClick={handleLogout}
                disabled={loading}
                size="large"
                className={classes.logoutBtn}
              >
                Back
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                onClick={handleLogin}
                size="large"
                className={classes.button}
              >
                Next
              </Button>
            </div>
          )}
          {step === '/onboard' && (
            <div className={classes.toolbar}>
              <Button
                variant="outlined"
                onClick={handleLogout}
                disabled={loading}
                size="large"
                className={classes.logoutBtn}
                sx={{ flex: 1, marginRight: '0.5rem' }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                onClick={handleOnboard}
                size="large"
                className={classes.button}
                sx={{ flex: 1, marginLeft: '0.5rem' }}
              >
                Next
              </Button>
            </div>
          )}
        </CardActions>
      </Card>
      <Notification />
    </Card>
  );
};

const useStyles = createUseStyles({
  logoutBtn: {
    '&.MuiButton-outlined': {
      borderRadius: '0.5rem',
      boxShadow: 'none',
      padding: '0.3125rem 0.9375rem',
    },
    '&.MuiButton-outlined.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
      opacity: 0.6,
    },
    '&.MuiButton-outlined:hover': {
    },
  },
  button: {
    '&.MuiButton-contained': {
      backgroundColor: '#2D67BD',
      borderRadius: '0.5rem',
      boxShadow: 'none',
      padding: '0.3125rem 0.9375rem',
    },
    '&.MuiButton-contained.Mui-disabled': {
      color: '#ffffff',
      opacity: 0.6,
    },
    '&.MuiButton-contained:hover': {
      backgroundColor: '#3D7CDA',
      boxShadow: 'none',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  main: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    top: 0,
    height: '100vh',
    width: '100vw',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    zIndex: 100,
    backgroundColor: '#3D7CDA',
  },
  panel: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 335,
    borderRadius: '1rem',
  },
  company: {
    '&': {
      flex: 1,
    },
    '& > div:first-child': {
      marginTop: '3rem',
      display: 'flex',
      justifyContent: 'center',
    },
    '& > div:last-child': {
      marginTop: '1rem',
      display: 'flex',
      justifyContent: 'center',
      fontSize: '1.5rem',
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: '1.875rem',
    },
  },
  preparing: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '2rem',
  },
  form: {
    overflow: 'auto',
    padding: '0 2rem',
    marginTop: '1rem',
  },
  onboard: {
    '&': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& > div': {
      marginTop: '1rem',
    },
  },
  actions: {
    padding: '2rem 2rem 2rem 2rem',
    borderRadius: '1rem',
  },
  typographyDiv: {
    marginBottom: '0.5rem',
  },
  terms: {
    marginTop: '0.25rem',
    fontSize: '0.75rem',
  },
  policy: {
    marginTop: '0!important',
    fontSize: '0.75rem',
  },
});

export default memo(Login);
