import { gql } from 'services/dataProvider';

export const fields = gql`
  fragment NotificationFields on Notification {
    id
    shouldNotifyWhenAllTasksSubmittedByClient
    shouldNotifyWhenEachUploadedTask
    countDaysAfterPastDueForTasks

    shouldNotifyNewTaskWasAdded
    shouldNotifyTaskWasDenied
    shouldNotifyWhenAllApproved
    countDaysBeforeDueDate
    shouldNotifyViaSMS
    shouldNotifyViaEmail
    shouldNotifyViaPush
  }
`;
