import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { GetOneResult, GetOneParams, GetOneProvider } from './types';

export type { GetOneResult, GetOneParams, GetOneProvider };

export default async (client: ApolloClient<NormalizedCacheObject>, provider: GetOneProvider, resource: string, params: GetOneParams): Promise<GetOneResult<any>> => {
  const { parser, ...clientArgs } = provider(params);
  const { data, error } = await client.query({
    fetchPolicy: 'no-cache',
    ...clientArgs,
  });

  if (error) {
    return Promise.reject(error.graphQLErrors[0]);
  }

  if (!data) {
    return Promise.reject(new Error('Not data found'));
  }

  if (parser) {
    return parser(data);
  }

  const firstNode = data[Object.keys(data)[0]];

  return Promise.resolve({
    data: firstNode.one,
  });
};
