import { gql, GetListProvider } from 'services/dataProvider';
import { Tag } from 'interfaces/entities';

import { fields as TagFields } from '../fragments';

const provider: GetListProvider<'tag', Tag[]> = (params) => {
  const query = gql`
    ${TagFields}
    query(
      $pagination: ListPaginationPayload
      $sort: ListSortPayload
      $filter: TagManyFilter
    ) {
      tag {
        many(
          pagination: $pagination
          sort: $sort
          filter: $filter
        ) {
            ...TagFields
          }
        }
      }
  `;

  return {
    query,
    variables: {
      pagination: params.pagination,
      sort: params.sort,
      filter: params.filter,
    },
  };
};

export default provider;
