import { FC, memo } from 'react';
import {
  Edit, SimpleForm, TextInput, ArrayInput, SimpleFormIterator, SelectInput, useTranslate,
} from 'react-admin';

import FileInput from 'components/FileInput';

import ImageItem from './elements/ImageItem';
import ImageEmpty from './elements/ImageEmpty';
import Toolbar from './elements/Toolbar';
import CheckIsOngoing from '../Task/elements/checkIsOngoing';

const TaskTypeEdit: FC = (props) => {
  const translate = useTranslate();

  return (
    <Edit
      sx={{
        '&': {
          marginTop: '-1rem',
          marginRight: '-10px',
          marginLeft: '-3px',
        },
        '& > div > .MuiPaper-root': {
          boxShadow: 'none',
        },
      }}
      {...props}
    >
      <SimpleForm toolbar={<Toolbar />}>
        <TextInput label="resources.taskType.fields.name" source="name" variant="standard" fullWidth />
        <TextInput label="resources.taskType.fields.description" source="description" variant="standard" fullWidth />
        <div>
          <FileInput
            source="example"
            label="resources.taskType.fields.example"
            accept={['image/jpeg', 'image/png', 'image/webp', 'image/tiff', 'image/jpg']}
            itemComponent={<ImageItem />}
            emptyComponent={<ImageEmpty />}
          />
          <div>
            <CheckIsOngoing source="isOngoing" label="resources.taskType.fields.repeatTask" />
            <SelectInput
              label="resources.taskType.fields.priorityLevel"
              source="priorityLevel"
              choices={[
                { id: '1', name: translate('resources.taskType.fields.low') },
                { id: '2', name: translate('resources.taskType.fields.medium') },
                { id: '3', name: translate('resources.taskType.fields.high') },
              ]}
              variant="standard"
              defaultValue={2}
              fullWidth
            />
            <div className="tags">
              <ArrayInput label="resources.taskType.fields.tags" source="tags" variant="standard">
                <SimpleFormIterator disableReordering>
                  <TextInput
                    source="name"
                    label=""
                    InputProps={{
                      className: 'input',
                      disableUnderline: true,
                    }}
                    FormHelperTextProps={{
                      className: 'helper',
                    }}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default memo(TaskTypeEdit);
