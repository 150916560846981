import { FC, memo } from 'react';
import { createUseStyles } from 'react-jss';
import { Typography } from '@mui/material';

import { ReactComponent as IconFile } from '../assets/icon-file.svg';

export interface AddComponentProps {
}

const DefaultAddComponent: FC<AddComponentProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.add}>
      <div className={classes.icon}>
        <IconFile />
      </div>
      <Typography className={classes.text} variant="caption" component="div">
        drop file here
        <br />
        to upload
      </Typography>
    </div>
  );
};

const useStyles = createUseStyles({
  add: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    border: '1px solid #606B7C',
    borderRadius: '0.5rem',
    padding: 0,
    width: '8rem',
    height: '8rem',
  },
  icon: {
    '&': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '2.4rem',
    },
    '& svg': {
      height: '100%',
    },
  },
  loading: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.625rem',
  },
  text: {
    marginTop: '0.6rem',
  },
});

export default memo(DefaultAddComponent);
