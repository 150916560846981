import {
  memo, useCallback, useEffect, useState,
} from 'react';
import {
  useTranslate, Menu as RAMenu, Logout, useSidebarState,
} from 'react-admin';
import { createUseStyles } from 'react-jss';
import {
  CardContent, Divider, Theme, useMediaQuery,
} from '@mui/material';

import Icon from 'components/Icon';

import { useIdentity } from 'services/AuthProvider/hooks';

import AuthUserSideBar from './elements/AuthUserSideBar';
import LocaleSwitcher from './elements/LocaleSwitcher';

const Menu = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const identity = useIdentity();
  const [open, setOpen] = useSidebarState();

  const isSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

  const handleWindowResize = useCallback(() => {
    setWindowHeight(window.innerHeight);
  }, []);

  const isUSNumber = (number: any) => {
    const pattern = /^\+1[2-9]\d{2}[2-9]\d{2}\d{4}$/;
    return pattern.test(number);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <RAMenu className={classes.container}>
      {/* <DashboardMenuItem /> */}
      {isSm && (
        <>
          <AuthUserSideBar
            firstName={identity?.user?.firstName || ''}
            lastName={identity?.user?.lastName || ''}
            company={identity?.user?.company?.name || ''}
            loading={identity?.loading}
            profilePhotoUrl={identity?.user?.avatarUrl || ''}
            className={classes.identity}
          />
          <RAMenu.Item
            className={classes.item}
            to="/Setting/1"
            primaryText={translate('resources.setting.fields.setting').replace(/.*\|/, '')}
            leftIcon={<Icon name="SettingsIcon" htmlColor="#7F8896" fontSize="medium" />}
          />
          <Divider className={classes.divider1} />
        </>
      )}
      <RAMenu.Item
        disableRipple
        className={classes.item}
        to="/Task"
        primaryText={translate('resources.task.name').replace(/.*\|/, '')}
        leftIcon={<Icon name="TasksIcon" htmlColor="#7F8896" fontSize="medium" />}
      />
      <RAMenu.Item
        disableRipple
        className={classes.item}
        to="/Client"
        primaryText={translate('resources.client.name').replace(/.*\|/, '')}
        leftIcon={<Icon name="ClientsIcon" htmlColor="#7F8896" fontSize="medium" />}
      />
      <RAMenu.Item
        disableRipple
        className={classes.item}
        to="/TaskType"
        primaryText={translate('resources.taskType.name').replace(/.*\|/, '')}
        leftIcon={<Icon name="TaskPoolIcon" htmlColor="#7F8896" fontSize="medium" />}
      />
      <RAMenu.Item
        disableRipple
        className={classes.item}
        to="/CompanyAdmin"
        primaryText={translate('resources.companyAdmin.name').replace(/.*\|/, '')}
        leftIcon={<Icon name="MembersIcon" htmlColor="#7F8896" fontSize="medium" />}
      />
      {isSm && (
        <>
          <Divider className={classes.divider2} />
          <Logout
            className={classes.item}
            icon={<Icon name="LogoutIcon" htmlColor="#7F8896" fontSize="medium" />}
          />
        </>
      )}
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: open ? 'row-reverse' : 'column',
          position: 'fixed',
          left: 0,
          top: windowHeight - (open ? 80 : 124),
          padding: '1rem 0.75rem 0',
          '&:last-child': { paddingBottom: 0 },
        }}
      >
        {!isUSNumber(identity.user?.phone) && (<LocaleSwitcher />)}

      </CardContent>
    </RAMenu>
  );
};

const useStyles = createUseStyles({
  container: {
    flex: 1,
    marginBottom: 0,
  },
  item: {
    '&': {
      fontWeight: 400,
      color: '#2B2F36',
      marginBottom: '0.5rem',
      transition: 'color 900ms ease',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#3D7CDA',
      transitionDuration: '200ms',
    },
    '& svg': {
      fill: '#454950',
      transition: 'fill 900ms ease',
    },
    '&:hover svg': {
      fill: '#3D7CDA',
      transitionDuration: '200ms',
    },
  },
  identity: {
    margin: '1rem 1rem 1rem',
  },
  divider1: {
    marginBottom: '1rem',
  },
  divider2: {
    marginTop: '0.5rem',
    marginBottom: '1rem',
  },
  itemActive: {
    '&': {
      fontWeight: 500,
      color: '#2D67BD',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      fill: '#2D67BD',
      marginBottom: '1px',
    },
  },
});

export default memo(Menu);
