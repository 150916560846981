import React, { memo } from 'react';
import {
  useUpdate, useNotify,
} from 'react-admin';
import { Button } from '@mui/material';
import { type OverridableStringUnion } from '@mui/types';
import { useNavigate } from 'react-router-dom';

interface ActionButtonProps {
  record?: {
    id: number | string,
    status?: 'to_do' | 'approved',
    client?: {
      id: number | string,
    },
  },
  action: 'approve' | 'decline' | 'restore' | 'archive' | 'finalize',
  label: string,
  variant?: 'text' | 'outlined' | 'contained',
  color?: OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'>
}

const ActionButton: React.FC<ActionButtonProps> = (props) => {
  const {
    record, action, label, variant, color,
  } = props;
  const notify = useNotify();
  const navigate = useNavigate();
  const [update, { isLoading }] = useUpdate(
    'Task',
    { id: record?.id, data: { state: action, id: record?.id } },
    {
      onSuccess: () => {
        notify('Task updated');
        navigate(-1);
      },
      onError: (error) => notify(`Task not updated error: ${error}`, { type: 'warning' }),
    },
  );

  const handleClick = () => {
    update();
  };

  let isDisabled = false;
  if (action === 'approve' && record?.status === 'approved') {
    isDisabled = true;
  }
  if (action === 'finalize' && record?.status === 'approved') {
    isDisabled = true;
  }
  if (action === 'decline' && record?.status === 'to_do') {
    isDisabled = true;
  }

  return (
    <Button
      onClick={handleClick}
      disabled={isLoading || isDisabled}
      variant={variant}
      color={color}
    >
      {label}
    </Button>
  );
};

export default memo(ActionButton);
