import { Resource } from 'react-admin';

import getList from './graphql/providers/getList';

export default <Resource
  name="Tag"
  options={{
    providers: {
      getList,
    },
  }}
/>;
