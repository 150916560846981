import {
  type ImgHTMLAttributes,
  useEffect, useRef, useState, memo,
} from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';

const fetchWithAuthentication = (url: string, authToken?: string) => {
  const headers = new Headers();
  if (authToken) {
    headers.set('Authorization', authToken);
  }
  return fetch(url, {
    headers,
    credentials: 'include',
  });
};

const loadImage = async (img: HTMLImageElement, src: string, authToken?: string) => {
  const response = await fetchWithAuthentication(src, authToken);

  const blob = await response.blob();
  const objectUrl = URL.createObjectURL(blob);

  return new Promise((resolve, reject) => {
    img.src = objectUrl;
    img.onload = () => {
      URL.revokeObjectURL(objectUrl);
      resolve(null);
    };
    img.onerror = () => {
      URL.revokeObjectURL(objectUrl);
      reject();
    };
  });
};

const Image = (props: ImgHTMLAttributes<any>) => {
  const {
    src, srcSet, alt, className, ...otherProps
  } = props;

  const classes = useStyles();

  const [status, setStatus] = useState<string>('empty');
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const { current: img } = imgRef;
    if (!img) {
      return;
    }
    if (!src) {
      img.src = '';
      setStatus('empty');
      return;
    }
    if (/amazonaws\.com/.test(src)) {
      img.src = src;
      setStatus('loaded');
      return;
    }
    setStatus('loading');
    loadImage(img, src).then(() => { setStatus('loaded'); }).catch(() => { setStatus('error'); });
  }, [src]);

  return (
    <img
      {...otherProps}
      ref={imgRef}
      alt={alt}
      className={cn(classes.img, className, {
        [classes.empty]: status === 'empty',
        [classes.loading]: status === 'loading',
        [classes.error]: status === 'error',
        [classes.loaded]: status === 'loaded',
      })}
    />
  );
};

const useStyles = createUseStyles({
  img: {},
  empty: {
    opacity: 0,
  },
  loading: {
    opacity: 0,
  },
  error: {
    opacity: 0,
  },
  loaded: {
    opacity: 1,
  },
});

export default memo(Image);
