import {
  Link as MuiLink, Card, CardContent, Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
  useCreatePath,
  TextField,
  useRecordContext, SelectField, ArrayField, SingleFieldList, ChipField, useTranslate,
} from 'react-admin';

export const TaskTypeItem = () => {
  const record = useRecordContext();
  const createPath = useCreatePath();
  const translate = useTranslate();
  if (!record) {
    return null;
  }
  return (
    <MuiLink
      to={createPath({
        resource: 'TaskType',
        type: 'edit',
        id: record.id,
      })}
      component={Link}
      underline="none"
      color="inherit"
    >
      <Card sx={{ margin: '0.5rem 0' }}>
        <CardContent sx={{ '&:last-child': { pb: '1rem' } }}>
          <Typography variant="body2" gutterBottom>
            {translate('resources.taskType.fields.name')}
            :&nbsp;
            <TextField label={translate('resources.taskType.fields.name')} source="name" />
          </Typography>
          <Typography variant="body2" gutterBottom>
            {translate('resources.taskType.fields.description')}
            :&nbsp;
            <TextField source="description" />
          </Typography>
          <Typography variant="body2" gutterBottom>
            {translate('resources.taskType.fields.priorityLevel')}
            :&nbsp;
            <SelectField
              source="priorityLevel"
              choices={[
                { id: '1', name: translate('resources.taskType.fields.low') },
                { id: '2', name: translate('resources.taskType.fields.medium') },
                { id: '3', name: translate('resources.taskType.fields.high') },
              ]}
            />
          </Typography>
          <Typography variant="body2" gutterBottom>
            {translate('resources.taskType.fields.tags')}
            :&nbsp;
            <ArrayField source="tags" label="resources.taskType.fields.tags" sortable={false}>
              <SingleFieldList sx={{ marginTop: '0.5rem' }}>
                <ChipField source="name" clickable={false} />
              </SingleFieldList>
            </ArrayField>
          </Typography>
        </CardContent>
      </Card>
    </MuiLink>
  );
};
