import {
  memo,
  useCallback, useEffect, useRef,
} from 'react';
import type { FC } from 'react';
import { Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';

interface CodeInputProps {
  value: string,
  onChange: (value: string) => void,
}

const CodeInput: FC<CodeInputProps> = (props) => {
  const {
    value,
    onChange,
  } = props;
  const classes = useStyles();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const { current } = inputRef;
    if (!current) {
      return;
    }
    current.focus();
  }, []);

  const handleFocus = useCallback(() => {
    const { current } = inputRef;
    if (!current) {
      return;
    }
    current.focus();
  }, []);

  const handleKeyPress = useCallback((event: any) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }, []);

  const handleChange = useCallback((event: any) => {
    onChange?.(event.target.value.replace(/[^0-9]+/, '').substring(0, 6));
  }, [onChange]);

  const finalValue = (value || '').replace(/[^0-9]+/, '').substring(0, 6);
  const letters = finalValue.split('');

  return (
    <div role="none" onClick={handleFocus} className={classes.codeInput}>
      <input
        ref={inputRef}
        onKeyPress={handleKeyPress}
        onChange={handleChange}
        value={finalValue}
        inputMode="numeric"
      />
      <Typography component="div">
        {letters?.[0]}
        {letters.length === 0 && <span>|</span>}
      </Typography>
      <Typography component="div">
        {letters?.[1]}
        {letters.length === 1 && <span>|</span>}
      </Typography>
      <Typography component="div">
        {letters?.[2]}
        {letters.length === 2 && <span>|</span>}
      </Typography>
      <Typography component="div">
        {letters?.[3]}
        {letters.length === 3 && <span>|</span>}
      </Typography>
      <Typography component="div">
        {letters?.[4]}
        {letters.length === 4 && <span>|</span>}
      </Typography>
      <Typography component="div">
        {letters?.[5]}
        {letters.length === 5 && <span>|</span>}
      </Typography>
    </div>
  );
};

const useStyles = createUseStyles({
  '@keyframes cursor': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 1,
    },
    '51%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0,
    },
  },
  codeInput: {
    '&': {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& input': {
      position: 'absolute',
      opacity: 0,
      cursor: 'default',
    },
    '& div': {
      display: 'flex',
      position: 'relative',
      height: 33,
      width: 30,
      alignItems: 'center',
      justifyContent: 'center',
      background: '#ffffff',
      border: '1px solid #cacaca',
      borderRadius: 5,
    },
    '& div span': {
      animation: '1.2s linear infinite',
      animationName: '$cursor',
      color: '#555555',
    },
  },
});

export default memo(CodeInput);
