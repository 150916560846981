import { memo, type PropsWithChildren, useMemo } from 'react';
import { useRecordContext } from 'ra-core';
import get from 'lodash/get';
import { Typography } from '@mui/material';
import { TextField, useLocaleState } from 'react-admin';
import { DateTime } from 'luxon';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';

interface DateFieldCustomProps {
  source: string,
  label?: string,
  emptyText?: string,
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>,
  hidden?: boolean,
  colorEmptyText?: string,
}

const DateFieldCustom = (props: PropsWithChildren<DateFieldCustomProps>) => {
  const {
    variant,
    source,
    label,
    emptyText,
    hidden,
    colorEmptyText,
  } = props;

  const [locale] = useLocaleState();
  const record = useRecordContext(props);

  const date = useMemo(() => {
    const value = !record ? null : get(record, source);
    if (!value) {
      return null;
    }
    const format = {
      en: 'LL/dd/yyyy',
      he: 'yyyy.LL.dd',
    };
    return DateTime.fromISO(value).toFormat(format[locale as 'en' | 'he']);
  }, [record, source, locale]);

  return (
    <Typography hidden={hidden} variant={variant}>
      {label}
      :&nbsp;
      {date || <TextField color={colorEmptyText} emptyText={emptyText} />}
    </Typography>
  );
};

export default memo(DateFieldCustom);
