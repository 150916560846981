import { gql, GetOneProvider } from 'services/dataProvider';

import { fields as NotificationFields } from '../fragments';

const provider: GetOneProvider = (params) => {
  const query = gql`
    ${NotificationFields}
    query(
      $id: Int!
    ) {
      notification {
        one(
          id: $id
        ) {
          ...NotificationFields
        }
      }
    }
  `;
  return {
    query,
    variables: {
      id: Number(params.id),
    },
  };
};

export default provider;
