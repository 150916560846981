import { gql } from 'services/dataProvider';

export const fields = gql`
  fragment CompanyAdminFields on CompanyAdmin {
    id
    firstName
    lastName
    profilePhotoUrl
    phone
    email
    didCompleteRegistration
    status
    role
  }
`;
