import { gql, GetOneProvider } from 'services/dataProvider';

import { fields as SettingFields } from '../fragments';

const provider: GetOneProvider = (params) => {
  const gqlQuery = gql`
    ${SettingFields}
    query(
      $id: Int!
    ) {
      setting {
        one(
          id: $id
        ) {
          ...SettingFields
        }
      }
    }
  `;
  return {
    query: gqlQuery,
    variables: {
      id: Number(params.id),
    },
  };
};

export default provider;
