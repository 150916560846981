import { memo, useState } from 'react';
import type { FC } from 'react';
import {
  Edit, SimpleForm, useTranslate, TextField, FunctionField, BooleanInput,
} from 'react-admin';
import {
  Box,
  InputLabel, Stack, Typography,
} from '@mui/material';
import { createUseStyles } from 'react-jss';


import FileInput from 'components/FileInput';
import DateInputCustom from 'components/DateInputCustom';
import ClientTaskStatus from 'components/ClientTaskStatus';

import UserLink from './UserLink';
import DownloadAll from './DownloadAll';
import ActionsToolbarMobile from './ActionsToolbarMobile';
import DocumentAddMobile from './DocumentAddMobile';
import DocumentItemMobile from './DocumentItemMobile';
import DocumentProgressMobile from './DocumentProgressMobile';
import DocumentEmptyMobile from './DocumentEmptyMobile';


const TaskEditMobile: FC = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [Archived, setArchived] = useState<boolean>(false);

  return (
    <Edit
      sx={{
        '&': {
          marginTop: '-22px',
          marginRight: '-16px',
          marginLeft: '-8px',
        },
        '& > div > .MuiPaper-root': {
          boxShadow: 'none',
          background: 'transparent',
        },
      }}
      {...props}
    >
      <SimpleForm sx={{ padding: '0.5rem' }} toolbar={<ActionsToolbarMobile />}>
        <Box sx={{
          paddingBottom: '1.75rem',
          padding: '0.5rem',
        }}
        >
          <TextField
            sx={{
              fontWeight: 700,
              fontSize: '1.125rem',
              lineHeight: '1.875rem',
            }}
            source="task.name"
            label="resources.task.taskName"
          />
          <FunctionField
            render={(record: { client?: { id: number; firstName: string; lastName: string; } | undefined; }) => (
              <UserLink record={record} />
            )}
          />
        </Box>
        <Box
          sx={{
            paddingTop: '0.5rem',
            paddingLeft: '0.5rem',
            paddingBottom: '1rem',
          }}
        >
          <InputLabel sx={{ paddingBottom: '0.4rem' }} shrink>About task</InputLabel>
          <DateInputCustom source="dueDateAt" name="dueDateAt" />
          <BooleanInput format={(value) => setArchived(value)} sx={{ display: 'none' }} name="isArchived" source="isArchived" />
          <Typography sx={{ display: 'grid', gridTemplateColumns: '0.1fr 1fr' }} variant="body2">
            {translate('resources.task.status')}
            :&nbsp;
            <Stack direction="row">
              <ClientTaskStatus source="status" label={translate('resources.task.status')} />
              <FunctionField
                color="red"
                paddingLeft={0.5}
                paddingRight={0.5}
                label={translate('resources.task.archived')}
                render={(record: { isArchived: boolean; }) => `${record?.isArchived ? '(archived)' : ''}`}
              />
            </Stack>
          </Typography>
        </Box>
        <DownloadAll />
        <InputLabel sx={{ paddingLeft: '0.7rem', paddingBottom: '0.3rem' }} shrink>Documents</InputLabel>
        <FileInput
          dropZoneDisable={Archived}
          classes={{ root: classes.fileInput, container: classes.fileInputContainer }}
          source="documents"
          label={false}
          multiple
          name="documents"
          accept={['image/jpeg', 'image/png', 'image/gif', 'image/tiff', 'application/pdf']}
          progressComponent={<DocumentProgressMobile />}
          addComponent={<DocumentAddMobile />}
          emptyComponent={<DocumentEmptyMobile />}
          itemComponent={<DocumentItemMobile />}
        />
      </SimpleForm>
    </Edit>
  );
};

const useStyles = createUseStyles({
  fileInputContainer: {
    pointerEvents: 'none',
  },
  fileInput: {
    gap: '0.5rem',
    flexWrap: 'wrap',
  },
});

export default memo(TaskEditMobile);
