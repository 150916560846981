import { FC, memo } from 'react';
import {
  Create, SimpleForm, TextInput, required,
} from 'react-admin';
import { createUseStyles } from 'react-jss';

import ToolbarMobileGrid from '../elements/ToolbarMobileGrid';

const ClientCreate: FC = (props) => {
  const classes = useStyles();
  return (
    <Create className={classes.taskTypeMobileStyle} actions={false} {...props}>
      <SimpleForm toolbar={<ToolbarMobileGrid />}>
        <TextInput source="firstName" variant="standard" validate={required()} fullWidth />
        <TextInput source="lastName" variant="standard" validate={required()} fullWidth />
        <TextInput source="email" variant="standard" fullWidth />
        <TextInput source="phone" variant="standard" validate={required()} fullWidth />
        <TextInput source="dateOfBirth" label="D.O.B." variant="standard" fullWidth />
        <TextInput source="addressHouseNumber" label="Address" variant="standard" fullWidth />
        <TextInput source="addressCity" label="City" variant="standard" fullWidth />
        <TextInput source="addressZipCode" label="Zipcode" variant="standard" fullWidth />
        <TextInput source="addressState" label="State" variant="standard" fullWidth />
        <TextInput source="notes" variant="standard" multiline fullWidth />
      </SimpleForm>
    </Create>
  );
};

const useStyles = createUseStyles({
  taskTypeMobileStyle: {
    '&': {
      marginRight: '-10px',
      marginLeft: '-3px',
    },
    '& > div > .MuiPaper-root': {
      boxShadow: 'none',
    },
  },
});

export default memo(ClientCreate);
