import { gql, GetListProvider } from 'services/dataProvider';
import { ClientTask } from 'interfaces/entities';

import { fields as ClientTaskFields } from '../fragments';

const provider: GetListProvider<'task', ClientTask[]> = (params) => {
  const query = gql`
    ${ClientTaskFields}
    query(
      $pagination: ListPaginationPayload
      $sort: ListSortPayload
      $filter: ClientTaskManyFilter
    ) {
      clientTask {
        many(
          pagination: $pagination
          sort: $sort
          filter: $filter
        ) {
          items {
            ...ClientTaskFields
          }
          pageInfo {
            page
            perPage
            totalItems
            totalPages
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }
  `;
  return {
    query,
    variables: {
      pagination: params.pagination,
      sort: params.sort,
      filter: params.filter,
    },
  };
};

export default provider;
