import { memo } from 'react';
import { Identifier } from 'react-admin';

import { Theme, useMediaQuery } from '@mui/material';

import DesktopLayout from './layouts/DesktopLayout';
import MobileTasksField from './layouts/MobileTasksField';

type TasksFieldProps = {
  clientId: Identifier,
  perPage?: number,
  hasPagination?: boolean,
  clientName?: string,
};

const TasksField = (props: TasksFieldProps) => {
  const {
    clientName,
    clientId,
    hasPagination,
    perPage,
  } = props;

  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  if (!clientId) {
    return null;
  }

  return (
    <>
      {!isXSmall && (
        <DesktopLayout clientName={clientName} clientId={clientId} hasPagination={hasPagination} perPage={perPage} />
      )}
      {isXSmall && (
        <MobileTasksField clientName={clientName} clientId={clientId} hasPagination={hasPagination} perPage={perPage} />
      )}
    </>
  );
};

export default memo(TasksField);
