import * as React from 'react';
import { FC } from 'react';

import { SvgIcon } from '@mui/material';

import { ReactComponent as ChatBubbleCode } from './assets/ChatBubbleCode.svg';
import { ReactComponent as FileSent } from './assets/FileSent.svg';
import { ReactComponent as DownloadFill } from './assets/DownloadFill.svg';
import { ReactComponent as DownloadOutline } from './assets/DownloadOutline.svg';
import { ReactComponent as ClientsIcon } from './assets/ClientsIcon.svg';
import { ReactComponent as LogoutIcon } from './assets/LogoutIcon.svg';
import { ReactComponent as NotificationsIcon } from './assets/NotificationsIcon.svg';
import { ReactComponent as SettingsIcon } from './assets/ProfileIcon.svg';
import { ReactComponent as TaskPoolIcon } from './assets/TaskPoolIcon.svg';
import { ReactComponent as TasksIcon } from './assets/TasksIcon.svg';
import { ReactComponent as MembersIcon } from './assets/CpnmmbrsIcon.svg';
import { ReactComponent as DeletePreviewDocumentsIcon } from './assets/DeletePreviewDocumentsIcon.svg';

const map = {
  ChatBubbleCode,
  FileSent,
  DownloadFill,
  DownloadOutline,
  ClientsIcon,
  LogoutIcon,
  NotificationsIcon,
  SettingsIcon,
  TaskPoolIcon,
  TasksIcon,
  MembersIcon,
  DeletePreviewDocumentsIcon,
};

interface IIcon {
  name: keyof typeof map;
  color?: 'inherit' | 'disabled' | 'action' | 'primary' | 'secondary' | 'error' | undefined;
  fontSize?: 'inherit' | 'large' | 'medium' | 'small';
  htmlColor?: string;
  shapeRendering?: string;
}

const Icon: FC<IIcon> = (props) => {
  const {
    name,
    color,
    fontSize,
    htmlColor,
    shapeRendering,
  } = props;

  return (
    <SvgIcon
      component={map[name]}
      color={color}
      fontSize={fontSize}
      htmlColor={htmlColor}
      shapeRendering={shapeRendering}
    />
  );
};

export default Icon;
