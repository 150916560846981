import { gql, CreateProvider } from 'services/dataProvider';

import { fields as CompanyAdminFields } from '../fragments';

const provider: CreateProvider = (params) => {
  const mutation = gql`
    ${CompanyAdminFields}
    mutation ($input: CompanyAdminCreateInput!) {
      companyAdmin {
        create (input: $input) {
          ...CompanyAdminFields
        }
      }
    }
  `;
  return {
    mutation,
    variables: {
      input: {
        firstName: params.data.firstName,
        lastName: params.data.lastName,
        phone: params.data.phone,
        email: params.data.email,
      },
    },
  };
};

export default provider;
