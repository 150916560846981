import { memo } from 'react';
import { useTranslate } from 'react-admin';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { Box } from '@mui/material';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';

import BulkActionButton from 'resources/Task/elements/BulkActionButton';
import LinkButton from 'components/LinkButton';

import BulkApproveButton from '../elements/BulkApproveButton';

type DesktopBulkActionProps = {
  filterValues? : {
    isArchived: string
  },
  handleApprove? : () => void
  isLoading?: boolean
  hasDownloadAll?: boolean
  downloadUrl? : string
  selectedIds? : number[]
};

const DesktopBulkAction = (props: DesktopBulkActionProps) => {
  const {
    filterValues, handleApprove, isLoading, downloadUrl, selectedIds, hasDownloadAll,
  } = props;
  const { isArchived = 'unarchived' } = filterValues || {};
  const translate = useTranslate();

  return (
    <Box sx={{ display: 'flex' }}>
      <LinkButton
        variant="text"
        disable={!hasDownloadAll}
        href={downloadUrl}
        icon={<GetAppOutlinedIcon sx={{ fontSize: '18px' }} />}
        text={translate('resources.task.download')}
      />
      <BulkApproveButton isLoading={isLoading} handleApprove={handleApprove} label={translate('resources.task.approve')} />
      {isArchived === 'unarchived' && (
        <BulkActionButton
          selectedIds={selectedIds}
          action="setArchived"
          label={translate('resources.task.values.status.archive')}
          value
          startIcon={<UnarchiveOutlinedIcon />}
        />
      )}
      {isArchived === 'archived' && (
        <BulkActionButton
          selectedIds={selectedIds}
          action="setArchived"
          label={translate('resources.task.values.status.unArchive')}
          value={false}
          startIcon={<ArchiveOutlinedIcon />}
        />
      )}
    </Box>
  );
};

export default memo(DesktopBulkAction);
