import React, { useCallback, useEffect, useRef } from 'react';
import { IconButton } from '@mui/material';
import { useLocaleState } from 'react-admin';

import { ReactComponent as ENIcon } from 'components/Menu/assets/Icon-EN-lang.svg';
import { ReactComponent as HEIcon } from 'components/Menu/assets/Icon-HE-lang.svg';

type Locales = 'en' | 'he';

const direction = {
  en: 'ltr',
  he: 'rtl',
};

const update = (locale: Locales) => {
  if (typeof document === 'undefined') {
    return;
  }
  document.body.setAttribute('dir', direction[locale]);
  localStorage.setItem('locale', locale);
};

const loadLocate = (defaultLocale: string): Locales => {
  return (localStorage.getItem('locale') || defaultLocale) as Locales;
};

const LocaleSwitcher = () => {
  const [locale, setLocale] = useLocaleState();

  const localeRef = useRef<Locales>(loadLocate(locale));

  const handleLocaleEn = useCallback(() => {
    setLocale('en');
    update('en');
  }, [setLocale]);

  const handleLocaleHe = useCallback(() => {
    setLocale('he');
    update('he');
  }, [setLocale]);

  useEffect(() => {
    const { current } = localeRef;
    setLocale(current);
    update(current);
  }, [setLocale]);

  return (
    <>
      <IconButton onClick={handleLocaleEn}><ENIcon /></IconButton>
      <IconButton onClick={handleLocaleHe}><HEIcon /></IconButton>
    </>
  );
};

export default LocaleSwitcher;
