import {
  useCallback, useRef, forwardRef, useEffect, memo,
} from 'react';
import {
  ArrayInput, SimpleFormIterator,
  TextInput,
  useCreate, useNotify, useRefresh, SelectInput, useUpdate, Identifier, useTranslate,
} from 'react-admin';
import {
  Box, Button, Theme, useMediaQuery,
} from '@mui/material';
import { createUseStyles } from 'react-jss';

import * as ModalwithForm from 'components/ModalwithForm';
import FileInput from 'components/FileInput';
import { FormButton } from 'components/FormButton';
import { TaskType } from 'interfaces/entities';

import cn from 'classnames';
import ImageItem from './ImageItem';
import ImageEmpty from './ImageEmpty';
import CheckIsOngoing from '../../Task/elements/checkIsOngoing';

interface DataType extends Omit<TaskType, 'id'> {
  id: Identifier
}

interface ModalTaskTypeProps {
  id?: string,
  record?: DataType,
  method: 'create' | 'update',
  FormProps: {
    title: string,
    submitLabel: string,
  },
  ButtonProps: {
    text?: string,
    variant?: 'contained' | 'outlined' | 'text',
    color?: 'primary' | 'secondary' | 'inherit' | 'error' | 'success' | 'info' | 'warning',
    disabled?: boolean,
    show?: boolean,
  },
  onComplete?: (data: DataType) => void,
}

const TaskTypeModal = forwardRef<any, ModalTaskTypeProps>((props, forwardedRef) => {
  const {
    id,
    method,
    ButtonProps,
    FormProps,
    record,
    onComplete,
  } = props;

  const classes = useStyles();
  const dialogRef = useRef(null);
  const [create, { isLoading: creating }] = useCreate();
  const [update, { isLoading: updating }] = useUpdate();
  const notify = useNotify();
  const loading = creating || updating;
  const refresh = useRefresh();
  const translate = useTranslate();

  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const handleClickOpen = useCallback((event: { stopPropagation: () => void; }) => {
    event?.stopPropagation?.();
    ModalwithForm.open(dialogRef);
  }, []);

  useEffect(() => {
    if (forwardedRef && 'current' in forwardedRef) {
      forwardedRef.current = {
        open: handleClickOpen,
      };
    }
  }, [forwardedRef, handleClickOpen]);

  const handleSubmit = useCallback(async (data: DataType) => {
    const options = {
      returnPromise: true,
    };
    let taskType: DataType;
    try {
      let response;
      if (method === 'create') {
        response = await create('TaskType', { data }, options);
      } else {
        response = await update('TaskType', { id: record?.id, data, previousData: { record } }, options);
      }
      taskType = response?.data;
    } catch (error) {
      notify((error as Error).message, { type: 'warning' });
      return;
    }
    ModalwithForm.close(dialogRef);
    setTimeout(() => {
      onComplete?.(taskType);
    }, 300);
    refresh();
  }, [create, method, notify, onComplete, record, refresh, update]);

  return (
    <>
      {(ButtonProps.show || ButtonProps.show === undefined) && (
        <Button
          variant={ButtonProps?.variant || 'contained'}
          color={ButtonProps?.color || 'primary'}
          disabled={ButtonProps?.disabled || false}
          onClick={handleClickOpen}
        >
          {FormProps?.submitLabel}
        </Button>
      )}
      <ModalwithForm.Dialog
        id={id}
        ref={dialogRef}
        title={FormProps?.title}
        onSubmit={handleSubmit}
        initialValues={record}
        bodyContent={(
          <>
            <TextInput label="resources.taskType.fields.name" source="name" variant="standard" fullWidth />
            <TextInput label="resources.taskType.fields.description" source="description" variant="standard" fullWidth />
            <div className={cn(classes.panel, { [classes.mobilePanel]: isXSmall })}>
              <FileInput
                source="example"
                label="resources.taskType.fields.example"
                accept={['image/jpeg', 'image/png', 'image/webp', 'image/tiff', 'image/jpg']}
                itemComponent={<ImageItem />}
                emptyComponent={<ImageEmpty />}
              />
              <Box marginTop="0.5rem">
                <CheckIsOngoing source="isOngoing" label="resources.taskType.fields.repeatTask" />
                <SelectInput
                  label="resources.taskType.fields.priorityLevel"
                  source="priorityLevel"
                  choices={[
                    { id: '1', name: translate('resources.taskType.fields.low') },
                    { id: '2', name: translate('resources.taskType.fields.medium') },
                    { id: '3', name: translate('resources.taskType.fields.high') },
                  ]}
                  variant="standard"
                  defaultValue={2}
                  fullWidth
                />
                <div className="tags">
                  <ArrayInput label="resources.taskType.fields.tags" source="tags" variant="standard">
                    <SimpleFormIterator disableReordering>
                      <TextInput
                        source="name"
                        label=""
                        InputProps={{
                          className: 'input',
                          disableUnderline: true,
                        }}
                        FormHelperTextProps={{
                          className: 'helper',
                        }}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                </div>
              </Box>
            </div>
          </>
                )}
        actionsRightSide={(
          <FormButton variant="contained" color="primary" label={FormProps.submitLabel} />
                )}
        layout="slim"
      />
    </>
  );
});

const useStyles = createUseStyles({
  panel: {
    '&': {
      display: 'flex',
      minHeight: '15rem',
    },
    '& .tags': {
      '&': {
        width: '17rem',
        marginTop: '-1rem',
      },
      '& ul': {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        columnGap: '1rem',
      },
      '& li': {
        position: 'relative',
        border: 0,
        maxWidth: '8rem',
      },
      '& li > div': {
        display: 'none',
      },
      '& .helper': {
        display: 'none',
      },
      '& .button-remove': {
        '&': {
          position: 'absolute',
          minWidth: 0,
          top: '-0.5rem',
          right: '-1rem',
        },
        '& .MuiButton-label span': {
          display: 'none',
        },
      },
      '& .input > input': {
        background: 'rgba(235, 243, 255, 1)',
        color: 'rgba(96, 107, 124, 1)',
        padding: '0.25rem 0.5rem',
        borderRadius: '0.5rem',
      },
      '& section .MuiFormControl-root': {
        width: 'auto',
      },
      '& .fade-enter': {
        opacity: 1,
        transform: 'none',
      },
      '& .fade-exit': {
        display: 'none',
      },
    },
  },
  mobilePanel: {
    display: 'grid',
    width: '100%',
    height: '100%',
  },
  paper: {
    width: '100%',
    height: '100%',
    maxWidth: '50rem',
    maxHeight: '45rem',
  },
  clientsInput: {
    marginTop: '0.5rem',
  },
  tasksInput: {},
});

export default memo(TaskTypeModal);
