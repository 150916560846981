import { memo, PropsWithChildren, ReactElement } from 'react';

import FileInput from 'components/FileInput';
import ImageInput from './ImageInput';

type AvatarInputProps = {
  source: string,
  label: string,
  textField: string,
  firstName?: string,
  lastName?: string,
};

export const getName = (firstName = '', lastName = ''): string => `${firstName} ${lastName}`.trim();

const AvatarInput = (props: PropsWithChildren<AvatarInputProps>): ReactElement => {
  const {
    source, label, firstName, lastName, textField,
  } = props;

  const name = getName(firstName, lastName);
  return (
    <FileInput
      source={source}
      label={label}
      itemComponent={<ImageInput textField={textField} name={name} />}
      emptyComponent={<ImageInput textField={textField} name={name} />}
    />
  );
};

export default memo(AvatarInput);
