import { memo, useCallback, useMemo } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import {
  useListContext, useNotify, useRecordContext, useRefresh, useUnselectAll, useUpdateMany,
} from 'react-admin';

import DesktopBulkAction from './layouts/DesktopBulkAction';
import MobileBulkAction from './layouts/MobileBulkAction';

const SERVER_URL = (process.env.REACT_APP_SERVER_URL || '').replace(/\/$/, '');


const BulkActionsToolbar = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll('Task');
  const { selectedIds, filterValues, data } = useListContext();
  const [updateMany, { isLoading }] = useUpdateMany();
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const hasDownloadAll = useMemo(() => (
    (data || []).filter(({ id, documents }) => selectedIds.includes(id) && documents.length > 0).length > 0
  ), [data, selectedIds]);

  const zipUrl = useMemo(() => (
    `${SERVER_URL}/api/download-all/generate-zip-by-client/${record.id}/${[
      (record.firstName || '').replace(' ', '_'),
      (record.lastName || '').replace(' ', '_'),
    ].join('_')}.zip?taskIds=${selectedIds.join(',')}`
  ), [record.id, record.firstName, record.lastName, selectedIds]);

  const handleApprove = useCallback(async () => {
    try {
      await updateMany('Task', { ids: selectedIds, data: { state: 'approved' } });
      refresh();
      unselectAll();
      notify('Task approved', { type: 'success' });
    } catch (err: any) {
      notify(`Task not approved error: ${err?.message}`, { type: 'warning' });
    }
  }, [notify, refresh, selectedIds, unselectAll, updateMany]);

  return (
    <>
      {!isXSmall && (
        <DesktopBulkAction
          selectedIds={selectedIds}
          hasDownloadAll={hasDownloadAll}
          isLoading={isLoading}
          downloadUrl={zipUrl}
          handleApprove={handleApprove}
          filterValues={filterValues}
        />
      )}
      {isXSmall && (
        <MobileBulkAction
          selectedIds={selectedIds}
          hasDownloadAll={hasDownloadAll}
          downloadUrl={zipUrl}
          handleApprove={handleApprove}
          filterValues={filterValues}
        />
      )}
    </>
  );
};

export default memo(BulkActionsToolbar);
