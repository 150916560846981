import { Theme, useMediaQuery } from '@mui/material';
import { memo } from 'react';

import DesktopLayout from './layouts/DesktopLayout';
import MobileLayout from './layouts/MobileLayout';

const ClientList = (props: any) => {
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return (
    <>
      {isXSmall && (
        <MobileLayout />
      )}
      {!isXSmall && (
        <DesktopLayout />
      )}
    </>
  );
};

export default memo(ClientList);
