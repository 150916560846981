import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { GraphQLError } from 'graphql';

import { DeleteManyResult, DeleteManyParams, DeleteManyProvider } from './types';

export type { DeleteManyResult, DeleteManyParams, DeleteManyProvider };

export default async (client: ApolloClient<NormalizedCacheObject>, provider: DeleteManyProvider, resource: string, params: DeleteManyParams): Promise<DeleteManyResult> => {
  const { parser, ...clientArgs } = provider(params);

  let data;
  let errors;
  try {
    const result = await client.mutate(clientArgs);
    data = result.data;
    errors = result.errors;
  } catch (error) {
    errors = [new GraphQLError((error as Error).message)];
  }

  if (errors) {
    return Promise.reject(errors[0].message);
  }

  if (!data) {
    return Promise.reject(new GraphQLError('Not data found'));
  }

  if (parser) {
    return parser(data);
  }

  const firstNode = data[Object.keys(data)[0]];
  return {
    data: firstNode.deleteMany,
  };
};
