import {
  type CSSProperties,
  memo, useCallback, useEffect,
} from 'react';
import {
  Datagrid,
  DateField,
  TextField,
  useTranslate,
  useUnselectAll,
  ListToolbar,
  ListActionsProps, TopToolbar, Identifier, useRecordContext, InfiniteListBase,
} from 'react-admin';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { Paper, Typography } from '@mui/material';

import TaskAddModal from 'resources/Task/elements/TaskAddModal';
import ClientTaskStatus from 'components/ClientTaskStatus';

import IsArchivedFilter from 'components/IsArhcivedFilter';
import ClientsTasksEmpty from '../elements/Empty';

import BulkActionToolbar from '../elements/BulkActionToolbar';

const filters = [
  <IsArchivedFilter
    label="resources.task.values.status.showArchived"
    source="isArchived"
    alwaysOn
  />,
];

const ListActions = (props: ListActionsProps) => {
  const {
    className,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();
  const record = useRecordContext();

  return (
    <TopToolbar className={className}>
      <TaskAddModal
        ButtonProps={{
          text: translate('resources.task.addNewTask'),
          color: 'primary',
          variant: 'contained',
        }}
        client={{
          id: record.id,
          name: `${record.firstName} ${record.lastName}`,
        }}
      />
    </TopToolbar>
  );
};

const defaultProps = {
  hasPagination: false,
  perPage: 10,
};

type DesktopLayoutProps = {
  className?: string,
  style?: CSSProperties,
  clientId?: Identifier,
  perPage?: number,
  hasPagination?: boolean,
  clientName?: string,
} & typeof defaultProps;

const DesktopLayout = (props: DesktopLayoutProps) => {
  const {
    clientName,
    className,
    style,
    clientId,
    hasPagination,
    perPage,
  } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const unSelectAll = useUnselectAll('Task');

  useEffect(() => {
    unSelectAll();
  }, [unSelectAll]);

  const handleTaskOpen = useCallback((id: any) => {
    return `/Task/${id}`;
  }, []);

  return (
    <div className={cn(classes.container, className)} style={style}>
      <Typography className={classes.title}>{translate('resources.task.name')}</Typography>
      <InfiniteListBase
        resource="Task"
        filter={{ clientId: clientId || 0 }}
        sort={{ field: 'id', order: 'desc' }}
        perPage={perPage}
      >
        <ListToolbar
          filters={filters}
          actions={<ListActions />}
          className={classes.toolbar}
        />
        <Paper elevation={1}>
          <Datagrid empty={<ClientsTasksEmpty />} bulkActionButtons={<BulkActionToolbar />} rowClick={handleTaskOpen} className={classes.list}>
            <TextField source="task.name" label={translate('resources.task.taskName')} />
            <DateField source="lastUpdatedAt" label={translate('resources.task.lastUpdate')} />
            <DateField source="dueDateAt" label={translate('resources.task.dueDate')} />
            <TextField source="documents.length" sortable={false} label={translate('resources.task.docs')} />
            <ClientTaskStatus source="status" label={translate('resources.task.status')} />
          </Datagrid>
        </Paper>
      </InfiniteListBase>
    </div>
  );
};

DesktopLayout.defaultProps = defaultProps;

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    flex: 1,
  },
  toolbar: {
    '&::before': {
      content: 'attr(data-content)',
    },
    '& > form': {
      justifyContent: 'flex-end',
      alignSelf: 'stretch',
      alignItems: 'center',
      paddingTop: 8,
    },
    '& > div': {
      alignSelf: 'stretch',
      alignItems: 'center',
      paddingTop: 8,
      paddingRight: 0,
    },
  },
  title: {
    position: 'absolute',
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: '1.875rem',
    top: 19,
  },
  list: {
    '& .column-documents\\.length': {
      minWidth: 64,
      whiteSpace: 'nowrap',
    },
    '& .column-lastUpdatedAt': {
      minWidth: 112,
      whiteSpace: 'nowrap',
    },
    '& .column-dueDateAt': {
      minWidth: 112,
      whiteSpace: 'nowrap',
    },
    '& .column-status': {
      minWidth: 96,
      whiteSpace: 'nowrap',
    },
  },
});

export default memo(DesktopLayout);
