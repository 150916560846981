import {
  FC, Fragment, ReactElement, useCallback, useMemo, cloneElement, useRef, useState, memo,
} from 'react';
import cn from 'classnames';
import {
  FileInput as RAFileInput, useInput,
} from 'react-admin';
import { createUseStyles } from 'react-jss';

import useFileUploadCallback from './hooks/useFileUploadCallback';
import DefaultEmptyComponent, { EmptyComponentProps } from './elements/DefaultEmptyComponent';
import DefaultItemComponent, { ItemComponentProps } from './elements/DefaultItemComponent';
import DefaultAddComponent, { AddComponentProps } from './elements/DefaultAddComponent';
import DefaultProgressComponent, { ProgressComponentProps } from './elements/DefaultProgressComponent';

import type {
  UploaderProps,
  UploadStartEvent,
  UploadProgressEvent,
  UploadEndEvent,
  TContentTypes,
  ChangeEvent,
  FileData,
  IProgress,
  ILoading,
  UploadErrorEvent,
} from './types';

export type {
  EmptyComponentProps,
  ItemComponentProps,
  AddComponentProps,
  ProgressComponentProps,
};

const SERVER_URL = (process.env.REACT_APP_SERVER_URL || '').replace(/\/$/, '');

interface FileInputProps extends UploaderProps {
  source: string,
  label: string | false,
  multiple?: boolean,
  name?: string,
  accept?: TContentTypes | TContentTypes[],
  onChange?: (event: ChangeEvent) => void,
  emptyComponent?: ReactElement,
  itemComponent?: ReactElement,
  progressComponent?: ReactElement,
  addComponent?: ReactElement | boolean,
  classes?: {
    root?: string,
    container?: string,
  },
  dropZoneDisable?: boolean,
}

const FileInput: FC<FileInputProps> = (props) => {
  const {
    source,
    label,
    onUploadStart,
    onUploadEnd,
    onUploadProgress,
    onUploadError,
    onChange,
    multiple,
    name,
    accept,
    emptyComponent: EmptyComponent = <DefaultEmptyComponent />,
    itemComponent: ItemComponent = <DefaultItemComponent />,
    progressComponent: ProgressComponent = <DefaultProgressComponent />,
    addComponent = true as ReactElement | boolean,
    classes: userClasses,
    dropZoneDisable,
  } = props;

  const AddComponent = addComponent === true ? <DefaultAddComponent /> : addComponent;

  const classes = useStyles();

  const progressRef = useRef<IProgress>({
    timer: undefined,
    percent: 0,
    sessions: [],
  });
  const [loading, setLoading] = useState<ILoading>({
    isLoading: false,
    percent: 0,
    sessions: [],
  });

  const { field }: any = useInput({ source });

  const currentValue = (!Array.isArray(field.value) ? [field.value] : field.value).filter((item: { url: any; }) => typeof item.url === 'string');

  const sessionTimer = useCallback(() => {
    const {
      timer,
      sessions: progressSessions,
    } = progressRef.current;
    const isLoading = Boolean(timer);
    const percent = progressSessions.reduce((previousValue: number, value) => previousValue + value.percent, 0) / progressSessions.length || 0;
    const sessions = progressSessions.map((item) => ({ ...item }));
    progressRef.current.percent = percent;
    setLoading({
      isLoading,
      percent,
      sessions,
    });
  }, []);

  const sessionAdd = useCallback((uuid: string, files: FileData[], abort: () => void): void => {
    if (!progressRef.current.timer) {
      progressRef.current.timer = setInterval(sessionTimer, 100);
    }
    progressRef.current.sessions.push({
      uuid,
      files,
      percent: 0,
      abort,
    });
    sessionTimer();
  }, [sessionTimer]);

  const sessionUpdate = useCallback((uuid: string, files: FileData[], percent: number): void => {
    const session = progressRef.current.sessions.find((item) => item.uuid === uuid);
    if (!session) {
      return;
    }
    session.files = files;
    session.percent = percent;
  }, []);

  const sessionRemove = useCallback((uuid: string): void => {
    const sessionIndex = progressRef.current.sessions.findIndex((item) => item.uuid === uuid);
    if (sessionIndex === -1) {
      return;
    }
    progressRef.current.sessions.splice(sessionIndex, 1);
    if (progressRef.current.sessions.length === 0 && progressRef.current.timer) {
      clearInterval(progressRef.current.timer);
      progressRef.current.timer = undefined;
    }
    sessionTimer();
  }, [sessionTimer]);

  const handleUploadStart = useCallback((event: UploadStartEvent): void => {
    const { uuid, files, abort } = event.payload;
    sessionAdd(uuid, files, abort);
    onUploadStart?.(event);
  }, [sessionAdd, onUploadStart]);

  const handleUploadProgress = useCallback((event: UploadProgressEvent): void => {
    const {
      uuid, files, loaded, total,
    } = event.payload;
    sessionUpdate(uuid, files, loaded / total);
    onUploadProgress?.(event);
  }, [onUploadProgress, sessionUpdate]);

  const handleUploadEnd = useCallback((event: UploadEndEvent): void => {
    const { uuid, files } = event.payload;
    let newValue: FileData | FileData[] | null;
    const newValueMap: Record<string, null> = {};
    if (files.length === 0) {
      newValue = null;
    } else if (!multiple) {
      [newValue] = files;
    } else {
      newValue = [];
      ([...currentValue, ...files] as FileData[])
        .filter((item) => typeof item.url === 'string')
        .forEach((item) => {
          if (item.url in newValueMap) {
            return;
          }
          newValueMap[item.url] = null;
          (newValue as FileData[]).push(item);
        });
    }
    sessionRemove(uuid);
    field.onChange(newValue);
    onUploadEnd?.(event);
    onChange?.(event);
  }, [currentValue, field, multiple, onChange, onUploadEnd, sessionRemove]);

  const handleUploadError = useCallback((event: UploadErrorEvent) => {
    const { uuid } = event.payload;
    sessionRemove(uuid);
    onUploadError?.(event);
  }, [onUploadError, sessionRemove]);

  const handlePreventClick = useCallback((event: any) => {
    if ((event.target as HTMLElement).closest('button')) {
      event.preventDefault();
      event.stopPropagation();
    }
  }, []);

  const handleRemove = useCallback((url: string, event: UploadErrorEvent) => {
    if (!currentValue) {
      return;
    }
    if (!multiple) {
      field.onChange(null);
    }
    field.onChange(currentValue.filter((item: { url: string; }) => item.url !== url));
  }, [currentValue, field, multiple]);

  const handleFileUpload = useFileUploadCallback(`${SERVER_URL}/api/upload` as string, {
    onUploadStart: handleUploadStart,
    onUploadEnd: handleUploadEnd,
    onUploadProgress: handleUploadProgress,
    onUploadError: handleUploadError,
  });

  const Items = useMemo(() => currentValue.map((item: any) => (
    <Fragment key={item.url}>
      {cloneElement(ItemComponent, {
        data: item,
        onRemove: handleRemove.bind(null, item.url),
      })}
    </Fragment>
  )), [currentValue, ItemComponent, handleRemove]);

  const isEmpty = currentValue.length === 0;

  return (
    <RAFileInput
      options={{ disabled: dropZoneDisable }}
      className={cn(classes.container, userClasses?.container)}
      source={source}
      label={label}
      multiple={multiple}
      onChange={handleFileUpload}
      accept={accept as string}
      name={name}
      placeholder={(
        <div
          className={cn(classes.items, userClasses?.root)}
          role="none"
          onClick={handlePreventClick}
        >
          {!multiple && Boolean(isEmpty) && cloneElement(EmptyComponent, { isLoading: loading?.sessions.length > 0, ...loading?.sessions?.[0] })}
          {multiple && Boolean(isEmpty) && !loading.isLoading && EmptyComponent}
          {!isEmpty && Items}
          {multiple && loading.sessions.map((session) => (
            <Fragment key={session.uuid}>
              {cloneElement(ProgressComponent as ReactElement, { isLoading: true, ...session })}
            </Fragment>
          ))}
          {multiple && (!isEmpty || (isEmpty && loading.sessions.length > 0)) && AddComponent}
        </div>
      )}
    >
      <div />
    </RAFileInput>
  );
};

const useStyles = createUseStyles({
  container: {
    '&': {
    },
    '& .previews': {
      display: 'none',
    },
    '& [data-testid="dropzone"]': {
      padding: 0,
      background: 'transparent',
    },
  },
  items: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    gap: '1rem',
  },
});

export default memo(FileInput);
