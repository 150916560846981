import { FC, memo } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  ListActionsProps,
  TopToolbar,
  useTranslate,
} from 'react-admin';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import { Theme, useMediaQuery } from '@mui/material';

import EmptyList from 'components/EmptyList';
import SearchInput from 'components/SearchInput';
import ClientTaskStatus from 'components/ClientTaskStatus';
import IsArchivedFilter from 'components/IsArhcivedFilter';

import BulkActionButton from './elements/BulkActionButton';
import MobileGrid from './elements/MobileGrid';

const filters = [
  <SearchInput
    label="resources.taskType.action.search"
    source="q"
    alwaysOn
  />,
  <IsArchivedFilter
    label="resources.task.values.status.showArchived"
    source="isArchived"
    alwaysOn
  />,
];

const ListActions: FC<ListActionsProps> = (props) => {
  const {
    className,
  } = props;

  return (
    <TopToolbar className={className}>
      {
        /* <TaskAddModal
        ButtonProps={{
          variant: 'contained',
          color: 'primary',
          text: 'ADD TASKS TO CLIENTS',
        }}
      /> */}
    </TopToolbar>
  );
};

interface TaskBulkActionButtonsProps {
  filterValues? : {
    isArchived: string
  },
}

export const TaskBulkActionButtons: FC<TaskBulkActionButtonsProps> = (props) => {
  const { filterValues } = props;
  const { isArchived = 'unarchived' } = filterValues || {};
  const translate = useTranslate();
  return (
    <>
      {isArchived === 'unarchived' && (
      <BulkActionButton
        {...props}
        action="setArchived"
        label={translate('resources.task.values.status.archive')}
        value
        startIcon={<UnarchiveOutlinedIcon />}
      />
      )}
      {isArchived === 'archived' && (
      <BulkActionButton
        {...props}
        action="setArchived"
        label={translate('resources.task.values.status.unArchive')}
        value={false}
        startIcon={<ArchiveOutlinedIcon />}
      />
      )}
    </>
  );
};

const TaskList: FC = (props) => {
  const translate = useTranslate();
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return (
    <>
      {!isXSmall && (
      <List
        {...props}
        sx={{
          '& .MuiToolbar-root:first-child': {
            '&': {
              display: 'flex',
              flex: 1,
            },
            '& form': {
              '&': {
                display: 'flex',
                flex: 1,
              },
              '& [data-source="isArchived"]': {
                marginLeft: 'auto',
              },
            },
          },
        }}
        filters={filters}
        actions={false}
        sort={{ field: 'lastUpdatedAt', order: 'desc' }}
        empty={<EmptyList body="You will see here all the tasks your clients have" />}
        title={translate('resources.task.name')}
      >
        <Datagrid
          sx={{
            '& .column-id': {
              width: '2rem',
            },
            '& .column-dueDateAt': {
              width: '9rem',
            },
            '& .column-lastUpdatedAt': {
              width: '9rem',
            },
            '& .column-documents\\.length': {
              width: '6rem',
            },
            '& .column-status': {
              width: '5rem',
            },
            '& .column-isArchived': {
              width: '5rem',
            },
          }}
          bulkActionButtons={<TaskBulkActionButtons />}
          rowClick="edit"
        >
          <FunctionField
            source="user.firstName"
            sortable={false}
            label="resources.task.clientName"
            render={(record: any) => `${record?.client?.firstName || ''} ${record?.client?.lastName || ''}`}
          />
          <TextField source="task.name" label="resources.task.taskName" />
          <DateField source="lastUpdatedAt" label="resources.task.lastUpdate" />
          <DateField source="dueDateAt" label="resources.task.dueDate" />
          <TextField source="documents.length" sortable={false} label="resources.task.docs" />
          <ClientTaskStatus source="status" label="resources.task.status" />
        </Datagrid>
      </List>
      )}
      {isXSmall && (
        <List
          {...props}
          sx={{
            '&': {
              marginRight: '-8px',
            },
            '& .MuiToolbar-root': {
              background: 'transparent',
            },
            '& > div > .MuiPaper-root': {
              boxShadow: 'none',
              background: 'transparent',
            },
          }}
          filters={filters}
          actions={false}
          sort={{ field: 'lastUpdatedAt', order: 'desc' }}
          empty={<EmptyList body="You will see here all the tasks your clients have" />}
          title={translate('resources.task.name')}
        >
          <MobileGrid />
        </List>
      ) }
    </>

  );
};


export default memo(TaskList);
