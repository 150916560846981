import { memo, PropsWithChildren, useCallback } from 'react';
import {
  Box, Card, CardHeader, CardContent, Checkbox,
} from '@mui/material';

import {
  TextField,
  useTranslate,
  useListContext,
  RecordContextProvider, Identifier,
} from 'react-admin';

import DueDateMobile, { Item } from '../elements/DueDateMobile';


type MobileTasksInputProps = {
  selectedItems: Item[],
  onChange?: (taskId: Identifier, dueDateAt: string) => void,
};
const MobileTasksInput = (props: PropsWithChildren<MobileTasksInputProps>) => {
  const {
    selectedItems, onChange,
  } = props;
  const {
    data, isLoading, onToggleItem,
  } = useListContext();

  const translate = useTranslate();

  const handleCheck = useCallback((event: {
    target: any ;
    stopPropagation: () => void; }) => {
    const taskId = Number(event.target.id);
    event.stopPropagation();
    onToggleItem(taskId);
  }, [onToggleItem]);

  if (isLoading || !data) {
    return null;
  }

  return (
    <Box width="100%">
      {data.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
          <Card
            sx={{ margin: '0.5rem 0' }}
          >
            <CardHeader
              title={(
                <>
                  <Checkbox
                    sx={{ marginLeft: '-0.7rem' }}
                    onClick={handleCheck}
                    id={record.id}
                  />
                  <TextField source="name" label="resources.task.taskName" />
                </>
              )}
              titleTypographyProps={{ variant: 'body1' }}
              sx={{ padding: '0.5rem 1rem 0 1rem' }}
            />
            <CardContent sx={{ padding: '0.2rem 1rem 0 1rem', '&:last-child': { pb: '1rem' } }}>
              <DueDateMobile source={translate('resources.task.dueDate')} selectedItems={selectedItems} onChange={onChange} />
            </CardContent>
          </Card>
        </RecordContextProvider>
      ))}
    </Box>
  );
};


export default memo(MobileTasksInput);
