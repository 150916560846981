import { memo } from 'react';
import {
  Paper, Typography,
} from '@mui/material';
import { createUseStyles } from 'react-jss';

import type { AddComponentProps } from 'components/FileInput';
import { ReactComponent as IconFile } from 'components/FileInput/assets/icon-file.svg';

const DocumentAdd = (props: AddComponentProps) => {
  const classes = useStyles();

  return (
    <Paper elevation={1} className={classes.add}>
      <div className={classes.icon}>
        <IconFile />
      </div>
      <Typography className={classes.text} variant="caption" component="div">
        drop file here
        <br />
        to upload
      </Typography>
    </Paper>
  );
};

const useStyles = createUseStyles({
  add: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: 0,
    width: '12rem',
    height: '10rem',
  },
  icon: {
    '&': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '2.4rem',
    },
    '& svg': {
      height: '100%',
    },
  },
  loading: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.625rem',
  },
  text: {
    marginTop: '0.6rem',
  },
});

export default memo(DocumentAdd);
