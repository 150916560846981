import { Resource } from 'react-admin';

import List from './screens/ClientList';
import Show from './screens/ClientShow';
import Create from './screens/ClientCreate';
import Edit from './screens/ClientEdit';

import getList from './graphql/providers/getList';
import getOne from './graphql/providers/getOne';
import create from './graphql/providers/create';
import update from './graphql/providers/update';
import deleteMany from './graphql/providers/deleteMany';

export default <Resource
  name="Client"
  list={List}
  show={Show}
  create={Create}
  edit={Edit}
  options={{
    providers: {
      getList,
      getOne,
      create,
      update,
      deleteMany,
    },
  }}
/>;
