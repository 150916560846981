import { memo } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import {
  Typography, Paper, Box,
} from '@mui/material';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';

import StringField from 'components/StringField';
import RenderField from 'components/RenderField';

import ClientModal from './ClientModal';

const defaultProps = {
  columns: 2,
};

type UserInfoProps = {
  columns?: number,
} & typeof defaultProps;

const UserInfo = (props: UserInfoProps) => {
  const {
    columns,
  } = props;

  const classes = useStyles();

  const record = useRecordContext();
  const translate = useTranslate();

  if (!record) {
    return null;
  }

  return (
    <Box
      sx={{
        marginLeft: '1rem',
        width: columns === 1 ? '30%' : '25%',
        maxWidth: 400,
        minWidth: columns === 1 ? 300 : 200,
        marginRight: '0.5rem',
      }}
    >
      <div className={classes.toolbar}>
        <Typography className={classes.toolbarText}>
          {translate('resources.client.client')}
        </Typography>
        <ClientModal
          id="edit-client"
          ButtonProps={{
            variant: 'outlined',
            color: 'primary',
            text: translate('resources.taskType.action.edit'),
          }}
          FormProps={{
            title: translate('resources.client.editClient'),
            submitLabel: translate('resources.task.save'),
          }}
          method="update"
          record={record}
        />
      </div>
      <Paper sx={{ padding: '8px 16px' }} className={classes.panel} elevation={1}>
        <div className={cn(classes.grid, { [classes.columns1]: columns === 1, [classes.columns2]: columns === 2 })}>
          <RenderField
            render={(rec) => [rec.firstName, rec.lastName].filter((i) => i).join(' ')}
            label={translate('resources.client.fields.clientName')}
          />
          <StringField source="email" label={translate('resources.client.fields.email')} />
          <StringField source="dateOfBirth" label={translate('resources.client.fields.dateOfBirth')} />
          <StringField source="phone" label={translate('resources.client.fields.mobilePhone')} />
        </div>
        <RenderField
          render={(rec) => [rec.addressHouseNumber, rec.addressCity, rec.addressState, rec.addressZipCode].filter((i) => i).join(', ')}
          label={translate('resources.client.fields.address')}
        />
        <StringField source="notes" label={translate('resources.client.fields.notes')} />
      </Paper>
    </Box>
  );
};

UserInfo.defaultProps = defaultProps;

const useStyles = createUseStyles({
  UserInfo: {
    marginLeft: '1rem',
    width: '30%',
    maxWidth: 400,
    minWidth: 300,
  },
  slim: {
    width: '25%',
    minWidth: 200,
  },
  panel: {
    padding: '8px 16px',
  },
  grid: {
    display: 'grid',
    width: '100%',
    columnGap: '2rem',
  },
  columns1: {
    gridTemplateColumns: '1fr',
  },
  columns2: {
    gridTemplateColumns: '1fr 1fr',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 64,
    padding: '8px 0 4px',
  },
  toolbarText: {
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: '1.875rem',
  },
});

export default memo(UserInfo);
