import { memo } from 'react';
import {
  Box, Card, CardHeader, CardContent, Typography,
} from '@mui/material';
import {
  TextField,
  useTranslate,
  useListContext,
  RecordContextProvider, FunctionField,
} from 'react-admin';

import User from './User';


const MobileGrid = () => {
  const { data, isLoading } = useListContext();
  const translate = useTranslate();
  if (isLoading || data.length === 0) {
    return null;
  }
  return (
    <Box>
      {data.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
          <Card sx={{ margin: '0.5rem 0' }}>
            <CardHeader
              title={(
                <FunctionField source="first_name" label={translate('resources.companyAdmin.fields.name')} render={() => <User record={record} />} />
              )}
              titleTypographyProps={{ variant: 'body1' }}
            />
            <CardContent sx={{ pt: 0, '&:last-child': { pb: '1rem' } }}>
              <Typography variant="body2" gutterBottom>
                {translate('resources.companyAdmin.fields.phone')}
                :&nbsp;
                <TextField source="phone" />
              </Typography>
              <Typography variant="body2" gutterBottom>
                {translate('resources.companyAdmin.fields.email')}
                :&nbsp;
                <TextField source="email" />
              </Typography>
              <Typography variant="body2" gutterBottom>
                {translate('resources.companyAdmin.fields.role')}
                :&nbsp;
                <TextField source="role" />
              </Typography>
              <Typography variant="body2">
                {translate('resources.companyAdmin.fields.status')}
                :&nbsp;
                <TextField source="status" />
              </Typography>
            </CardContent>
          </Card>
        </RecordContextProvider>
      ))}
    </Box>
  );
};

MobileGrid.defaultProps = {
  data: {},
  ids: [],
};

export default memo(MobileGrid);
