import { memo } from 'react';
import {
  useRecordContext, Loading,
} from 'react-admin';
import { createUseStyles } from 'react-jss';
import {
  Theme, useMediaQuery,
} from '@mui/material';
import cn from 'classnames';
import { useResizeDetector } from 'react-resize-detector';

import Tasks from '../../../elements/Tasks';
import UserInfo from '../../../elements/UserInfo';
import UserInfoMobile from '../../../elements/UserInfoMobile';

const ClientShowLayout = () => {
  const classes = useStyles();
  const record = useRecordContext();
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const { width, height, ref } = useResizeDetector();

  return (
    <div ref={ref} className={cn({ [classes.container]: !isXSmall })}>
      {!record?.id && (
        <Loading />
      )}
      {record?.id && isXSmall && (
        <UserInfoMobile />
      )}
      {record?.id && (
        <Tasks
          clientName={`${record?.firstName || ''} ${record?.lastName || ''}`.trim()}
          clientId={record?.id}
          hasPagination
          perPage={50}
        />
      )}
      {record?.id && !isXSmall && !!width && (
        <UserInfo columns={width > 960 ? 2 : 1} />
      )}
    </div>
  );
};

const useStyles = createUseStyles({
  paperMobile: {
    boxShadow: 'none',
    background: 'transparent',
  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    flex: 1,
  },
  paper: {
    padding: '1rem',
    flex: 'none',
    width: '60%',
    marginBottom: '1rem',
  },
});

export default memo(ClientShowLayout);
