import { gql, GetOneProvider } from 'services/dataProvider';

import { fields as ClientFields } from '../fragments';

const provider: GetOneProvider = (params) => {
  const query = gql`
    ${ClientFields}
    query(
      $id: Int
    ) {
      client {
        one(
          id: $id
        ) {
          ...ClientFields
        }
      }
    }
  `;
  return {
    query,
    variables: {
      id: Number(params.id),
    },
  };
};

export default provider;
