import { FC, memo, useCallback } from 'react';
import {
  Toolbar as RAToolbar, useNotify, useRecordContext, useRedirect, useRefresh, useTranslate, useUpdate,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { DateTime } from 'luxon';
import {
  Button, Divider, Theme, useMediaQuery,
} from '@mui/material';
import { createUseStyles } from 'react-jss';

import { Document } from 'interfaces/entities';

import cn from 'classnames';
import ActionButton from './ActionButton';
import NotesAddModal from './NotesAddModal';

interface IFormData {
  id: string | number,
  documents: Document[],
  task?: {
    isOngoing: boolean
  },
  notes: string,
  dueDateAt: string,
  isArchived: boolean,
  state: 'decline',
  status?: 'to_do' | 'approved',
  client?: {
    id: number | string,
  },
}

export const checkHasChanges = (recordData: IFormData, date: string, watch: Document[]): boolean => {
  const documentFormState = watch.map((document) => document.id || 0).join('|');
  const documentRecord = recordData.documents.map((document) => document.id || 0).join('|');
  // const notesFormState = formData?.notes || '';
  // const notesRecord = recordData?.notes || '';
  const dueDateAtFormState = DateTime.fromISO(date).toFormat('ddLLyyyy');
  const dueDateAtRecord = DateTime.fromISO(recordData.dueDateAt).toFormat('ddLLyyyy');
  if (documentFormState !== documentRecord) {
    return true;
  }
  if (dueDateAtFormState !== dueDateAtRecord) {
    return true;
  }
  return false;
};

const ActionsToolbar: FC = (props) => {
  const classes = useStyles();
  const record = useRecordContext<IFormData>();
  const watch = useWatch<IFormData>();

  const [update, { isLoading }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const { isArchived } = record;
  const dueDateAtFormState = useWatch({ name: 'dueDateAt' });
  const documentFormState = useWatch({ name: 'documents' });

  const isFinalize = record.task?.isOngoing;
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const { reset } = useFormContext();
  const hasChanges = checkHasChanges(record, dueDateAtFormState, documentFormState);
  const redirect = useRedirect();
  const translate = useTranslate();

  const handleReset = useCallback(() => {
    reset();
  }, [reset]);

  const handleSave = useCallback(async () => {
    try {
      await update('Task', { id: watch.id, data: watch, previousData: record }, {
        returnPromise: true,
      });
    } catch (error) {
      notify((error as Error).message, { type: 'warning' });
      return;
    }

    refresh();
  }, [refresh, update, watch, record, notify]);

  const handleComplete = useCallback(() => {
    redirect(`/Client/${record?.client?.id}/show`);
  }, [record, redirect]);

  return (
    <RAToolbar {...props} className={cn(classes.toolbar, { [classes.toolbarMobile]: isXSmall })}>
      {hasChanges && (
        <>
          <Button onClick={handleReset} variant="outlined" disabled={isLoading}>{translate('resources.client.action.cancel')}</Button>
          <div className={classes.sep} />
          <Button onClick={handleSave} variant="contained" disabled={isLoading} color="primary">{translate('resources.task.save')}</Button>
        </>
      )}
      {!hasChanges && (
        <>
          {isArchived && (<ActionButton record={record} label={translate('resources.task.values.status.unArchive')} action="restore" color="primary" variant="outlined" />)}
          {!isArchived && (<ActionButton record={record} label={translate('resources.task.values.status.archive')} action="archive" color="primary" variant="outlined" />)}
          {!isXSmall && <div className={classes.sep} />}
          {isFinalize && (
          <>
            <ActionButton record={record} label={translate('resources.task.finalize')} action="finalize" color="primary" variant="outlined" />
            <Divider style={{ height: '2.28rem' }} orientation="vertical" />
          </>
          )}
          <NotesAddModal
            id="decline-confirm"
            ButtonProps={{
              text: translate('resources.task.dontApprove'),
              color: 'primary',
              variant: 'contained',
            }}
            FormProps={{
              title: 'Don\'t approve',
              submitLabel: 'Confirm',
            }}
            record={record}
            onComplete={handleComplete}
          />
          <ActionButton record={record} label={translate('resources.task.approve')} action="approve" color="primary" variant="contained" />
        </>
      )}
    </RAToolbar>
  );
};

const useStyles = createUseStyles({
  toolbar: {
    display: 'flex',
    columnGap: '1rem',
  },
  toolbarMobile: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  sep: {
    flex: 1,
  },
});

export default memo(ActionsToolbar);
